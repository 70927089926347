// import React, { useContext, useEffect, useRef, useState } from "react";
// import BackgroundImage from "../../components/BackgroundImage/BackgroundImage";
// import CircularImage from "../../components/CircularImage/CircularImage";
// import "./Exit.css";
// import { UserContext } from "../../providers/UserProvider";
// import { useNavigate, useParams } from "react-router-dom";
// import axiosInstance from "../../services/axios";
// import { backgrounds } from "../../data/backgrounds";
// import { iconsData } from "../../data/icons";
// import AboutModal from "../../components/AboutModal/AboutModal";
// import ExitModal from "../../components/ExitModal/ExitModal";
// import InstagramModal from "../../components/InstagramModal/AboutModal";
// import { FaArrowLeft, FaBars, FaTimes } from "react-icons/fa"; // Import the left arrow icon
// import Modal from "../../components/Modal/Modal";
// import ReportModal from "../../components/ReportModal/ReportModal";
// import { BsThreeDots } from "react-icons/bs";
// import Carousel from "../../components/Carousel/Carousel";

// const Exit = ({ post, whatValue, type, prankType }) => {
//   const { user } = useContext(UserContext);
//   const { id } = useParams();
//   const [compliment, setCompliment] = useState(null);
//   const [instagramModal, setInstagramModal] = useState(false);
//   const [instagramConnect, setInstagramConnect] = useState(false);
//   const [instagramUsername, setInstagramUsername] = useState("");
//   const [menuOpen, setMenuOpen] = useState(false);
//   const [showModal, setShowModal] = useState(false);
//   const [modal, setModal] = useState("");
//   const navigate = useNavigate();

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setInstagramModal(true);
//     }, 10000);
//     return () => clearTimeout(timer);
//   }, []);

//   const toggleMenu = () => {
//     console.log("clicked");
//     setMenuOpen(!menuOpen);
//   };

//   const handleModal = (modal) => {
//     if (modal !== "privacy" && modal !== "tos") {
//       setShowModal(true);
//       setMenuOpen(false);
//       setModal(modal);
//     } else {
//       navigate(`/${modal}`);
//     }
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//     setModal("");
//   };

//   const handleOpenInstagramModal = () => {
//     setInstagramModal(true);
//   };

//   const handleCloseInstagramModal = () => {
//     setInstagramModal(false);
//   };

//   const handleInstagramConnect = () => {
//     setInstagramConnect(true);
//     setInstagramModal(false);
//   };

//   const backgroundImageUrl = post?.image;
//   const logo = "https://i.ibb.co/yyjy9t4/checkout-my-Friend.png";

//   useEffect(() => {
//     const sendCompliment = async () => {
//       if (user && id && whatValue && type) {
//         let reqObj;

//         if (type === "prank") {
//           reqObj = {
//             postId: id,
//             message: whatValue._id,
//             complimentType: whatValue.type,
//             prank: true,
//           };
//         } else {
//           reqObj = {
//             postId: id,
//             message: whatValue,
//             complimentType: type,
//           };
//         }

//         try {
//           const response = await axiosInstance.post("/compliments", reqObj, {
//             headers: {
//               Authorization: `Bearer ${user.token}`,
//             },
//           });
//           setCompliment(response.data);
//         } catch (error) {
//           console.error("Error sending compliment:", error);
//           navigate("/servererror");
//         }
//       }
//     };

//     sendCompliment();
//   }, [user, id, whatValue, type]);

//   const inputRef = useRef(null);

//   useEffect(() => {
//     // Automatically focus the input field when the component mounts
//     if (inputRef.current) {
//       inputRef.current.focus();
//     }
//   }, []);

//   const handleInstagramConnectBtn = async () => {
//     if (instagramUsername.length >= 5) {
//       await addInstagram();
//       setInstagramConnect(false);
//     } else {
//       setInstagramConnect(false);
//     }
//   };

//   const calculateLikePercentage = () => {
//     const date = post?.scores?.date;
//     const marry = post?.scores?.marry;
//     const hookup = post?.scores?.hookup;
//     const total = date + marry + hookup;

//     return {
//       date: Math.round((date / total) * 100) || 0,
//       marry: Math.round((marry / total) * 100) || 0,
//       hookup: Math.round((hookup / total) * 100) || 0,
//     };
//   };

//   const getTag = () => {
//     const percentages = calculateLikePercentage();

//     if (
//       percentages.date > percentages.marry &&
//       percentages.date > percentages.hookup
//     ) {
//       return "Crush Material";
//     } else if (
//       percentages.marry > percentages.date &&
//       percentages.marry > percentages.hookup
//     ) {
//       return "Fun Person";
//     } else if (
//       percentages.hookup > percentages.date &&
//       percentages.hookup > percentages.marry
//     ) {
//       return "Rizzler";
//     } else {
//       return "GOAT Person";
//     }
//   };

//   const addInstagram = async () => {
//     try {
//       const response = await axiosInstance.post(
//         "/users/update-instagram",
//         { instagram: instagramUsername },
//         {
//           headers: {
//             Authorization: `Bearer ${user.token}`,
//           },
//         }
//       );
//     } catch (error) {
//       console.error("Error sending instagram:", error);
//       navigate("/servererror");
//     }
//   };

//   const data = [
//     {
//       avatar: backgroundImageUrl,
//       title: "What They See?",
//       description: `Some wants to ${prankType} with you!`,
//     },
//     {
//       avatar: backgroundImageUrl,
//       title: "Message They Got",
//       description: `"${whatValue?.description}"`,
//     },
//   ];

//   return (
//     <div className="exit-container">
//       <BackgroundImage imageUrl={backgrounds[type]} />

//       <div
//         onClick={toggleMenu}
//         style={{
//           position: "absolute",
//           top: 10,
//           right: 10,
//           padding: "10px",
//           cursor: "pointer",
//           zIndex: 1000,
//         }}
//       >
//         {menuOpen ? (
//           <FaTimes size={24} color="#FFF" />
//         ) : (
//           <BsThreeDots size={24} color="#FFF" />
//         )}
//       </div>

//       {menuOpen && (
//         <div
//           style={{
//             position: "absolute",
//             top: 50,
//             right: 27,
//             backgroundColor: "#000",
//             borderRadius: "20px",
//             padding: "30px",
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "flex-start",
//             zIndex: 120,
//           }}
//         >
//           <div
//             style={{
//               width: "100%",
//               borderBottom: "1px solid grey",
//               paddingBottom: "10px",
//               marginBottom: "5px",
//               textAlign: "center",
//             }}
//             onClick={() => handleModal("about")}
//           >
//             <span
//               style={{
//                 color: "#FFF",
//                 fontWeight: "bold",
//                 cursor: "pointer",
//                 fontSize: "1.5em",
//               }}
//             >
//               About
//             </span>
//           </div>
//           <div
//             style={{
//               width: "100%",
//               borderBottom: "1px solid grey",
//               paddingBottom: "10px",
//               marginBottom: "5px",
//               textAlign: "center",
//             }}
//             onClick={() => handleModal("privacy")}
//           >
//             <span
//               style={{
//                 color: "#FFF",
//                 fontWeight: "bold",
//                 cursor: "pointer",
//                 fontSize: "1.5em",
//               }}
//             >
//               Privacy
//             </span>
//           </div>

//           <div
//             style={{
//               width: "100%",
//               borderBottom: "1px solid grey",
//               paddingBottom: "10px",
//               marginBottom: "5px",
//               textAlign: "center",
//             }}
//             onClick={() => handleModal("tos")}
//           >
//             <span
//               style={{
//                 color: "#FFF",
//                 fontWeight: "bold",
//                 cursor: "pointer",
//                 fontSize: "1.5em",
//               }}
//             >
//               Terms
//             </span>
//           </div>
//           <div
//             style={{ width: "100%", textAlign: "center" }}
//             onClick={() => handleModal("report")}
//           >
//             <span
//               style={{
//                 color: "red",
//                 fontWeight: "bold",
//                 cursor: "pointer",
//                 fontSize: "1.5em",
//               }}
//             >
//               Report
//             </span>
//           </div>
//         </div>
//       )}

//       {instagramConnect ? (
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             flex: 1,
//             height: "100vh",
//           }}
//         >
//           {/* Back Icon */}
//           <div
//             onClick={() => setInstagramConnect(false)}
//             style={{
//               display: "flex",
//               flexDirection: "row",
//               justifyContent: "center",
//               alignItems: "center",
//               margin: 15,
//               backgroundColor: "rgba(255, 255, 255, 0.36)", // 36% opacity of white
//               borderRadius: 20,
//               width: 20,
//               height: 20,
//               padding: 10,
//             }}
//           >
//             <FaArrowLeft style={{ color: "#ffffff" }} />{" "}
//             {/* Left arrow with 36% opacity */}
//           </div>

//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "flex-start",
//               flexDirection: "column",
//               flex: 1,
//               marginTop: 30,
//             }}
//           >
//             {/* Add Instagram */}
//             <p style={{ fontWeight: 800, fontSize: "2.5rem", color: "#fff" }}>
//               Add Instagram
//             </p>

//             {/* To Connect Personally */}
//             <p style={{ fontWeight: 600, fontSize: "1.2rem", color: "#fff" }}>
//               To connect personally 🤞
//             </p>

//             {/* Input Field */}
//             <input
//               type="text"
//               placeholder="@Username"
//               value={instagramUsername}
//               ref={inputRef}
//               className="custom-input"
//               onChange={(e) => setInstagramUsername(e.target.value)}
//               style={{
//                 borderRadius: 10,
//                 backgroundColor: "rgba(255, 255, 255, 0.333)",
//                 width: "75%",
//                 padding: "20px 25px",
//                 fontSize: "1rem",
//                 border: "none",
//                 marginTop: 30,
//               }}
//             />

//             {/* Button Logic */}
//             <button
//               onClick={handleInstagramConnectBtn}
//               style={{
//                 marginTop: 20,
//                 padding: "20px 50px",
//                 borderRadius: 30,
//                 backgroundColor:
//                   instagramUsername.length >= 5
//                     ? "#000000"
//                     : "rgba(0, 0, 0, 0.32)",
//                 color: "#ffffff",
//                 border: "none",
//                 fontWeight: 600,
//                 cursor: "pointer",
//               }}
//             >
//               {instagramUsername.length >= 5 ? "Done" : "Cancel"}
//             </button>
//           </div>
//         </div>
//       ) : (
//         <div className="exit-container">
//           <CircularImage
//             backgroundImageUrl={backgroundImageUrl}
//             icon={iconsData[type]}
//           />

//           {type === "prank" ? (
//             <>
//               <p
//                 style={{
//                   color: "white",
//                   fontWeight: "900",
//                   fontSize: "2em",
//                   marginTop: "10px",
//                   marginBottom: "10px",
//                 }}
//               >
//                 Prank Done! ✅
//               </p>

//               <Carousel data={data} />
//             </>
//           ) : (
//             <>
//               <p
//                 className="exit-message"
//                 style={{
//                   marginTop: 10,
//                   marginBottom: 10,
//                   fontStyle: "italic",
//                   marginBottom: 20,
//                 }}
//               >
//                 Poll Results 👇🏻
//               </p>

//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   marginBottom: 20,
//                 }}
//               >
//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "row",
//                     alignItems: "center",
//                     marginRight: 10,
//                   }}
//                 >
//                   <img
//                     src={require("../../assets/icons/Date.png")}
//                     style={{
//                       width: 30,
//                       height: 30,
//                       border: "1px solid white",
//                       borderRadius: 50,
//                     }}
//                   />
//                   <div
//                     style={{
//                       background:
//                         "linear-gradient(to right, transparent, rgba(255, 255, 255, 0.45))",
//                       padding: 7,
//                       paddingLeft: 15,
//                       paddingRight: 15,
//                       borderRadius: 10,
//                       color: "white",
//                       fontWeight: "700",
//                     }}
//                   >
//                     {calculateLikePercentage().date}%
//                   </div>
//                 </div>

//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "row",
//                     alignItems: "center",
//                     marginRight: 10,
//                   }}
//                 >
//                   <img
//                     src={require("../../assets/icons/Chill.png")}
//                     style={{
//                       width: 30,
//                       height: 30,
//                       border: "1px solid white",
//                       borderRadius: 50,
//                     }}
//                   />
//                   <div
//                     style={{
//                       background:
//                         "linear-gradient(to right, transparent, rgba(255, 255, 255, 0.45))",
//                       padding: 7,
//                       paddingLeft: 15,
//                       paddingRight: 15,
//                       borderRadius: 10,
//                       color: "white",
//                       fontWeight: "700",
//                     }}
//                   >
//                     {calculateLikePercentage().marry}%
//                   </div>
//                 </div>

//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "row",
//                     alignItems: "center",
//                     marginRight: 10,
//                   }}
//                 >
//                   <img
//                     src={require("../../assets/icons/Hookup.png")}
//                     style={{
//                       width: 30,
//                       height: 30,
//                       border: "1px solid white",
//                       borderRadius: 50,
//                     }}
//                   />
//                   <div
//                     style={{
//                       background:
//                         "linear-gradient(to right, transparent, rgba(255, 255, 255, 0.45))",
//                       padding: 7,
//                       paddingLeft: 15,
//                       paddingRight: 15,
//                       borderRadius: 10,
//                       color: "white",
//                       fontWeight: "700",
//                     }}
//                   >
//                     {calculateLikePercentage().hookup}%
//                   </div>
//                 </div>
//               </div>
//               {/* <img
//                 src={iconsData["exitLoader"]}
//                 style={{ width: "60vw", marginBottom: 50 }}
//               /> */}
//             </>
//           )}
//           {type !== "prank" ? (
//             <div
//               style={{
//                 paddingTop: "5px",
//                 paddingBottom: "5px",

//                 backgroundColor: "rgba(255, 255, 255, 0.5)",
//                 borderRadius: 20,
//                 marginTop: 12,
//                 marginBottom: "25px",
//                 maxWidth: window.innerWidth > 1024 ? "60%" : "120%",
//               }}
//             >
//               <p
//                 style={{
//                   color: "white",
//                   fontSize: "0.9rem",
//                   fontWeight: 600,
//                   paddingLeft: window.innerWidth > 1024 ? "15px" : "27x", // Smaller on desktop
//                   paddingRight: window.innerWidth > 1024 ? "15px" : "27px",
//                   textWrap: "nowrap",
//                   fontFamily: "Inter",
//                 }}
//               >
//                 {compliment ? `The friend is a "${getTag()}"` : "Loading..."}
//               </p>
//             </div>
//           ) : null}

//           <p style={{ marginTop: "55px" }} className="exit-message">
//             Wanna to see who likes your friend 🤩
//           </p>

//           <img
//             src={require("../../assets/start-now.png")}
//             style={{
//               width: window.innerWidth > 1024 ? "30%" : "90%",
//               //   maxHeight: window.innerWidth > 1024 ? "200px" : "auto",
//               objectFit: "contain",
//             }}
//           />

//           <p
//             style={{
//               color: "white",
//               fontWeight: 700,
//               fontSize: "13px",
//               marginTop: 20,
//               marginBottom: "10px",
//               fontFamily: "Inter",
//             }}
//           >
//             👆🏻 10k+ people already played 👆🏻
//           </p>

//           {/* <img src={iconsData['cmfWhite']} style={{ width: '60vw', position: 'absolute', bottom: -50 }} /> */}
//         </div>
//       )}

//       <ExitModal
//         show={instagramModal}
//         handleClose={handleCloseInstagramModal}
//         handleInstagramConnect={handleInstagramConnect}
//       >
//         <InstagramModal photo={backgroundImageUrl} type={type} />
//       </ExitModal>

//       <Modal show={showModal} handleClose={handleCloseModal}>
//         {modal === "about" ? <AboutModal /> : null}
//         {modal === "report" ? <ReportModal /> : null}
//       </Modal>
//     </div>
//   );
// };

// export default Exit;
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft, FaTimes } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import BackgroundImage from "../../components/BackgroundImage/BackgroundImage";
import CircularImage from "../../components/CircularImage/CircularImage";
import AboutModal from "../../components/AboutModal/AboutModal";
import ExitModal from "../../components/ExitModal/ExitModal";
import InstagramModal from "../../components/InstagramModal/AboutModal";
import Modal from "../../components/Modal/Modal";
import ReportModal from "../../components/ReportModal/ReportModal";
import Carousel from "../../components/Carousel/Carousel";
import { UserContext } from "../../providers/UserProvider";
import axiosInstance from "../../services/axios";
import { backgrounds } from "../../data/backgrounds";
import { iconsData } from "../../data/icons";
import "./Exit.css";
import InstagramConnectPage from "../InstagramConnect";

// Create a separate CSS file for the responsive styles
// exit-responsive.css

const Exit = ({ post, whatValue, setPageProgress, type, prankType }) => {
  const { user } = useContext(UserContext);
  const { id } = useParams();
  const [compliment, setCompliment] = useState(null);
  const [instagramModal, setInstagramModal] = useState(false);
  const [instagramConnect, setInstagramConnect] = useState(false);
  const [showInstagramConnect, setShowInstagramConnect] = useState(false);
  const [instagramUsername, setInstagramUsername] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState("");
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [hideMenu, setHideMenu] = useState(false);

  const [checkoutValue, setCheckoutValue] = useState("");
  // Handle the "Add Instagram for reply" click
  const handleInstagramClick = () => {
    // setShowInstagramConnect(true);
    setInstagramConnect(true);
    setShowInstagramConnect(true);
    setHideMenu(true);
  };
  // Handle cancel action
  const handleCancel = () => {
    setShowInstagramConnect(false);
  };
  // Handle when user completes Instagram entry
  const handleDone = (username) => {
    console.log(`Instagram username: ${username}`);
    setShowInstagramConnect(false);
    setInstagramConnect(false);
    // Do something with the username
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setInstagramModal(true);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const sendCompliment = async () => {
      if (user && id && whatValue && type) {
        let reqObj;

        if (type === "prank") {
          reqObj = {
            postId: id,
            message: whatValue._id,
            complimentType: whatValue.type,
            prank: true,
          };
        } else {
          reqObj = {
            postId: id,
            message: whatValue,
            complimentType: type,
          };
        }

        try {
          const response = await axiosInstance.post("/compliments", reqObj, {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          });
          setCompliment(response.data);
        } catch (error) {
          console.error("Error sending compliment:", error);
          navigate("/servererror");
        }
      }
    };

    sendCompliment();
  }, [user, id, whatValue, type, navigate]);

  useEffect(() => {
    // Automatically focus the input field when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [instagramConnect]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleModal = (modal) => {
    if (modal !== "privacy" && modal !== "tos") {
      setShowModal(true);
      setMenuOpen(false);
      setModal(modal);
    } else {
      navigate(`/${modal}`);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModal("");
  };

  const handleOpenInstagramModal = () => {
    setInstagramModal(true);
  };

  const handleCloseInstagramModal = () => {
    setInstagramModal(false);
  };

  const handleInstagramConnect = () => {
    setInstagramConnect(true);
    setInstagramModal(false);
  };

  const handleInstagramConnectBtn = async () => {
    if (instagramUsername.length >= 5) {
      await addInstagram();
    }
    setInstagramConnect(false);
  };

  const addInstagram = async () => {
    try {
      await axiosInstance.post(
        "/users/update-instagram",
        { instagram: instagramUsername },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
    } catch (error) {
      console.error("Error sending instagram:", error);
      navigate("/servererror");
    }
  };

  const calculateLikePercentage = () => {
    const date = post?.scores?.date || 0;
    const marry = post?.scores?.marry || 0;
    const hookup = post?.scores?.hookup || 0;
    const total = date + marry + hookup;

    if (total === 0) {
      return { date: 0, marry: 0, hookup: 0 };
    }

    return {
      date: Math.round((date / total) * 100),
      marry: Math.round((marry / total) * 100),
      hookup: Math.round((hookup / total) * 100),
    };
  };

  const getTag = () => {
    const percentages = calculateLikePercentage();

    if (
      percentages.date > percentages.marry &&
      percentages.date > percentages.hookup
    ) {
      return "Crush Material";
    } else if (
      percentages.marry > percentages.date &&
      percentages.marry > percentages.hookup
    ) {
      return "Fun Person";
    } else if (
      percentages.hookup > percentages.date &&
      percentages.hookup > percentages.marry
    ) {
      return "Rizzler";
    } else {
      return "GOAT Person";
    }
  };

  const backgroundImageUrl = post?.image;

  const data = [
    {
      avatar: backgroundImageUrl,
      title: "What They See?",
      description: `Some wants to ${prankType} with you!`,
    },
    {
      avatar: backgroundImageUrl,
      title: "Message They Got",
      // description: `"${whatValue?.description}"`,
      description: `My Jokes are drier than your hair,wanna fix that with coffe?`,
    },
  ];

  const menuButtonStyle = {
    position: "absolute",
    top: "1rem",
    right: "1rem",
    padding: "0.75rem",
    cursor: "pointer",
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const menuStyle = {
    position: "absolute",
    top: "4rem",
    right: "1rem",
    backgroundColor: "#000",
    borderRadius: "1.25rem",
    padding: "1.5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    zIndex: 120,
    width: "10rem",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
  };

  const menuItemStyle = {
    width: "100%",
    borderBottom: "1px solid grey",
    paddingBottom: "0.75rem",
    marginBottom: "0.5rem",
    textAlign: "center",
    cursor: "pointer",
  };

  const menuTextStyle = {
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "1.2rem",
  };

  const instagramContainerStyle = {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "1rem",
  };

  const backButtonStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "1rem",
    backgroundColor: "rgba(255, 255, 255, 0.36)",
    borderRadius: "50%",
    width: "2.5rem",
    height: "2.5rem",
    cursor: "pointer",
    alignSelf: "flex-start",
  };

  const instagramHeaderStyle = {
    fontWeight: 800,
    fontSize: "clamp(1.5rem, 5vw, 2.5rem)",
    color: "#fff",
    textAlign: "center",
    marginTop: "2rem",
  };

  const instagramSubtitleStyle = {
    fontWeight: 600,
    fontSize: "clamp(1rem, 3vw, 1.2rem)",
    color: "#fff",
    textAlign: "center",
    marginTop: "0.5rem",
  };

  const inputStyle = {
    borderRadius: "0.75rem",
    backgroundColor: "rgba(255, 255, 255, 0.333)",
    width: "clamp(250px, 75%, 400px)",
    padding: "1rem 1.25rem",
    fontSize: "1rem",
    border: "none",
    marginTop: "2rem",
  };

  const buttonStyle = {
    marginTop: "1.5rem",
    padding: "1rem 3rem",
    borderRadius: "2rem",
    backgroundColor:
      instagramUsername.length >= 5 ? "#000000" : "rgba(0, 0, 0, 0.32)",
    color: "#ffffff",
    border: "none",
    fontWeight: 600,
    cursor: "pointer",
    fontSize: "1rem",
  };

  const pollResultsStyle = {
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderRadius: "22px",
    marginBottom: "1.5rem",
    maxWidth: "min(100%, 500px)",
    textAlign: "center",
  };

  const pollTextStyle = {
    color: "white",
    fontSize: "0.8rem",
    fontWeight: 600,
    padding: "0 1rem",
    whiteSpace: "nowrap",
    fontFamily: "Inter",
  };

  const actionButtonStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // marginRight: "0.600rem",
    marginBottom: "0.5rem",
  };

  const iconStyle = {
    width: "1.8rem",
    height: "1.8rem",
    border: "2.5px solid white",
    borderRadius: "50%",
  };

  const percentageStyle = {
    background:
      "linear-gradient(to right, transparent, rgba(255, 255, 255, 0.45))",
    padding: "0.2rem 1rem",
    borderRadius: "0.625rem",
    color: "white",
    fontWeight: "700",
  };

  const exitMessageStyle = {
    color: "#FFFFFF",
    fontWeight: 900,
    fontFamily: "Inter, sans-serif",
    textAlign: "center",
    fontSize: "clamp(1.8rem, 3vw, 1.25rem)",
    marginBottom: "4rem",
    padding: "0 1rem",
    lineHeight: "1.2", // Adjust line spacing (smaller value = less space)
    marginTop: "17px", // Moves text upward
  };

  const startNowImageStyle = {
    width: "min(95%, 350px)",
    maxHeight: "200px",
    objectFit: "contain",
    // margin: "0rem 0",
  };

  const footerTextStyle = {
    color: "white",
    fontFamily: "Inter, sans-serif",
    fontWeight: 800,
    fontSize: "19px",

    textAlign: "center",
    textDecoration: "underline solid",
    textDecorationThickness: "10%",
    textDecorationOffset: "9%",

    marginBottom: "2rem",
  };

  return (
    <div className="exit-container">
      <BackgroundImage imageUrl={backgrounds[type]} />

      {/* Menu Button */}
      {!hideMenu && (
        <>
          <div onClick={toggleMenu} style={menuButtonStyle}>
            {menuOpen ? (
              <FaTimes size={24} color="#FFF" />
            ) : (
              <BsThreeDots size={24} color="#FFF" />
            )}
          </div>

          {/* Menu Options */}
          {menuOpen && (
            <div style={menuStyle}>
              <div style={menuItemStyle} onClick={() => handleModal("about")}>
                <span style={menuTextStyle}>About</span>
              </div>
              <div style={menuItemStyle} onClick={() => handleModal("privacy")}>
                <span style={menuTextStyle}>Privacy</span>
              </div>
              <div style={menuItemStyle} onClick={() => handleModal("tos")}>
                <span style={menuTextStyle}>Terms</span>
              </div>
              <div
                style={{ ...menuItemStyle, borderBottom: "none" }}
                onClick={() => handleModal("report")}
              >
                <span style={{ ...menuTextStyle, color: "red" }}>Report</span>
              </div>
            </div>
          )}
        </>
      )}
      {instagramConnect ? (
        // <div style={instagramContainerStyle}>
        //   {/* Back Button */}
        //   <div
        //     onClick={() => setInstagramConnect(false)}
        //     style={backButtonStyle}
        //   >
        //     <FaArrowLeft style={{ color: "#ffffff" }} />
        //   </div>

        //   <div style={{ width: "100%", textAlign: "center", maxWidth: "50px" }}>
        //     {/* Add Instagram Header */}
        //     <p style={instagramHeaderStyle}>Add Instagram</p>

        //     {/* Subtitle */}
        //     <p style={instagramSubtitleStyle}>To connect personally 🤞</p>

        //     {/* Input Field */}
        //     <input
        //       type="text"
        //       placeholder="@Username"
        //       value={instagramUsername}
        //       ref={inputRef}
        //       className="custom-input"
        //       onChange={(e) => setInstagramUsername(e.target.value)}
        //       style={inputStyle}
        //     />

        //     {/* Button */}
        //     <button onClick={handleInstagramConnectBtn} style={buttonStyle}>
        //       {instagramUsername.length >= 5 ? "Done" : "Cancel"}
        //     </button>
        //   </div>
        // </div>
        <InstagramConnectPage
          // backgroundImageUrl={} // Your profile image
          instagramUsername={backgroundImageUrl}
          setInstagramUsername={setInstagramUsername}
          setInstagramConnect={setInstagramConnect}
          handleInstagramConnectBtn={handleInstagramConnectBtn}
          type={checkoutValue}
          circularBackgroundImage={backgroundImageUrl}
          onDone={() => setPageProgress("checkout")}
        />
      ) : (
        <div className="exit-container">
          <CircularImage
            backgroundImageUrl={backgroundImageUrl}
            icon={iconsData[type]}
          />

          {type === "prank" ? (
            <>
              <div className="prank-container">
                <p className="prank-title">Prank Done! ✅</p>
                <Carousel data={data} />
              </div>
            </>
          ) : (
            <>
              <p
                style={{
                  marginTop: "1rem",
                  marginBottom: "1.3rem",
                  fontWeight: 900,
                  color: "white",
                  textAlign: "center",

                  fontSize: "clamp(1.7rem, 3vw, 1.25rem)",
                }}
              >
                Poll Results 👇🏻
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "1.25rem",
                  flexWrap: "wrap",
                  gap: "0.5rem",
                  padding: "0 1rem",
                  width: "90vw",
                }}
              >
                <div style={actionButtonStyle}>
                  <img
                    src={require("../../assets/icons/Date.png")}
                    style={iconStyle}
                    alt="Date icon"
                  />
                  <div style={percentageStyle}>
                    {calculateLikePercentage().date}%
                  </div>
                </div>

                <div style={actionButtonStyle}>
                  <img
                    src={require("../../assets/icons/Chill.png")}
                    style={iconStyle}
                    alt="Chill icon"
                  />
                  <div style={percentageStyle}>
                    {calculateLikePercentage().marry}%
                  </div>
                </div>

                <div style={actionButtonStyle}>
                  <img
                    src={require("../../assets/icons/Hookup.png")}
                    style={iconStyle}
                    alt="Hookup icon"
                  />
                  <div style={percentageStyle}>
                    {calculateLikePercentage().hookup}%
                  </div>
                </div>
              </div>
            </>
          )}

          {type !== "prank" ? (
            <div style={pollResultsStyle}>
              <p style={pollTextStyle}>
                {compliment ? `The friend is a "${getTag()}"` : "Loading..."}
              </p>
            </div>
          ) : null}

          <p style={exitMessageStyle}>See who wants to date your friend🤩</p>

          <img
            src={require("../../assets/start-now.png")}
            style={startNowImageStyle}
            alt="Start now"
          />

          {showInstagramConnect ? (
            <InstagramConnectPage
              profileImageUrl={backgroundImageUrl} // Use the same image from your existing code
              onCancel={handleCancel}
              onDone={handleDone}
            />
          ) : (
            <div className="exit-container">
              {/* Your existing content */}

              {/* Make this clickable to show Instagram page */}
              <p style={footerTextStyle} onClick={handleInstagramClick}>
                Add Instagram for reply
              </p>
            </div>
          )}
        </div>
      )}

      <ExitModal
        show={instagramModal}
        handleClose={handleCloseInstagramModal}
        handleInstagramConnect={handleInstagramConnect}
      >
        <InstagramModal photo={backgroundImageUrl} type={type} />
      </ExitModal>

      <Modal show={showModal} handleClose={handleCloseModal}>
        {modal === "about" ? <AboutModal /> : null}
        {modal === "report" ? <ReportModal /> : null}
      </Modal>
    </div>
  );
};

export default Exit;
