import React, { useState } from "react";
import "./Header.css";
import { pageTitles } from "../../data/DummyValues";

const Header = ({ page }) => {
  const [headerImage, setHeaderImage] = useState(pageTitles[page]);

  return (
    <div className="header">
      {/* <img src={headerImage} className='header-image' /> */}
      <p
        style={{
          color: "white",
          fontSize: 25,
          fontWeight: "900",
          fontFamily: "Inter,sans serif",
          marginTop: 10,
        }}
      >
        😄 Choose a one-liner 👇🏻
      </p>
    </div>
  );
};

export default Header;
