import React, { useContext, useState } from "react";
import BackgroundImage from "../../components/BackgroundImage/BackgroundImage";
import Header from "../../components/Header/Header";
import ImageContainer from "../../components/ImageContainer/ImageContainer";
import { signInWithGoogle } from "../../services/Firebase";
import { useNavigate } from "react-router-dom";
import { backgrounds } from "../../data/backgrounds";
import { iconsData } from "../../data/icons";
import { FaArrowDownLong } from "react-icons/fa6";

const CheckoutDummy = ({
  backgroundImageUrl,
  native = true,
  subject,
  whatsapp = false,
}) => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  const [isClicked, setIsClicked] = useState(false);

  const navigate = useNavigate();

  const handleClick = () => {
    if (native) {
      signInWithGoogle();
    }
  };

  const handleTOSClick = () => {
    //tbd
    navigate("/tos");
  };

  const getPronoun = () => {
    console.log("subject", subject);
    if (subject?.gender) {
      switch (subject.gender.toLowerCase()) {
        case "male":
          return "he";
        case "female":
          return "she";
        case "non-binary":
          return "this";
        default:
          return "this"; // A neutral pronoun for unknown or unspecified gender
      }
    } else {
      return "this"; // A neutral pronoun for cases where gender is not present
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        height: "100vh",
        paddingVertical: "20px",
      }}
    >
      <BackgroundImage imageUrl={backgrounds["checkout2"]} />

      {!native ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: "20px",
            position: "relative",
            minHeight: "100vh",
            width: "100%",
            marginTop: "-15px",
          }}
        >
          {/* 18+ Badge - Positioned in top left */}
          <div style={{ alignSelf: "center", marginBottom: 10 }}>
            <div
              style={{
                backgroundColor: "#FFE500",
                borderRadius: 100,
                paddingTop: "7px",
                paddingBottom: "7px",
                paddingLeft: "14px",
                paddingRight: "14px",
                marginBottom: "8px",
                // marginTop: "13px",
              }}
            >
              <p
                style={{
                  fontWeight: "bolder",
                  fontSize: "0.9rem",
                  color: "black",
                  fontWeight: 700,
                  fontStyle: "italic",
                  textAlign: "center",
                  margin: 0,
                }}
              >
                🔥 Only for 18+
              </p>
            </div>
          </div>

          {/* Title Section */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              textAlign: "center",
            }}
          >
            <p
              style={{
                fontWeight: 900,
                fontSize: "clamp(2rem, 8vw, 2.5rem)",
                color: "#FFE500",
                marginBottom: "10px",
                textAlign: "center",
                fontFamily: "'Inter', sans-serif",
              }}
            >
              See <span style={{ color: "white" }}>who's this</span>🔥
            </p>
            <p
              style={{
                fontWeight: 700,
                fontSize: "clamp(1.2rem, 5vw, 1.5em)",
                color: "#DADADA",
                marginBottom: "10px",
                textAlign: "center",
                fontStyle: "italic",
                fontFamily: "'Inter', sans-serif",
              }}
            >
              Date, Marry or Hookup 👀
            </p>
          </div>

          {/* Image Container - Made responsive for different screen sizes */}
          <div
            style={{
              display: "flex",
              alignSelf: "center",
              justifyContent: "center",
              position: "relative",
              margin: 30,
              height: "min(40vh, 400px)",
              width: "min(65%, 300px)",
              alignItems: "center",
              borderRadius: 27,
              border: "2px solid white",
              overflow: "hidden",
            }}
          >
            <ImageContainer imageUrl={backgroundImageUrl} height="100%">
              {!isClicked ? (
                <>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      padding: "20px",
                      color: "#FFF",
                      borderRadius: "0 20px",
                      width: "100%",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <img
                        src={iconsData["blurredName"]}
                        height={20}
                        width={150}
                        style={{ marginBottom: "10px" }}
                        alt="Blurred name"
                      />
                      <img
                        src={iconsData["blurredTime"]}
                        height={20}
                        width={100}
                        style={{ marginBottom: "10px" }}
                        alt="Blurred time"
                      />
                    </div>
                  </div>

                  <img
                    src={require("../../assets/q-vector.png")}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      width: "80px",
                      height: "80px",
                      transform: "translate(-50%, -50%)",
                    }}
                    onClick={() => setIsClicked(true)}
                    alt="Question mark"
                  />
                </>
              ) : (
                <>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "20%",
                      left: "50%",
                      transform: "translateX(-50%)",
                      cursor: "pointer",
                      width: "60px",
                      textAlign: "center",
                    }}
                  >
                    <FaArrowDownLong color="white" size={40} />
                  </div>
                  <p
                    style={{
                      color: "white",
                      fontWeight: "bolder",
                      fontSize: "clamp(1.3rem, 5vw, 2em)",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      textAlign: "center",
                      width: "80%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    Follow the <span style={{ color: "#FFE500" }}>steps</span>{" "}
                    below
                  </p>
                </>
              )}
            </ImageContainer>
          </div>

          {/* Instructions with numbered bullets instead of icons */}
          <div
            style={{
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "90%",
              maxWidth: "400px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "15px",
                marginLeft: "20px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#FFE500",
                  color: "black",
                  borderRadius: "50%",
                  width: "25px",
                  height: "25px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "20px",
                  fontWeight: 700,
                  fontFamily: "'Inter', sans-serif",
                }}
              >
                1
              </div>
              <p
                style={{
                  fontWeight: "bolder",
                  fontSize: "clamp(1.3rem, 4vw, 1.5em)",
                  color: "#FFF",
                  margin: 0,
                }}
              >
                Tap 3-dot icon on top
              </p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "20px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#FFE500",
                  color: "black",
                  borderRadius: "50%",
                  width: "25px",
                  height: "25px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "20px",
                  fontWeight: 700,
                  fontFamily: "'Inter', sans-serif",
                }}
              >
                2
              </div>
              <p
                style={{
                  fontWeight: "bolder",
                  fontSize: "clamp(1.3rem, 4vw, 1.5em)",
                  color: "#FFF",
                  margin: 0,
                }}
              >
                Tap "Open in <span style={{ color: "#FFE500" }}>Chrome</span>"
              </p>
            </div>
          </div>

          {/* Animated Pointy Finger */}
          <div
            style={{
              position: "absolute",
              top: "30px",
              right: "10px",
              width: "50px",
              height: "50px",
              background: `url(${iconsData["pointyFinger"]}) no-repeat center center`,
              backgroundSize: "contain",
              animation: "bounce 1s infinite",
            }}
          />
          <style>
            {`
        @keyframes bounce {
            0%, 100% {
                transform: translateY(0);
            }
            50% {
                transform: translateY(-10px);
            }
        }
        body {
            margin: 0;
            padding: 0;
            font-family: 'Inter', sans-serif;
            background-color: #121212;
            overflow-x: hidden;
        }
      `}
          </style>
        </div>
      ) : whatsapp ? (
        <>
          <div
            style={{
              height: height * 0.23,
              width: "80%",
              alignSelf: "center",
              marginBottom: height * 0.04,
              marginLeft: width * 0.05,
              marginRight: width * 0.05,
              position: "relative",
              alignSelf: "center",
            }}
          >
            <img
              src={subject.image}
              alt="Initiator"
              style={{
                width: width * 0.44,
                height: width * 0.5,
                borderRadius: width * 0.15,
                borderColor: "white",
                borderWidth: 5,
                borderStyle: "solid",
                position: "absolute",
                left: "0%",
                top: "0%",
                transform: "rotate(350deg)",
                zIndex: 1,
              }}
            />

            <img
              src={backgroundImageUrl}
              alt="Blurred Response"
              style={{
                width: width * 0.44,
                height: width * 0.5,
                borderRadius: width * 0.15,
                borderColor: "white",
                borderWidth: 5,
                borderStyle: "solid",
                position: "absolute",
                right: "0%",
                top: 10,
                zIndex: 2,
                transform: "rotate(10deg)",
              }}
            />

            <img
              src={require("../../assets/q-vector.png")}
              alt="Insta Icon"
              style={{ position: "absolute", top: 80, right: 50, zIndex: 3 }}
            />

            <img
              src={require("../../assets/insta-story-top-vector.png")}
              alt="Top Vector"
              style={{ position: "absolute", top: -30, right: -30 }}
            />

            <img
              src={require("../../assets/insta-story-bottom-vector.png")}
              alt="Bottom Vector"
              style={{ position: "absolute", bottom: -30, left: -30 }}
            />
          </div>

          <img
            src={require("../../assets/cmf-signup-text.png")}
            style={{
              width: "70vw",
              borderRadius: 20,
              alignSelf: "center",
              marginTop: 50,
            }}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px",
              margin: "2px 0px",
            }}
          >
            <button
              className="exit-button"
              onClick={handleClick}
              style={{
                backgroundColor: "#FFE500",
                fontSize: "1.3em",
                fontWeight: "700",
              }}
            >
              {native ? `Continue 👻` : null}
            </button>
          </div>

          <img
            src={require("../../assets/up-arrow.png")}
            style={{
              width: "50vw",
              borderRadius: 20,
              alignSelf: "center",
              marginTop: -20,
            }}
          />

          <p
            style={{
              color: "white",
              textAlign: "center",
              width: "80vw",
              alignSelf: "center",
            }}
          >
            Note: This is only for light fun & entertainment. By Continuing, you
            accept our{" "}
            <a
              href="/tos"
              style={{ color: "#FFE500", textDecoration: "underline" }}
            >
              Terms
            </a>{" "}
            &{" "}
            <a
              href="/privacy"
              style={{ color: "#FFE500", textDecoration: "underline" }}
            >
              Privacy
            </a>
            .
          </p>
        </>
      ) : (
        // <>
        //   <p
        //     style={{
        //       fontWeight: "bolder",
        //       fontSize: "2.5em",
        //       color: "white",
        //       marginBottom: "10px",
        //       textAlign: "center",
        //       marginTop: 10,
        //     }}
        //   >
        //     1 step away from your{" "}
        //     <span style={{ color: "#FFE500" }}>match</span> 🔥
        //   </p>

        //   <div
        //     style={{
        //       display: "flex",
        //       alignSelf: "center",
        //       justifyContent: "center",
        //       position: "relative",
        //       margin: 30,
        //       height: "min(40vh, 400px)",
        //       width: "min(65%, 300px)",
        //       alignItems: "center",
        //       borderRadius: 27,
        //       border: "2px solid white",
        //       overflow: "hidden",
        //     }}
        //   >
        //     <ImageContainer imageUrl={backgroundImageUrl} height="100%">
        //       {!isClicked ? (
        //         <>
        //           <div
        //             style={{
        //               position: "absolute",
        //               bottom: 0,
        //               left: 0,
        //               padding: "20px",
        //               color: "#FFF",
        //               borderRadius: "0 20px",
        //               width: "100%",
        //             }}
        //           >
        //             <div style={{ display: "flex", flexDirection: "column" }}>
        //               <img
        //                 src={iconsData["blurredName"]}
        //                 height={20}
        //                 width={150}
        //                 style={{ marginBottom: "10px" }}
        //                 alt="Blurred name"
        //               />
        //               <img
        //                 src={iconsData["blurredTime"]}
        //                 height={20}
        //                 width={100}
        //                 style={{ marginBottom: "10px" }}
        //                 alt="Blurred time"
        //               />
        //             </div>
        //           </div>

        //           <img
        //             src={require("../../assets/q-vector.png")}
        //             style={{
        //               position: "absolute",
        //               top: "50%",
        //               left: "50%",
        //               width: "80px",
        //               height: "80px",
        //               transform: "translate(-50%, -50%)",
        //             }}
        //             onClick={() => setIsClicked(true)}
        //             alt="Question mark"
        //           />
        //         </>
        //       ) : (
        //         <>
        //           <div
        //             style={{
        //               position: "absolute",
        //               bottom: "20%",
        //               left: "50%",
        //               transform: "translateX(-50%)",
        //               cursor: "pointer",
        //               width: "60px",
        //               textAlign: "center",
        //             }}
        //           >
        //             <FaArrowDownLong color="white" size={40} />
        //           </div>
        //           <p
        //             style={{
        //               color: "white",
        //               fontWeight: "bolder",
        //               fontSize: "clamp(1.3rem, 5vw, 2em)",
        //               position: "absolute",
        //               top: "50%",
        //               left: "50%",
        //               textAlign: "center",
        //               width: "80%",
        //               transform: "translate(-50%, -50%)",
        //             }}
        //           >
        //             Follow the <span style={{ color: "#FFE500" }}>steps</span>{" "}
        //             below
        //           </p>
        //         </>
        //       )}
        //     </ImageContainer>
        //   </div>

        //   <div
        //     style={{
        //       display: "flex",
        //       justifyContent: "center",
        //       alignItems: "center",
        //       padding: "5px",
        //       margin: "2px 0px",
        //     }}
        //   >
        //     <button
        //       className="exit-button"
        //       onClick={handleClick}
        //       style={{
        //         backgroundColor: "#FFE500",
        //         fontSize: "1.3em",
        //         fontWeight: "700",
        //         padding: "10px 20px",
        //         borderRadius: "25px",
        //         border: "none",
        //         width: "100%",
        //         maxWidth: "280px",
        //       }}
        //     >
        //       {native ? `Continue 👻` : null}
        //     </button>
        //   </div>

        //   <p
        //     style={{
        //       fontWeight: "bolder",
        //       fontSize: "1.5em",
        //       color: "#FFE500",
        //       marginBottom: "10px",
        //       textAlign: "center",
        //       marginTop: -20,
        //     }}
        //   >
        //     👆🏻 See <span style={{ color: "white" }}>before it's deleted</span>{" "}
        //     👆🏻
        //   </p>

        //   <p
        //     style={{
        //       color: "white",
        //       textAlign: "center",
        //       width: "80vw",
        //       alignSelf: "center",
        //     }}
        //   >
        //     Note: This is only for light fun & entertainment. By Continuing, you
        //     accept our{" "}
        //     <a
        //       href="/tos"
        //       style={{ color: "#FFE500", textDecoration: "underline" }}
        //     >
        //       Terms
        //     </a>{" "}
        //     &{" "}
        //     <a
        //       href="/privacy"
        //       style={{ color: "#FFE500", textDecoration: "underline" }}
        //     >
        //       Privacy
        //     </a>
        //     .
        //   </p>
        // </>
        <>
          <p
            style={{
              fontWeight: 900,
              fontSize: "clamp(2.1rem, 5vw, 2.5em)",
              color: "white",
              fontFamily: "'Inter', sans-serif",
              textAlign: "center",
              marginTop: 10,
              marginRight: "10px",
              marginLeft: "10px",
              marginBottom: "-16px",
              width: "80%",
              alignSelf: "center",
            }}
          >
            1 step away from your{" "}
            <span style={{ color: "#FFE500" }}>match</span>🔥
          </p>

          <div
            style={{
              display: "flex",
              alignSelf: "center",
              justifyContent: "center",
              position: "relative",
              margin: "0px auto",
              height: "min(40vh, 400px)",
              width: "min(75%, 250px)",
              alignItems: "center",
              borderRadius: 27,
              border: "2px solid white",
              overflow: "hidden",
            }}
          >
            <ImageContainer imageUrl={backgroundImageUrl} height="100%">
              {!isClicked ? (
                <>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      padding: "20px",
                      color: "#FFF",
                      borderRadius: "0 20px",
                      width: "100%",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <img
                        src={iconsData["blurredName"]}
                        height={20}
                        width={150}
                        style={{ marginBottom: "10px" }}
                        alt="Blurred name"
                      />
                      <img
                        src={iconsData["blurredTime"]}
                        height={20}
                        width={100}
                        style={{ marginBottom: "10px" }}
                        alt="Blurred time"
                      />
                    </div>
                  </div>

                  <img
                    src={require("../../assets/q-vector.png")}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      width: "80px",
                      height: "80px",
                      transform: "translate(-50%, -50%)",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsClicked(true)}
                    alt="Question mark"
                  />
                </>
              ) : (
                <>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "20%",
                      left: "50%",
                      transform: "translateX(-50%)",
                      cursor: "pointer",
                      width: "60px",
                      textAlign: "center",
                    }}
                  >
                    <FaArrowDownLong color="white" size={40} />
                  </div>
                  <p
                    style={{
                      color: "white",
                      fontWeight: "bolder",
                      fontSize: "clamp(1.3rem, 5vw, 2em)",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      textAlign: "center",
                      width: "80%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    Follow the <span style={{ color: "#FFE500" }}>steps</span>{" "}
                    below
                  </p>
                </>
              )}
            </ImageContainer>
          </div>

          {/* Improved button container with more responsive spacing */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "clamp(5px, 2vw, 10px)",
              margin: "clamp(5px, 2vh, 15px) 0px",
              width: "100%",
            }}
          >
            <button
              className="exit-button"
              onClick={handleClick}
              style={{
                backgroundColor: "#FFE500",
                fontSize: "clamp(1.1rem, 3vw, 1.3rem)",
                fontWeight: 700,
                fontFamily: "'Inter', sans-serif",
                padding: "clamp(15px, 2vw, 12px) clamp(20px, 3vw, 25px)",
                borderRadius: "25px",
                border: "none",
                width: "min(90%, 280px)",
                maxWidth: "280px",
                boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                transition: "transform 0.2s ease",
              }}
            >
              {native ? `Continue 👻` : `Unlock 🔓`}
            </button>
          </div>

          {/* Improved text with better responsive margins */}
          <p
            style={{
              fontWeight: 700,
              fontFamily: "'Inter', sans-serif",
              fontSize: "clamp(1.2rem, 3vw, 1.8rem)",
              color: "#FFE500",
              marginBottom: "clamp(8px, 2vh, 15px)",
              textAlign: "center",
              marginTop: "clamp(-20px, -2vh, -5px)",
              padding: "0 clamp(10px, 3vw, 20px)",
              lineHeight: 1.3,
            }}
          >
            👆🏻 Sign in <span style={{ color: "white" }}>to unlock now</span> 👆🏻
          </p>

          <p
            style={{
              color: "white",
              textAlign: "center",
              width: "min(95%, 500px)",
              maxWidth: "500px",
              fontSize: "clamp(0.75rem, 2vw, 1rem)",
              alignSelf: "center",
              margin: "0 auto",
              padding: "0 clamp(5px, 2vw, 15px)",
              lineHeight: "1.4",
            }}
          >
            Note: This is only for light fun & entertainment. By Continuing, you
            accept our{" "}
            <a
              href="/tos"
              style={{ color: "#FFE500", textDecoration: "underline" }}
            >
              Terms
            </a>{" "}
            &{" "}
            <a
              href="/privacy"
              style={{ color: "#FFE500", textDecoration: "underline" }}
            >
              Privacy
            </a>
            .
          </p>
        </>
      )}
    </div>
  );
};

export default CheckoutDummy;
