import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom"; // Assuming you're using react-router-dom for navigation
import axiosInstance from "../../services/axios";
import GeneralLoadingModal from "../../components/GenericModal/GenericModal"; // Import the generic loader modal
import LoginUserPage from "../LoginUserPage/LoginUserPage";
import { UserContext } from "../../providers/UserProvider";
import { FaAngleLeft } from "react-icons/fa";
import { Height } from "@material-ui/icons";

const RequestData = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoadingModalVisible, setIsLoadingModalVisible] = useState(false);
  const [success, setSuccess] = useState("");
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (loading) {
      setIsLoadingModalVisible(true);
    } else {
      setIsLoadingModalVisible(false);
    }
  }, [loading]);

  const requestData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        "/request/web",
        { data: message, type: "request" },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setSuccess(
        `Thanks for writing! We'll write back to your registered email address: ${user.email}`
      );
    } catch (error) {
      setSuccess(
        `The request could not be completed at this time. This may be due to the absence of an account associated with the currently signed-in email, a ban from the platform, or another issue. You can try again later or reach out to us at help@checkoutmyfriend.link for assistance.`
      );
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {user ? (
        <div style={styles.container}>
          <div style={styles.headerContainer}>
            <FaAngleLeft size={34} color="white" onClick={() => navigate(-1)} />
            <h3 style={styles.descriptionText}>Request My Data</h3>
          </div>

          {success ? (
            <div
              style={{
                padding: "20px",
                marginTop: "20px",
                marginBottom: "50px",
              }}
            >
              <p
                style={{
                  color: "#A0A0A0",
                  lineHeight: "25px",
                  fontSize: "15px",
                }}
              >
                {success}
              </p>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center", // ✅ Centers the textarea
                justifyContent: "center",
                width: "100%",
                padding: "20px",
                marginTop: "20px",
                marginBottom: "50px",
              }}
            >
              <h3
                style={{
                  fontSize: "20px",
                  color: "white",
                  fontWeight: 800,
                  fontFamily: "'Inter', sans-serif",
                  alignSelf: "flex-start", // ✅ Aligns to the left
                  paddingLeft: "10px", // ✅ Adds slight left padding for spacing
                }}
              >
                Description
              </h3>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <textarea
                  style={styles.textInput}
                  placeholder="Type your message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  rows={5}
                />
              </div>
              <p
                style={{
                  color: "#A0A0A0",
                  lineHeight: "25px",
                  fontSize: "0.8rem",
                  marginTop: "0.3rem",
                  fontWeight: 500,
                  fontFamily: "'Inter', sans-serif",
                  lineHeight: 1.5,
                  //   textAlign: "center",
                }}
              >
                Please enter the details of your request and we will respond as
                soon as possible.
              </p>
            </div>
          )}

          <div
            style={{
              width: "100%",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {success ? (
              <button
                disabled={loading || message.length < 2}
                onClick={() => navigate(-1)}
                style={{ ...styles.button, backgroundColor: "#FFFFFF" }}
              >
                Got it!
              </button>
            ) : (
              <div
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  margin: 20,
                  display: "FLEX",
                  justifyContent: "center",
                }}
              >
                <button
                  disabled={loading || message.length < 2}
                  onClick={requestData}
                  style={{
                    ...styles.button,
                    backgroundColor: message.length < 2 ? "#5D5D5D" : "#FFFFFF",
                    color: message.length < 2 ? "#FFFFFF" : "#000000", // Text color changes
                    maxWidth: "400px",
                  }}
                >
                  Submit
                </button>

                <button
                  onClick={() => navigate(-1)}
                  style={{
                    ...styles.button,
                    backgroundColor: "#000000",
                    color: "#FFFFFF",
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>

          {isLoadingModalVisible && (
            <GeneralLoadingModal isVisible={isLoadingModalVisible} />
          )}
        </div>
      ) : (
        <LoginUserPage />
      )}
    </>
  );
};

const styles = {
  container: {
    padding: "10px",
    maxWidth: "700px",
    backgroundColor: "#000",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  headerContainer: {
    display: "flex", // Use flexbox
    alignItems: "center", // Align items vertically
    gap: "10px", // Add space between arrow and text
    width: "100%", // Full width
    padding: "0px 20px", // Add some padding
  },
  descriptionText: {
    fontSize: "20px",
    color: "white",
    fontWeight: 800,
    fontFamily: "'Inter', sans-serif",
    margin: 0, // Remove default margin
  },

  textInput: {
    width: "98%",
    maxWidth: "500px",
    backgroundColor: "#181818",
    borderRadius: "20px",
    color: "white",
    padding: "20px",
    border: "0.5px solid #909090",
    fontSize: "18px",
    marginTop: "20px",
    marginBottom: "20px",
  },
  headerText: {
    fontSize: "1.6rem",
    fontWeight: 900,

    fontFamily: "'Inter', sans-serif",
    marginTop: "-200px",
  },
  button: {
    padding: "20px",
    borderRadius: "30px",
    width: "95%",
    marginBottom: "20px",
    fontWeight: 600,
    fontFamily: "'Inter', sans-serif",
    cursor: "pointer",
    border: "none",
    textAlign: "center",
    fontSize: "15px",
    color: "#000000",
  },
};

export default RequestData;
