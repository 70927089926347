import React from "react";
import BackgroundImage from "../../components/BackgroundImage/BackgroundImage";
import { backgrounds } from "../../data/backgrounds";
import { iconsData } from "../../data/icons";

const Error = ({}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
    >
      <BackgroundImage imageUrl={backgrounds["default"]} />
      <p
        style={{
          fontSize: "2.2em",
          width: "80%",
          maxWidth: "300px",
          fontWeight: 900,
          fontFamily: "'Inter', sans-serif",
          textAlign: "center",
          color: "white",
          marginTop: "2rem",
        }}
      >
        Uh-oh! Can’t find what you’re looking for?
      </p>

      <p
        style={{
          fontSize: "1.3em",
          width: "80%",
          fontWeight: 700,
          fontFamily: "'Inter', sans-serif",
          textAlign: "center",
          color: "white",
        }}
      >
        But you may be interested in👇🏻
      </p>

      <img
        src={require("../../assets/see-who-likes-your-friend.png")}
        style={{ width: "80%", maxWidth: "300px", marginTop: -30 }}
      />

      <img
        src={iconsData["cmfWhite"]}
        style={{ width: "60%", maxWidth: "300px" }}
      />
    </div>
  );
};

export default Error;
// import React from "react";
// import "./Error.css";

// const Error = () => {
//   return (
//     <div className="error-container">
//       <div className="error-background"></div>

//       <div className="error-content">
//         <h1 className="error-heading">
//           Uh-oh! Can't find what you're looking for?
//         </h1>

//         <p className="error-subtext">But you may be interested in 👇🏻</p>

//         <div className="error-button-container">
//           <button className="error-button">See who likes your friend</button>
//           <div className="error-button-shadow"></div>
//         </div>

//         <p className="error-footer">checkout my Friend</p>
//       </div>
//     </div>
//   );
// };

// export default Error;
