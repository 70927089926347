import React from "react";
import { backgrounds } from "../../data/backgrounds";

const ExitModal = ({
  show,
  handleClose,
  handleInstagramConnect,
  children,
  showConnectBtn = true,
}) => {
  if (!show) {
    return null;
  }

  const imageUrl = backgrounds["date"];

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          width: "70vw",
          background: `url(${imageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "20px",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {children}
        {showConnectBtn && (
          <button
            onClick={handleInstagramConnect}
            style={{
              marginTop: "20px",
              padding: "20px 30px",
              backgroundColor: "#FFF",
              border: "none",
              borderRadius: "50px",
              cursor: "pointer",
              fontWeight: "900",
              width: "65%",
              fontSize: "1.2em",
            }}
          >
            Connect
          </button>
        )}

        <button
          onClick={handleClose}
          style={{
            marginTop: "20px",
            padding: "20px 30px",
            backgroundColor: "#000",
            color: "#fff",
            border: "none",
            borderRadius: "50px",
            cursor: "pointer",
            fontWeight: "900",
            width: "65%",
            fontSize: "1.2em",
          }}
        >
          Nope
        </button>
      </div>
    </div>
  );
};

export default ExitModal;
