import React, { useEffect, useRef, useState } from "react";
import { FaAngleLeft, FaArrowLeft } from "react-icons/fa";
import BackgroundImage from "../components/BackgroundImage/BackgroundImage";
import { backgrounds } from "../data/backgrounds";
import CircularImage from "../components/CircularImage/CircularImage";
// import { backgrounds } from "../../data/backgrounds";
const InstagramConnectPage = ({
  backgroundImageUrl,
  profileImageUrl,
  onCancel,
  onDone,
  type,
  circularBackgroundImage,
}) => {
  const [instagramUsername, setInstagramUsername] = useState("");
  const inputRef = useRef(null);

  // Focus the input field to open keyboard on mount
  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 300);
    }
  }, []);

  const handleButtonClick = { onDone };

  return (
    <div style={styles.container}>
      {/* Background Color
      <div style={styles.background}></div> */}
      <BackgroundImage imageUrl={backgrounds["date"]} />

      {/* Back Button */}
      <div
        onClick={onDone}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          margin: 15,
          backgroundColor: "rgba(255, 255, 255, 0.36)", // 36% opacity of white
          borderRadius: 50,
          width: 45,
          height: 45,
          padding: 10,
          position: "absolute",
          top: 15,
          left: 10,
        }}
      >
        <FaAngleLeft size={34} style={{ color: "#ffffff" }} />
      </div>

      {/* Content Container */}
      <div style={styles.contentContainer}>
        {/* Profile Image */}
        {/* <div style={styles.profileImageContainer}>
          <img
            src={profileImageUrl}
            alt="Profile"
            style={styles.profileImage}
          />
        </div> */}
        <CircularImage
          backgroundImageUrl={circularBackgroundImage}
          // icon={iconsData[type]}
        />

        {/* Text Content */}
        <h1 style={styles.title}>Add Instagram</h1>
        <p style={styles.subtitle}>to connect personally 🫰</p>

        {/* Input Field */}
        <div style={styles.inputContainer}>
          <input
            type="text"
            ref={inputRef}
            placeholder="@Username"
            value={instagramUsername}
            onChange={(e) => setInstagramUsername(e.target.value)}
            style={styles.input}
          />
        </div>

        {/* Button */}
        <button style={styles.button} onClick={onDone}>
          {instagramUsername.length >= 5 ? "Done" : "Cancel"}
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: "relative",
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
  },
  background: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#ff1a75",
    background: "linear-gradient(to bottom, #ff1a75 60%, #ff0000)",
    zIndex: -1,
  },
  backButton: {
    position: "absolute",
    top: "30px",
    left: "20px",
    // width: "55px",
    // height: "55px",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    zIndex: 10,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxWidth: "500px",
    padding: "0 20px",
    marginTop: "60px",
  },
  profileImageContainer: {
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    border: "4px solid white",
    overflow: "hidden",
    marginBottom: "30px",
  },
  profileImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  // title: {
  //   fontSize: "34px",
  //   fontWeight: 900,
  //   color: "white",
  //   margin: "0",
  //   textAlign: "center",
  // },
  // subtitle: {
  //   fontSize: "20px",
  //   fontWeight: 700,
  //   color: "white",
  //   margin: "0px 0 40px",
  //   textAlign: "center",
  // },
  title: {
    fontSize: "34px",
    fontWeight: 900,
    color: "white",
    margin: "0",
    textAlign: "center",
    display: "block",
    width: "100%",
    fontFamily: "'Inter', sans-serif", // ✅ Inter Font
    // lineHeight: "px", // ✅ Adjust line height to reduce space between lines
  },
  subtitle: {
    fontSize: "20px",
    fontWeight: 700,
    color: "white",
    margin: "0px 0 40px",
    textAlign: "center",
    display: "block",
    fontFamily: "'Inter', sans-serif", // ✅ Inter Font
    lineHeight: "22px", // ✅ Reduce space between lines
    width: "100%",
  },
  inputContainer: {
    width: "100%",
    maxWidth: "360px",
    marginBottom: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    width: "95%",
    padding: "24px 21px",
    fontSize: "18px",
    borderRadius: "10px",
    border: "none",
    outline: "none",
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    color: "white",
    fontWeight: "500",
    fontFamily: "'Poppins', sans-serif", // ✅ Poppins Font
    boxSizing: "border-box",
    caretColor: "white", // ✅ Makes the typing cursor white
  },

  button: {
    width: "80%",
    maxWidth: "360px",
    fontWeight: 600,
    padding: "16px",
    borderRadius: "40px",
    border: "none",
    backgroundColor: "#B30000",
    padding: "25px 17px",
    color: "white",
    fontSize: "18px",
    cursor: "pointer",
    marginTop: "20px",
  },
};

export default InstagramConnectPage;
