import React, { useState, useEffect } from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  const [activeSection, setActiveSection] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSection = (sectionId) => {
    setActiveSection(activeSection === sectionId ? null : sectionId);
  };

  const sections = [
    {
      id: "introduction",
      title: "Introduction",
      content: (
        <>
          <p>
            Welcome to the Privacy Policy of "Checkout My Friend" which is made
            available by ExtasyTechnologies ("our", "us", "we"), a registered
            Partnership Firm located in - Kushal Konwar Path, Professor Colony,
            Rangagora Road, Tinsukia, Assam 786125, India. We recognise that
            your privacy is critically important to us, and we are committed to
            protecting your personal information.
          </p>
          <p>
            This Privacy Policy outlines how we collect, use, share, and protect
            your personal information, and describes your rights and choices
            regarding your information.
          </p>
          <p>
            This Privacy Policy applies to all users of the "Checkout My Friend"
            mobile application and any associated services (collectively
            referred to as the "Services"). By using our Services, you agree to
            the collection, use, and sharing of your information as described in
            this Privacy Policy.
          </p>
        </>
      ),
    },
    {
      id: "collection",
      title: "1. Personal Information Collection",
      content: (
        <>
          <h3>1.1 Types of Personal Information We Collect</h3>
          <p>
            We collect various types of personal information from you depending
            on your interaction with our Services:
          </p>
          <ul>
            <li>
              <strong>Account Information:</strong> When you sign up via Google
              OAuth or Apple OAuth to use our Services, we collect your name,
              email address, profile picture, and gender.
            </li>
            <li>
              <strong>Third-Party Content:</strong> We collect content that
              features third parties (your friend) which you upload to the app.
              This content is securely managed and shared with the intended
              audience via the web interface, as specified by you. You must
              obtain explicit consent from your friends before sharing their
              photos via the app.
            </li>
            <li>
              <strong>Reactions:</strong> We collect user reactions or likes
              (e.g., date, chill, hookup, or prank) along with any pre-written
              messages associated with these reactions that users send or
              receive through the Services.
            </li>
            <li>
              <strong>Friend's Tag:</strong> We collect the one-word description
              you select for your friend from predefined options when you upload
              their photo.
            </li>
            <li>
              <strong>Instagram Username:</strong> We collect Instagram
              Usernames from users who interact with shared content via links on
              social networks.
            </li>
            <li>
              <strong>Feedback and Correspondence:</strong> We collect
              information that you provide when contacting us for support,
              submitting feedback, or reporting issues.
            </li>
            <li>
              <strong>Automatic Data Collection:</strong> Information about your
              device and usage patterns when interacting with our Services.
            </li>
          </ul>

          <h3>1.2 How We Collect Personal Information</h3>
          <p>Personal information is collected directly from you when you:</p>
          <ul>
            <li>
              Download and interact with the "Checkout My Friend" app or
              website.
            </li>
            <li>Sign up for our Services using Google OAuth or Apple OAuth.</li>
            <li>
              Upload photographic content, send reactions, or provide feedback.
            </li>
          </ul>
        </>
      ),
    },
    {
      id: "use",
      title: "2. Use of Personal Information",
      content: (
        <>
          <h3>2.1 Purpose of Collecting Personal Information</h3>
          <p>
            We use the personal information we collect for the following
            purposes:
          </p>
          <ul>
            <li>
              <strong>Providing and Supporting the Services:</strong> To
              operate, maintain, and improve the functionality of our Services.
            </li>
            <li>
              <strong>Improvement and Personalization:</strong> To enhance and
              personalize your experience.
            </li>
            <li>
              <strong>Marketing and Advertising:</strong> With your consent, we
              may send you marketing communications about special promotions,
              offers, and events.
            </li>
            <li>
              <strong>Compliance and Legal Obligations:</strong> To comply with
              legal requirements, enforce our terms of use, and defend ourselves
              against potential legal claims.
            </li>
          </ul>
        </>
      ),
    },
    {
      id: "sharing",
      title: "3. Sharing of Personal Information",
      content: (
        <>
          <h3>3.1 With Whom We Share Personal Information</h3>
          <p>
            We may share your personal information under the following
            circumstances:
          </p>
          <ul>
            <li>
              <strong>With Other Users:</strong> We share personal information,
              including the name and profile picture of the sender of feedback
              or reactions, with users who have subscribed to our premium
              service ("Checkout Pass").
            </li>
            <li>
              <strong>Service Providers:</strong> We may share your personal
              information with third-party service providers who assist us in
              operating our Services.
            </li>
            <li>
              <strong>Compliance, Fraud Prevention, and Safety:</strong> We may
              disclose your information to legal authorities or other parties
              when necessary to comply with legal obligations.
            </li>
            <li>
              <strong>Business Transfers:</strong> In the event of a corporate
              transaction, such as a merger, acquisition, reorganization, or
              sale of assets.
            </li>
          </ul>

          <h3>3.2 How Users Share Their Personal Information</h3>
          <p>
            Initiator (user who shares the photo of their friend) and Subject
            (user whose photo has been shared), may choose to post their
            responses to pre-written messages and likes received through the
            services, on their social media accounts.
          </p>
        </>
      ),
    },
    {
      id: "safety",
      title: "4. User Safety and Content Moderation",
      content: (
        <>
          <h3>4.1 Reporting Inappropriate Content</h3>
          <p>
            We are committed to maintaining a safe environment on our platform.
            If you encounter any photographic content that violates our
            community guidelines, you can report it directly through the web by
            selecting the "Report" option.
          </p>

          <h3>4.2 Content Moderation Practices</h3>
          <p>
            Before uploading any content, users must agree to our Photo
            Guidelines, which include:
          </p>
          <ul>
            <li>Explicit consent from individuals depicted.</li>
            <li>
              No nudity, sexually explicit material, or child exploitation.
            </li>
            <li>No violence, self-harm, or illegal activities.</li>
            <li>Respect for the privacy and dignity of others.</li>
          </ul>

          <h3>4.3 Consent Breach</h3>
          <p>
            In situations where your photographic content is used without your
            explicit consent, you can report the unauthorized use by contacting
            us at help@checkoutmyfriend.link.
          </p>
        </>
      ),
    },
    {
      id: "retention",
      title: "5. Data Retention",
      content: (
        <>
          <p>
            We retain personal information for as long as necessary to fulfill
            the purposes outlined in this Privacy Policy, unless a longer
            retention period is required or permitted by law. The criteria used
            to determine our retention periods include:
          </p>
          <ul>
            <li>
              <strong>Duration of Your Relationship with Us:</strong> We retain
              your information while your account is active or as needed to
              provide you with the Services.
            </li>
            <li>
              <strong>Legal Obligations:</strong> We may retain data for an
              extended period when required to comply with legal obligations.
            </li>
            <li>
              <strong>Dispute Resolution and Enforcement:</strong> We may retain
              information as necessary to resolve disputes, enforce our
              agreements, and protect our legal rights.
            </li>
          </ul>
        </>
      ),
    },
    {
      id: "security",
      title: "6. Data Security",
      content: (
        <>
          <h3>6.1 Security Measures</h3>
          <p>
            We implement appropriate technical and organizational measures to
            protect your personal information, including:
          </p>
          <ul>
            <li>
              <strong>Encryption:</strong> We implement industry-standard
              security protocols to safeguard our system.
            </li>
            <li>
              <strong>Access Controls:</strong> We restrict access to personal
              information to employees, contractors, and service providers who
              need that information.
            </li>
            <li>
              <strong>Regular Security Audits:</strong> We regularly audit our
              security practices and infrastructure.
            </li>
          </ul>

          <h3>6.2 User Responsibilities</h3>
          <p>
            While we take security seriously, no method of transmission over the
            internet or electronic storage is 100% secure. You are responsible
            for maintaining the confidentiality of your account credentials.
          </p>
        </>
      ),
    },
    {
      id: "international",
      title: "7. International Data Transfers",
      content: (
        <>
          <p>
            Currently, our services are available only within India, and all
            data processing is restricted to India. We operate solely within
            this region and do not engage in international data transfers.
          </p>
        </>
      ),
    },
    {
      id: "rights",
      title: "8. Your Rights and Choices",
      content: (
        <>
          <h3>8.1 Accessing and Updating Your Information</h3>
          <p>
            You have the right to access and update the personal information we
            hold about you. You may manage and update certain aspects of your
            account information through the settings section of the app.
          </p>

          <h3>8.2 Opting Out of Marketing Communications</h3>
          <p>
            You may opt out of receiving marketing communications from us at any
            time by following the unsubscribe instructions provided in the
            emails or by contacting us directly.
          </p>

          <h3>8.3 Withdrawing Consent</h3>
          <p>
            Where we rely on your consent to process your personal information,
            you have the right to withdraw your consent at any time.
          </p>

          <h3>8.4 Right to Deletion</h3>
          <p>
            You are entitled to exercise your right to delete your personal
            information at any time. To do so, access the settings page of your
            app and select the "Delete" option or request us at
            http://checkoutmyfriend.link/delete-account.
          </p>

          <h3>8.5 Right to Block</h3>
          <p>
            As a user, you have the right to control who can collaborate with
            you within the app. When you block another user, all shared posts
            and mutual activities between you and your friend will be deleted.
          </p>

          <h3>8.6 Right to Object</h3>
          <p>
            You have the right to object to the processing of your personal
            information where we rely on legitimate interests as the legal basis
            for processing.
          </p>

          <h3>8.7 Exercising Your Rights</h3>
          <p>
            To exercise any of your rights, please contact us at
            help@checkoutmyfriend.link.
          </p>
        </>
      ),
    },
    {
      id: "children",
      title: "9. Children's Privacy",
      content: (
        <>
          <p>
            "Checkout My Friend" is not intended for use by individuals under
            the age of 18. We do not knowingly collect personal information from
            children under 18. If we become aware that we have inadvertently
            collected personal information from a child under 18, we will take
            steps to delete such information promptly.
          </p>
        </>
      ),
    },
    {
      id: "third-party",
      title: "10. Third-Party Services and Links",
      content: (
        <>
          <p>
            Our platform may contain links to external websites and services,
            including, but not limited to, our official Instagram page, which
            can be accessed through the app settings page for the purpose of
            following us on Instagram.
          </p>
          <p>
            Please be advised that these third-party websites and services
            operate independently of our platform and are governed by their own
            privacy practices.
          </p>
        </>
      ),
    },
    {
      id: "changes",
      title: "11. Changes to This Privacy Policy",
      content: (
        <>
          <p>
            We may update this Privacy Policy from time to time in response to
            changing legal, technical, or business developments. When we update
            our Privacy Policy, we will take appropriate measures to inform you,
            consistent with the significance of the changes we make.
          </p>

          <h3>11.1 Notification of Changes</h3>
          <p>
            We will notify you of any changes to this Privacy Policy by posting
            the updated policy within the app and revising the "Last Updated"
            date at the top of this page.
          </p>
        </>
      ),
    },
    {
      id: "contact",
      title: "12. Contact Us",
      content: (
        <>
          <p>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at:
          </p>
          <p>
            <a href="https://www.checkoutmyfriend.link/contact-us">
              https://www.checkoutmyfriend.link/contact-us
            </a>
          </p>
        </>
      ),
    },
  ];

  return (
    <div className="privacy-policy-container">
      <header className="privacy-header">
        <div className="logo-container">
          <div className="logo">
            {/* <span className="emoji">😊</span> */}
            <h1>Privacy Policy</h1>
          </div>
        </div>
        {/* <h2>Privacy Policy</h2> */}
        <p className="last-updated">Last Updated: August 28th, 2024</p>
      </header>

      <div className="privacy-content">
        {isMobile ? (
          // Mobile view: Accordion
          <div className="mobile-accordion">
            {sections.map((section) => (
              <div key={section.id} className="accordion-section">
                <button
                  className={`accordion-button ${
                    activeSection === section.id ? "active" : ""
                  }`}
                  onClick={() => toggleSection(section.id)}
                >
                  {section.title}
                  <span className="accordion-icon">
                    {activeSection === section.id ? "−" : "+"}
                  </span>
                </button>
                <div
                  className={`accordion-content ${
                    activeSection === section.id ? "active" : ""
                  }`}
                >
                  {section.content}
                </div>
              </div>
            ))}
          </div>
        ) : (
          // Desktop view: Table of contents + content
          <div className="desktop-layout">
            <nav className="table-of-contents">
              <h3>Contents</h3>
              <ul>
                {sections.map((section) => (
                  <li key={section.id}>
                    <a
                      href={`#${section.id}`}
                      className={activeSection === section.id ? "active" : ""}
                      onClick={(e) => {
                        e.preventDefault();
                        document
                          .getElementById(section.id)
                          .scrollIntoView({ behavior: "smooth" });
                        setActiveSection(section.id);
                      }}
                    >
                      {section.title}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
            <div className="sections-content">
              {sections.map((section) => (
                <section
                  key={section.id}
                  id={section.id}
                  className="policy-section"
                >
                  <h2>{section.title}</h2>
                  {section.content}
                </section>
              ))}
            </div>
          </div>
        )}
      </div>

      <footer className="privacy-footer">
        <div className="footer-links">
          <a href="/terms">Terms of Service</a>
          <a href="/about">About Product</a>
          <a href="/support">Support</a>
        </div>
        <div className="social-icons">
          <a href="#" className="social-icon">
            <i className="instagram"></i>
          </a>
          <a href="#" className="social-icon">
            <i className="linkedin"></i>
          </a>
          <a href="#" className="social-icon">
            <i className="twitter"></i>
          </a>
        </div>
        <p>© 2024 ExtasyTechnologies. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
