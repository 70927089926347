// import React from 'react'
// import BackgroundImage from '../../components/BackgroundImage/BackgroundImage'
// import { backgrounds } from '../../data/backgrounds'

// const ServerError = ({}) => {
//   return (
//     <div style={{display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'space-evenly', alignItems: 'center'}}>
//         <BackgroundImage imageUrl={backgrounds['checkout2']} />
//         <p style={{fontSize: '3em', width: '80%', fontWeight: 'bolder', textAlign: 'center', color: 'white'}}>Oops! we broke the <span style={{color: '#FFE500'}}>internet</span> 😱</p>

//       <div style={{width: '80vw', position: 'relative', alignItems: 'center' , display: 'flex', justifyContent: 'center', borderRadius: 50}}>
//       <img src={require('../../assets/server-crash.gif')} style={{width: '100%', marginTop: -30, borderRadius: 50}}/>
//       <p style={{position: 'absolute', bottom: 20, left: 30, color: 'white', fontSize: '2em', fontWeight: 'bolder'}}>Fixing it, wait a sec!</p>
//       </div>

//         <p style={{fontSize: '1.5em', width: '80%', fontWeight: 'bolder', textAlign: 'center', color: 'white', fontStyle: 'italic'}}>Damn, you all are wild!🔥 Too many people are checking out their friends. Hold up, let us catch our breath.</p>
//     </div>
//   )
// }

// export default ServerError

import React from "react";
import BackgroundImage from "../../components/BackgroundImage/BackgroundImage";
import { backgrounds } from "../../data/backgrounds";
import "./ServerError.css";

const ServerError = () => {
  return (
    <div className="server-error-container">
      {/* Background component */}
      <BackgroundImage imageUrl={backgrounds["checkout2"]} />

      {/* Main heading */}
      <h1 className="server-error-heading">
        Oops! we broke the{" "}
        <span className="server-error-highlight">internet</span> 😱
      </h1>

      {/* GIF container with overlay text */}
      <div className="server-error-gif-container">
        <img
          src={require("../../assets/server-crash.gif")}
          className="server-error-gif"
          alt="Server crash"
        />
        <p className="server-error-overlay-text">Fixing it, wait a sec!</p>
      </div>

      {/* Footer text */}
      <p className="server-error-footer">
        Damn, you all are wild!🔥 Too many people are checking out their
        friends. Hold up, let us catch our breath.
      </p>
    </div>
  );
};

export default ServerError;
