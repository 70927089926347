// import React from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { iconsData } from "../../data/icons";

// const Card = ({ avatar, title, description }) => {
//   return (
//     <div
//       style={{
//         backgroundColor: "#FFFFFF",
//         border: "5px solid #BEBEBE8A", // 54% opacity
//         borderRadius: "10px",
//         marginBottom: 10,
//         padding: "10px",
//         display: "flex",
//         flexDirection: "column",
//         height: "160px", // Adjust height as needed
//       }}
//     >
//       {/* Top Section */}
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "row",
//           justifyContent: "space-between",
//           alignItems: "center",
//         }}
//       >
//         {/* Avatar Image */}
//         <img
//           src={avatar}
//           alt="Avatar"
//           style={{
//             width: "30px",
//             height: "30px",
//             borderRadius: "50%",
//           }}
//         />

//         {/* Title */}
//         <span
//           style={{
//             backgroundColor: "#FFE500",
//             paddingRight: "16px",
//             paddingLeft: "16px",
//             paddingTop: "4px",
//             paddingBottom: "4px",
//             borderRadius: "13px",
//             border: "1px solid black",
//             fontWeight: "bold",
//             color: "black",
//             fontSize: "15px",
//           }}
//         >
//           {title}
//         </span>

//         {/* Prank Image (Replace URL later) */}
//         <img
//           src={iconsData["prank"]} // Update with actual prank image URL
//           alt="Prank"
//           style={{ width: "60px", height: "60px" }}
//         />
//       </div>

//       {/* Description Section */}
//       <div
//         style={{
//           fontSize: "1.1em",
//           fontWeight: 500,
//           fontFamily: "Inter",
//           paddingLeft: 10,
//           marginRight: 10,
//           marginBottom: 10,
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           width: "100%",

//           // marginTop: 30,
//         }}
//       >
//         {/* {description.trim().length > 60
//           ? description.trim().substring(0, 100) + "..."
//           : description.trim()} */}
//         {description}
//       </div>
//     </div>
//   );
// };

// const Carousel = ({ data }) => {
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     arrows: false,
//     appendDots: (dots) => (
//       <div
//         style={{
//           alignSelf: "center",
//           alignItems: "center",
//           display: "flex",
//           justifyContent: "center",
//           marginTop: "10px",
//         }}
//       >
//         <ul style={{ display: "flex", gap: "8px", marginLeft: -40 }}>
//           {dots.map((dot, index) => (
//             <li
//               key={index}
//               style={{
//                 width: "10px",
//                 height: "10px",
//                 backgroundColor: "white",
//                 borderRadius: "50%",
//                 listStyle: "none",
//                 cursor: "pointer",
//                 opacity: dot.props.className.includes("slick-active") ? 1 : 0.5,
//               }}
//             />
//           ))}
//         </ul>
//       </div>
//     ),
//     customPaging: () => <div style={{ width: "10px", height: "10px" }} />,
//   };

//   return (
//     <div style={{ width: "80vw", margin: "auto", marginBottom: 50 }}>
//       <Slider {...settings}>
//         {data.map((item, index) => (
//           <Card key={index} {...item} />
//         ))}
//       </Slider>
//     </div>
//   );
// };

// export default Carousel;
// import React from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { iconsData } from "../../data/icons";

// const Card = ({ avatar, title, description }) => {
//   return (
//     <div
//       style={{
//         backgroundColor: "#FFFFFF",
//         border: "5px solid #BEBEBE8A", // 54% opacity
//         borderRadius: "10px",
//         marginBottom: 10,
//         padding: "10px",
//         display: "flex",
//         flexDirection: "column",
//         height: "160px", // Adjust height as needed
//       }}
//     >
//       {/* Top Section */}
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "row",
//           justifyContent: "space-between",
//           alignItems: "center",
//         }}
//       >
//         {/* Avatar Image */}
//         <img
//           src={avatar}
//           alt="Avatar"
//           style={{
//             width: "30px",
//             height: "30px",
//             borderRadius: "50%",
//           }}
//         />

//         {/* Title */}
//         <span
//           style={{
//             backgroundColor: "#FFE500",
//             paddingRight: "16px",
//             paddingLeft: "16px",
//             paddingTop: "4px",
//             paddingBottom: "4px",
//             borderRadius: "13px",
//             border: "1px solid black",
//             fontWeight: "bold",
//             color: "black",
//             fontSize: "15px",
//           }}
//         >
//           {title}
//         </span>

//         {/* Prank Image (Replace URL later) */}
//         <img
//           src={iconsData["prank"]} // Update with actual prank image URL
//           alt="Prank"
//           style={{ width: "60px", height: "60px" }}
//         />
//       </div>

//       {/* Description Section */}
//       <div
//         style={{
//           fontSize: "1.1em",
//           fontWeight: 500,
//           fontFamily: "Inter",
//           paddingLeft: 10,
//           marginRight: 10,
//           marginBottom: 10,
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           width: "100%",
//           // marginTop: 30,
//         }}
//       >
//         {/* {description.trim().length > 60
//           ? description.trim().substring(0, 100) + "..."
//           : description.trim()} */}
//         {description}
//       </div>
//     </div>
//   );
// };

// const Carousel = ({ data }) => {
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     arrows: false,
//     appendDots: (dots) => (
//       <div
//         style={{
//           alignSelf: "center",
//           alignItems: "center",
//           display: "flex",
//           justifyContent: "center",
//           marginTop: "10px",
//         }}
//       >
//         <ul style={{ display: "flex", gap: "8px", marginLeft: -40 }}>
//           {dots.map((dot, index) => (
//             <li
//               key={index}
//               style={{
//                 width: "10px",
//                 height: "10px",
//                 backgroundColor: "white",
//                 borderRadius: "50%",
//                 listStyle: "none",
//                 cursor: "pointer",
//                 opacity: dot.props.className.includes("slick-active") ? 1 : 0.5,
//               }}
//             />
//           ))}
//         </ul>
//       </div>
//     ),
//     customPaging: () => <div style={{ width: "10px", height: "10px" }} />,
//   };

//   return (
//     <div
//       style={{
//         width: "100%",
//         maxWidth: "500px",
//         margin: "auto",
//         marginBottom: 50,
//       }}
//     >
//       <Slider {...settings}>
//         {data.map((item, index) => (
//           <Card key={index} {...item} />
//         ))}
//       </Slider>
//     </div>
//   );
// };

// export default Carousel;
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { iconsData } from "../../data/icons";

const Card = ({ avatar, title, description }) => {
  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        border: "5px solid #BEBEBE8A", // 54% opacity
        borderRadius: "10px",
        marginBottom: 0, // Reduced from 10px to 0
        // padding: "10px",
        paddingTop: "10px",

        display: "flex",
        flexDirection: "column",
        height: "160px", // Adjust height as needed
      }}
    >
      {/* Top Section */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          paddingLeft: "12px",
          paddingRight: "3px",
        }}
      >
        {/* Avatar Image */}
        <img
          src={avatar}
          alt="Avatar"
          style={{
            width: "30px",
            height: "30px",
            borderRadius: "50%",
          }}
        />

        {/* Title */}
        <span
          style={{
            backgroundColor: "#FFE500",
            paddingRight: "16px",
            paddingLeft: "16px",
            paddingTop: "4px",
            paddingBottom: "4px",
            borderRadius: "13px",
            border: "1px solid black",
            fontWeight: "bold",
            color: "black",
            fontSize: "14px",
            marginLeft: "20px",
          }}
        >
          {title}
        </span>

        {/* Prank Image (Replace URL later) */}
        <img
          src={iconsData["prank"]} // Update with actual prank image URL
          alt="Prank"
          style={{ width: "60px", height: "60px" }}
        />
      </div>

      {/* Description Section */}
      <div
        style={{
          fontSize: "1.1em",
          fontWeight: 500,
          fontFamily: "Inter",
          paddingLeft: 10,
          marginRight: 10,
          marginBottom: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%", // Take up remaining space
          textAlign: "center", // Center the text horizontally
        }}
      >
        {description}
      </div>
    </div>
  );
};

const Carousel = ({ data }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    appendDots: (dots) => (
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginTop: "-10px", // Reduced from 10px to 2px
        }}
      >
        <ul
          style={{
            display: "flex",
            // gap: "0px",
            padding: 0,
            margin: 0,
            listStyle: "none",
            justifyContent: "center",
          }}
        >
          {dots.map((dot, index) => (
            <li
              key={index}
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: "white",
                borderRadius: "50%",
                listStyle: "none",
                cursor: "pointer",
                opacity: dot.props.className.includes("slick-active") ? 1 : 0.5,
              }}
            />
          ))}
        </ul>
      </div>
    ),
    customPaging: () => <div style={{ width: "10px", height: "10px" }} />,
  };

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "500px",
        margin: "auto",
        marginBottom: 50,
      }}
    >
      <Slider {...settings}>
        {data.map((item, index) => (
          <Card key={index} {...item} />
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
