import React, { useContext, useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"; // Import Skeleton Loader
import "react-loading-skeleton/dist/skeleton.css";
import "./PromptList.css";
import { UserContext } from "../../providers/UserProvider";
import axiosInstance from "../../services/axios";

const PromptList = ({ onItemClick, type }) => {
  const { user } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        setLoading(true); // Start loading when API call starts
        try {
          const response = await axiosInstance.get(
            `/configurations/type/${type}`,
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          );
          setData(response.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false); // Stop loading when API call completes
        }
      }
    };

    fetchData();
  }, [user, type]);

  return (
    <div
      style={{
        marginTop: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {loading
        ? // Show skeleton loaders while loading
          Array(3)
            .fill()
            .map((_, index) => (
              <SkeletonTheme baseColor="#d9d9d9" highlightColor="#ffffff">
                <div
                  key={index}
                  style={{
                    backgroundColor: "#fff",
                    width: "90%",
                    margin: 20,
                    padding: 20,
                    borderRadius: 15,
                  }}
                >
                  <Skeleton
                    width={"90%"}
                    height={20}
                    className="prompt-title"
                  />
                  <Skeleton
                    width={"90%"}
                    height={15}
                    className="prompt-description"
                  />
                </div>
              </SkeletonTheme>
            ))
        : // Render real content after loading
          data.map((item) => (
            <div
              key={item._id}
              className="prompt-card"
              onClick={() => onItemClick(item._id)}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="prompt-title">{item.title}</div>
                <img
                  src="https://i.ibb.co/HPvW9p6/Group-2735.png"
                  style={{ width: "20px", height: "10px" }}
                />
              </div>
              <div className="prompt-description">{item.description}</div>
            </div>
          ))}
    </div>
  );
};

export default PromptList;
