// import React from "react";

// function ImageContainer({ imageUrl, children, blur = false, width = "100%" }) {
//   return (
//     <div
//       style={{
//         width: width, // Adjust width as needed
//         height: "100%", // Adjust height as needed
//         borderRadius: 27, // Rounded corners
//         backgroundSize: "cover",
//         backgroundImage: `url(${imageUrl})`,
//         position: "relative",
//         overflow: "hidden",
//         filter: blur ? "blur(8px)" : "blur(0px)",
//       }}
//     >
//       {children}
//     </div>
//   );
// }

// export default ImageContainer;
import React from "react";

function ImageContainer({
  imageUrl,
  children,
  blur = false,
  width = "100%",
  height = "95%",
}) {
  return (
    <div
      style={{
        width: width,
        height: height,
        maxWidth: "800px",
        borderRadius: 27,
        backgroundSize: "cover", // Ensures the image covers the entire container
        backgroundPosition: "center", // Centers the image
        backgroundRepeat: "no-repeat", // Prevents tiling
        backgroundImage: `url(${imageUrl})`,
        position: "relative",
        overflow: "hidden",
        filter: blur ? "blur(8px)" : "blur(0px)",
      }}
    >
      {children}
    </div>
  );
}

export default ImageContainer;
