// import { useState, useEffect, useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Checkbox, CircularProgress } from '@mui/material';
// import Entypo from '@mui/icons-material/ChevronLeft';
// import axiosInstance from '../../services/axios';
// import { UserContext } from '../../providers/UserProvider';
// import LoginUserPage from '../LoginUserPage/LoginUserPage';
// import GenericLoadingModal from '../../components/GenericModal/GenericModal';

// const DeleteAccount = () => {
//     const navigate = useNavigate();
//     const [isChecked, setIsChecked] = useState(false);
//     const [isLoading, setIsLoading] = useState(false);
//     const [isLoadingModalVisible, setIsLoadingModalVisible] = useState(false);
//     const [success, setSuccess] = useState('');
//     const { user } = useContext(UserContext);

//     useEffect(() => {
//         setIsLoadingModalVisible(isLoading);
//     }, [isLoading]);

//     const handleCheckboxChange = () => {
//         setIsChecked(!isChecked);
//     };

//     const onDelete = async () => {
//         try {
//             setIsLoading(true);
//             const response = await axiosInstance.post('/request/web', { data: 'delete', type: 'delete' }, {
//                 headers: {
//                     Authorization: `Bearer ${user.token}`,
//                 },
//             });
//             console.log(response.data);
//             navigate('/');

//         } catch (error) {
//             setSuccess(`The request could not be completed at this time. This may be due to the absence of an account associated with the currently signed-in email, a ban from the platform, or another issue. You can try again later or reach out to us at help@checkoutmyfriend.link for assistance.`);
//             console.error('error', error.message);
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const onCancel = () => {
//         navigate(-1);
//     };

//     return (
//         <>
//         {user ? (
//         <div style={{ backgroundColor: '#000', minHeight: '100vh', padding: '10px', color: 'white' }}>
//         <div style={styles.header}>
//             <button style={styles.closeButton} onClick={() => navigate(-1)}>
//                 <Entypo style={{ fontSize: '40px', color: 'white' }} />
//             </button>
//             <h1 style={styles.headerText}>Delete Account</h1>
//         </div>

//        {success ? (
//           <div style={{ padding: '20px', marginTop: '20px', marginBottom: '50px' }}>
//           <p style={{ color: '#A0A0A0', lineHeight: '25px', fontSize: '15px' }}>
//               {success}
//           </p>
//       </div>
//        ) : ( <div style={{ padding: '20px', marginTop: '15px', marginBottom: '50px' }}>
//             <p>Once you confirm, your account will be deactivated immediately, and you will lose access to all your data in the app.</p>

//             <p style={{ marginTop: '20px' }}>Although your account will be inactive, your data will remain on our servers for a 30-day retention period to meet legal, security, and dispute resolution requirements. During this time, you cannot reactivate your account. After the 30-day period, all your data will be permanently deleted, in compliance with privacy regulations.</p>

//             <h2 style={styles.warningText}>Would you still like to <span style={styles.highlightedText}>delete</span> your account?</h2>

//             <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
//                 <Checkbox
//                     checked={isChecked}
//                     onChange={handleCheckboxChange}
//                     style={{ color: '#FEE500' }}
//                 />
//                 <span style={{ lineHeight: '30px', fontSize: '12px' }}>I understand & agree to the deletion policy.</span>
//             </div>

//             <button
//                 style={{ ...styles.confirmButton, backgroundColor: isChecked ? '#FF3148' : '#2D1717' }}
//                 onClick={onDelete}
//                 disabled={isLoading || !isChecked}
//             >
//                 {isLoading ? <CircularProgress size={24} style={{ color: '#F6213A' }} /> : 'Confirm Deletion'}
//             </button>

//             <button style={styles.cancelButton} onClick={onCancel} disabled={isLoading}>
//                 Cancel Deletion
//             </button>
//         </div>)}

//         <GenericLoadingModal isVisible={isLoadingModalVisible} onClose={() => setIsLoadingModalVisible(false)} />
//        </div>) : (<LoginUserPage />)}
//        </>
//     );
// };

// const styles = {
//     header: {
//         display: 'flex',
//         alignItems: 'center',
//         marginTop: '5px',
//     },
//     closeButton: {
//         padding: '10px',
//         backgroundColor: 'transparent',
//         border: 'none',
//         cursor: 'pointer',
//     },
//     headerText: {
//         color: '#FFFFFF',
//         fontSize: '2rem',
//         fontWeight: '900',
//     },
//     warningText: {
//         color: 'white',
//         lineHeight: '30px',
//         fontSize: '1.5rem',
//         marginTop: '20px',
//         fontWeight: '900',
//     },
//     highlightedText: {
//         color: '#FF3148',
//     },
//     confirmButton: {
//         width: '97%',
//         padding: '15px',
//         marginTop: '15px',
//         borderRadius: '50px',
//         color: 'white',
//         fontSize: '16px',
//         fontWeight: '500',
//         cursor: 'pointer',
//         border: 'none',
//     },
//     cancelButton: {
//         width: '97%',
//         padding: '15px',
//         marginTop: '15px',
//         borderRadius: '50px',
//         color: '#000',
//         backgroundColor: '#FFF',
//         fontSize: '16px',
//         fontWeight: '500',
//         cursor: 'pointer',
//         border: 'none',
//     },
// };

// export default DeleteAccount;

// import { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { Checkbox, CircularProgress } from "@mui/material";
// import Entypo from "@mui/icons-material/ChevronLeft";
// import axiosInstance from "../../services/axios";
// import { UserContext } from "../../providers/UserProvider";
// import LoginUserPage from "../LoginUserPage/LoginUserPage";
// import GenericLoadingModal from "../../components/GenericModal/GenericModal";
// import { FaAngleLeft } from "react-icons/fa";

// const DeleteAccount = () => {
//   const navigate = useNavigate();
//   const [isChecked, setIsChecked] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isLoadingModalVisible, setIsLoadingModalVisible] = useState(false);
//   const [success, setSuccess] = useState("");
//   const { user } = useContext(UserContext);

//   useEffect(() => {
//     setIsLoadingModalVisible(isLoading);
//   }, [isLoading]);

//   const handleCheckboxChange = () => {
//     setIsChecked(!isChecked);
//   };

//   const onDelete = async () => {
//     try {
//       setIsLoading(true);
//       const response = await axiosInstance.post(
//         "/request/web",
//         { data: "delete", type: "delete" },
//         {
//           headers: {
//             Authorization: `Bearer ${user.token}`,
//           },
//         }
//       );
//       console.log(response.data);
//       navigate("/");
//     } catch (error) {
//       setSuccess(
//         `The request could not be completed at this time. This may be due to the absence of an account associated with the currently signed-in email, a ban from the platform, or another issue. You can try again later or reach out to us at help@checkoutmyfriend.link for assistance.`
//       );
//       console.error("error", error.message);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const onCancel = () => {
//     navigate(-1);
//   };

//   return (
//     <>
//       {user ? (
//         <div style={styles.container}>
//           <div style={styles.contentWrapper}>
//             <div style={styles.header}>
//               <button style={styles.closeButton} onClick={() => navigate(-1)}>
//                 <FaAngleLeft
//                   size={34}
//                   style={{ fontSize: "40px", color: "white" }}
//                 />
//               </button>
//               <h1 style={styles.headerText}>Delete Account</h1>
//             </div>

//             {success ? (
//               <div style={styles.messagePadding}>
//                 <p style={styles.messageText}>{success}</p>
//               </div>
//             ) : (
//               <div style={styles.contentPadding}>
//                 <p style={styles.paragraphText}>
//                   Once you confirm, your account will be deactivated
//                   immediately, and you will lose access to all your data in the
//                   app.
//                 </p>

//                 <p style={styles.paragraphText}>
//                   Although your account will be inactive, your data will remain
//                   on our servers for a 30-day retention period to meet legal,
//                   security, and dispute resolution requirements. During this
//                   time, you cannot reactivate your account. After the 30-day
//                   period, all your data will be permanently deleted, in
//                   compliance with privacy regulations.
//                 </p>

//                 <h2 style={styles.warningText}>
//                   Would you still like to{" "}
//                   <span style={styles.highlightedText}>delete</span> your
//                   account?
//                 </h2>

//                 <div style={styles.checkboxContainer}>
//                   <Checkbox
//                     checked={isChecked}
//                     onChange={handleCheckboxChange}
//                     style={{ color: "#FEE500" }}
//                   />
//                   <span style={styles.checkboxLabel}>
//                     I understand & agree to the deletion policy.
//                   </span>
//                 </div>

//                 <div style={styles.buttonContainer}>
//                   <button
//                     style={{
//                       ...styles.confirmButton,
//                       backgroundColor: isChecked ? "#FF3148" : "#2D1717",
//                     }}
//                     onClick={onDelete}
//                     disabled={isLoading || !isChecked}
//                   >
//                     {isLoading ? (
//                       <CircularProgress
//                         size={24}
//                         style={{ color: "#F6213A" }}
//                       />
//                     ) : (
//                       "Confirm Deletion"
//                     )}
//                   </button>

//                   <button
//                     style={styles.cancelButton}
//                     onClick={onCancel}
//                     disabled={isLoading}
//                   >
//                     Cancel Deletion
//                   </button>
//                 </div>
//               </div>
//             )}

//             <GenericLoadingModal
//               isVisible={isLoadingModalVisible}
//               onClose={() => setIsLoadingModalVisible(false)}
//             />
//           </div>
//         </div>
//       ) : (
//         <LoginUserPage />
//       )}
//     </>
//   );
// };

// const styles = {
//   container: {
//     backgroundColor: "#000",
//     minHeight: "100vh",
//     padding: "10px",
//     color: "white",
//     display: "flex",
//     justifyContent: "center",
//   },
//   contentWrapper: {
//     width: "100%",
//     maxWidth: "800px",
//     margin: "0 auto",
//   },
//   header: {
//     display: "flex",
//     alignItems: "center",
//     marginTop: "30px",
//   },
//   closeButton: {
//     padding: "10px",
//     backgroundColor: "transparent",
//     border: "none",
//     cursor: "pointer",
//   },
//   headerText: {
//     color: "#FFFFFF",
//     fontSize: "2rem",
//     fontWeight: 900,
//     fontFamily: "'Inter', sans-serif",
//   },
//   contentPadding: {
//     padding: "20px",
//     marginTop: "-15px",
//     marginBottom: "50px",
//   },
//   paragraphText: {
//     marginTop: "20px",
//     lineHeight: "1.6",
//     fontSize: "16px",
//     fontWeight: 500,

//     fontFamily: "'Inter', sans-serif",
//   },
//   messagePadding: {
//     padding: "10px",
//     marginTop: "10px",
//     marginBottom: "50px",
//   },
//   messageText: {
//     color: "#A0A0A0",
//     lineHeight: "25px",
//     fontSize: "15px",
//   },
//   warningText: {
//     color: "white",
//     lineHeight: "30px",
//     fontSize: "1.5rem",
//     marginTop: "40px",
//     fontWeight: 900,
//     fontFamily: "'Inter', sans-serif",
//   },
//   highlightedText: {
//     color: "#FF3148",
//   },
//   checkboxContainer: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     marginTop: "30px",
//   },
//   checkboxLabel: {
//     lineHeight: "16px",
//     fontSize: "13px",
//     fontWeight: 500,

//     fontFamily: "'Inter', sans-serif",
//   },
//   buttonContainer: {
//     display: "flex",
//     flexDirection: "column",
//     width: "100%",
//     maxWidth: "600px",
//     margin: "0 auto",
//   },
//   confirmButton: {
//     width: "100%",
//     padding: "20px",
//     marginTop: "20px",
//     borderRadius: "50px",
//     color: "white",
//     fontSize: "16px",
//     fontWeight: 600,

//     fontFamily: "'Poppins', sans-serif",
//     cursor: "pointer",
//     border: "none",
//     transition: "background-color 0.3s ease",
//   },
//   cancelButton: {
//     width: "100%",
//     padding: "20px",
//     marginTop: "15px",
//     borderRadius: "50px",
//     color: "#000",
//     backgroundColor: "#FFF",
//     fontSize: "16px",
//     fontWeight: 600,

//     fontFamily: "'Poppins', sans-serif",
//     cursor: "pointer",
//     border: "none",
//   },
// };

// export default DeleteAccount;
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox, CircularProgress } from "@mui/material";
import Entypo from "@mui/icons-material/ChevronLeft";
import axiosInstance from "../../services/axios";
import { UserContext } from "../../providers/UserProvider";
import LoginUserPage from "../LoginUserPage/LoginUserPage";
import GenericLoadingModal from "../../components/GenericModal/GenericModal";
import { FaAngleLeft } from "react-icons/fa";

const DeleteAccount = () => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingModalVisible, setIsLoadingModalVisible] = useState(false);
  const [success, setSuccess] = useState("");
  const { user } = useContext(UserContext);
  const [responsiveStyles, setResponsiveStyles] = useState({
    headerText: "2rem",
    paragraphText: "16px",
    warningText: "1.5rem",
    checkboxLabel: "13px",
    buttonText: "16px",
    contentPadding: "20px",
    headerMargin: "30px",
    paragraphSpacing: "20px",
    warningMargin: "40px",
    checkboxMargin: "30px",
    buttonPadding: "20px",
  });

  useEffect(() => {
    setIsLoadingModalVisible(isLoading);
  }, [isLoading]);

  // Add responsive sizing based on screen width
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width < 360) {
        // Extra small phones
        setResponsiveStyles({
          headerText: "1.6rem",
          paragraphText: "14px",
          warningText: "1.2rem",
          checkboxLabel: "12px",
          buttonText: "14px",
          contentPadding: "15px",
          headerMargin: "20px",
          paragraphSpacing: "15px",
          warningMargin: "30px",
          checkboxMargin: "20px",
          buttonPadding: "15px",
        });
      } else if (width < 480) {
        // Small phones
        setResponsiveStyles({
          headerText: "1.8rem",
          paragraphText: "15px",
          warningText: "1.3rem",
          checkboxLabel: "12px",
          buttonText: "15px",
          contentPadding: "18px",
          headerMargin: "25px",
          paragraphSpacing: "18px",
          warningMargin: "35px",
          checkboxMargin: "25px",
          buttonPadding: "18px",
        });
      } else if (width < 768) {
        // Tablets and large phones
        setResponsiveStyles({
          headerText: "2rem",
          paragraphText: "16px",
          warningText: "1.5rem",
          checkboxLabel: "13px",
          buttonText: "16px",
          contentPadding: "20px",
          headerMargin: "30px",
          paragraphSpacing: "20px",
          warningMargin: "40px",
          checkboxMargin: "30px",
          buttonPadding: "20px",
        });
      } else {
        // Larger screens
        setResponsiveStyles({
          headerText: "2.2rem",
          paragraphText: "17px",
          warningText: "1.7rem",
          checkboxLabel: "14px",
          buttonText: "17px",
          contentPadding: "25px",
          headerMargin: "35px",
          paragraphSpacing: "22px",
          warningMargin: "45px",
          checkboxMargin: "35px",
          buttonPadding: "22px",
        });
      }
    };

    handleResize(); // Call once initially
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const onDelete = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.post(
        "/request/web",
        { data: "delete", type: "delete" },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log(response.data);
      navigate("/");
    } catch (error) {
      setSuccess(
        `The request could not be completed at this time. This may be due to the absence of an account associated with the currently signed-in email, a ban from the platform, or another issue. You can try again later or reach out to us at help@checkoutmyfriend.link for assistance.`
      );
      console.error("error", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  // Create updated styles using responsive values
  const responsivePageStyles = {
    ...styles,
    headerText: {
      ...styles.headerText,
      fontSize: responsiveStyles.headerText,
    },
    contentPadding: {
      ...styles.contentPadding,
      padding: responsiveStyles.contentPadding,
      marginTop: "-15px",
      marginBottom: "50px",
    },
    paragraphText: {
      ...styles.paragraphText,
      marginTop: responsiveStyles.paragraphSpacing,
      fontSize: responsiveStyles.paragraphText,
    },
    warningText: {
      ...styles.warningText,
      fontSize: responsiveStyles.warningText,
      marginTop: responsiveStyles.warningMargin,
    },
    checkboxContainer: {
      ...styles.checkboxContainer,
      marginTop: responsiveStyles.checkboxMargin,
    },
    checkboxLabel: {
      ...styles.checkboxLabel,
      fontSize: responsiveStyles.checkboxLabel,
    },
    confirmButton: {
      ...styles.confirmButton,
      padding: responsiveStyles.buttonPadding,
      fontSize: responsiveStyles.buttonText,
    },
    cancelButton: {
      ...styles.cancelButton,
      padding: responsiveStyles.buttonPadding,
      fontSize: responsiveStyles.buttonText,
    },
    header: {
      ...styles.header,
      marginTop: responsiveStyles.headerMargin,
    },
  };

  return (
    <>
      {user ? (
        <div style={styles.container}>
          <div style={styles.contentWrapper}>
            <div style={responsivePageStyles.header}>
              <button style={styles.closeButton} onClick={() => navigate(-1)}>
                <FaAngleLeft
                  size={34}
                  style={{ fontSize: "40px", color: "white" }}
                />
              </button>
              <h1 style={responsivePageStyles.headerText}>Delete Account</h1>
            </div>

            {success ? (
              <div style={styles.messagePadding}>
                <p style={styles.messageText}>{success}</p>
              </div>
            ) : (
              <div style={responsivePageStyles.contentPadding}>
                <p style={responsivePageStyles.paragraphText}>
                  Once you confirm, your account will be deactivated
                  immediately, and you will lose access to all your data in the
                  app.
                </p>

                <p style={responsivePageStyles.paragraphText}>
                  Although your account will be inactive, your data will remain
                  on our servers for a 30-day retention period to meet legal,
                  security, and dispute resolution requirements. During this
                  time, you cannot reactivate your account. After the 30-day
                  period, all your data will be permanently deleted, in
                  compliance with privacy regulations.
                </p>

                <h2 style={responsivePageStyles.warningText}>
                  Would you still like to{" "}
                  <span style={styles.highlightedText}>delete</span> your
                  account?
                </h2>

                <div style={responsivePageStyles.checkboxContainer}>
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    style={{ color: "#FEE500" }}
                  />
                  <span style={responsivePageStyles.checkboxLabel}>
                    I understand & agree to the deletion policy.
                  </span>
                </div>

                <div style={styles.buttonContainer}>
                  <button
                    style={{
                      ...responsivePageStyles.confirmButton,
                      backgroundColor: isChecked ? "#FF3148" : "#2D1717",
                    }}
                    onClick={onDelete}
                    disabled={isLoading || !isChecked}
                  >
                    {isLoading ? (
                      <CircularProgress
                        size={24}
                        style={{ color: "#F6213A" }}
                      />
                    ) : (
                      "Confirm Deletion"
                    )}
                  </button>

                  <button
                    style={responsivePageStyles.cancelButton}
                    onClick={onCancel}
                    disabled={isLoading}
                  >
                    Cancel Deletion
                  </button>
                </div>
              </div>
            )}

            <GenericLoadingModal
              isVisible={isLoadingModalVisible}
              onClose={() => setIsLoadingModalVisible(false)}
            />
          </div>
        </div>
      ) : (
        <LoginUserPage />
      )}
    </>
  );
};

const styles = {
  container: {
    backgroundColor: "#000",
    minHeight: "100vh",
    padding: "10px",
    color: "white",
    display: "flex",
    justifyContent: "center",
  },
  contentWrapper: {
    width: "100%",
    maxWidth: "800px",
    margin: "0 auto",
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginTop: "30px",
  },
  closeButton: {
    padding: "10px",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
  },
  headerText: {
    color: "#FFFFFF",
    fontSize: "2rem",
    fontWeight: 900,
    fontFamily: "'Inter', sans-serif",
  },
  contentPadding: {
    padding: "20px",
    marginTop: "-15px",
    marginBottom: "50px",
  },
  paragraphText: {
    marginTop: "20px",
    lineHeight: "1.6",
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "'Inter', sans-serif",
  },
  messagePadding: {
    padding: "10px",
    marginTop: "10px",
    marginBottom: "50px",
  },
  messageText: {
    color: "#A0A0A0",
    lineHeight: "25px",
    fontSize: "15px",
  },
  warningText: {
    color: "white",
    lineHeight: "30px",
    fontSize: "1.5rem",
    marginTop: "40px",
    fontWeight: 900,
    fontFamily: "'Inter', sans-serif",
  },
  highlightedText: {
    color: "#FF3148",
  },
  checkboxContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "30px",
  },
  checkboxLabel: {
    lineHeight: "16px",
    fontSize: "13px",
    fontWeight: 500,
    fontFamily: "'Inter', sans-serif",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    maxWidth: "600px",
    margin: "0 auto",
  },
  confirmButton: {
    width: "100%",
    maxWidth: "400px",

    padding: "20px",
    marginTop: "20px",
    borderRadius: "50px",
    color: "white",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "'Poppins', sans-serif",
    cursor: "pointer",
    border: "none",
    transition: "background-color 0.3s ease",
  },
  cancelButton: {
    width: "100%",
    maxWidth: "400px",
    padding: "20px",
    marginTop: "15px",
    borderRadius: "50px",
    color: "#000",
    backgroundColor: "#FFF",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "'Poppins', sans-serif",
    cursor: "pointer",
    border: "none",
  },
};

export default DeleteAccount;
