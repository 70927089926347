// import React from 'react'
// import BackgroundImage from '../../components/BackgroundImage/BackgroundImage'
// import { signInWithGoogle } from '../../services/Firebase'
// import { useNavigate } from 'react-router-dom'
// import { backgrounds } from '../../data/backgrounds'
// import { iconsData } from '../../data/icons'
// import logo from '../../assets/logos/off-logo.png';
// import cmftext from '../../assets/cmf-text-full.png';

// const LoginUserPage = () => {

//     const handleClick = () => {
//         signInWithGoogle();
//     }

//     const navigate = useNavigate();

//     return (
//         <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '100vh', backgroundColor: 'black' }}>

//                     <img src={logo} style={{width:100, height: 100, alignSelf:'center'}} />

//                     <img src={cmftext} style={{width: '70%', alignSelf: 'center'}} />

//                     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px', margin: '5px 0px' , flexDirection: 'column'}}>
//                         <button className="exit-button" onClick={handleClick} style={{backgroundImage: `url(${iconsData['yellowBtn']})`, backgroundSize: 'cover', backgroundPosition: 'center',backgroundRepeat: 'no-repeat', backgroundColor: '#FFE500', fontSize: '1.3em', fontWeight: '700'}}>
//                            Sign In
//                         </button>

//                         <p style={{textAlign: 'center', color: 'white'}}>Please sign in to confirm your identity before proceeding with the request.</p>
//                     </div>

//                     <div style={{ display: 'flex',width: '100vw', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', }}>
//                         <p style={{color: 'white', marginRight: 20, fontWeight:  '900' }} onClick={() => navigate('/tos')}>Terms</p>
//                         <p style={{color: 'white', fontWeight: '900'}} onClick={() => navigate('/privacy')}>Privacy</p>
//                     </div>

//         </div>
//     )
// }

// export default LoginUserPage
import React from "react";
import BackgroundImage from "../../components/BackgroundImage/BackgroundImage";
import { signInWithGoogle } from "../../services/Firebase";
import { useNavigate } from "react-router-dom";
import { backgrounds } from "../../data/backgrounds";
import { iconsData } from "../../data/icons";
import logo from "../../assets/logos/off-logo.png";
import cmftext from "../../assets/cmf-text-full.png";

const LoginUserPage = () => {
  const handleClick = () => {
    signInWithGoogle();
  };

  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      <div style={styles.contentWrapper}>
        <div style={styles.logoSection}>
          <img src={logo} style={styles.logo} alt="Logo" />
          <img src={cmftext} style={styles.cmfText} alt="CMF Text" />
        </div>

        <div style={styles.signInSection}>
          <button
            className="exit-button"
            onClick={handleClick}
            style={styles.signInButton}
          >
            Sign In
          </button>
          <p style={styles.signInDescription}>
            Please sign in to confirm your identity before proceeding with the
            request.
          </p>
        </div>

        <div style={styles.footerSection}>
          <p style={styles.footerLink} onClick={() => navigate("/tos")}>
            Terms
          </p>
          <p style={styles.footerLink} onClick={() => navigate("/privacy")}>
            Privacy
          </p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "black",
    padding: "20px",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: "600px",
    height: "100%",
    minHeight: "90vh",
  },
  logoSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "40px",
    width: "100%",
  },
  logo: {
    width: "100px",
    height: "100px",
    marginBottom: "40px",
  },
  cmfText: {
    width: "70%",
    maxWidth: "400px",
    height: "auto",
  },
  signInSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    padding: "20px",
    marginTop: "20px",
  },
  signInButton: {
    backgroundImage: `url(${iconsData["yellowBtn"]})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#FFE500",
    fontSize: "1.3em",
    fontWeight: "700",
    padding: "15px 40px",
    borderRadius: "30px",
    border: "none",
    cursor: "pointer",
    width: "80%",
    maxWidth: "300px",
    marginBottom: "20px",
    transition: "transform 0.2s ease",
  },
  signInDescription: {
    textAlign: "center",
    color: "white",
    maxWidth: "450px",
    padding: "0 15px",
    lineHeight: "1.5",
    fontSize: "16px",
  },
  footerSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "auto",
    marginBottom: "20px",
    padding: "10px",
  },
  footerLink: {
    color: "white",
    fontWeight: "900",
    margin: "0 20px",
    cursor: "pointer",
    fontSize: "16px",
    padding: "5px 10px",
    transition: "opacity 0.2s ease",
  },
};

export default LoginUserPage;
