import React, { useState, useEffect } from "react";
import "./CommunityGuidelines.css";

const CommunityGuidelines = () => {
  const [activeSection, setActiveSection] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSection = (sectionId) => {
    setActiveSection(activeSection === sectionId ? null : sectionId);
  };

  const sections = [
    {
      id: "introduction",
      title: "Introduction",
      content: (
        <>
          <p>
            Welcome to "Checkout My Friend", a fun and engaging wingman tool
            designed for celebrating friendship, sparking curiosity, and
            discovering who secretly likes your friend, all while keeping things
            fun and light-hearted. Here, friendship meets matchmaking in a space
            built on trust, consent, and mutual respect.
          </p>
          <p>
            To ensure a safe and enjoyable environment for everyone, we've
            established these Community Guidelines. Please review them
            carefully, along with our Terms of Service and Privacy Policy, to
            understand the expectations and responsibilities within our
            community.
          </p>
        </>
      ),
    },
    {
      id: "how-to-use",
      title: `How Should I Use "Checkout My Friend"?`,
      content: (
        <>
          <h3>Be a Good Wingman</h3>
          <p>
            You must have explicit consent from your friend before uploading
            their photo and clearly explain how their photo will be used,
            including the potential outcomes. When you involve your friend the
            right way, it creates a better experience for everyone. Plus, it's a
            great way to show your friend you care about their privacy and
            comfort. Mutual agreement is mandatory before sharing any content.
            Consent isn't optional—it's essential.
          </p>

          <h3>Be Authentic</h3>
          <p>
            Ensure that the photo and any other information you provide comply
            with our guidelines. This includes using your real name, profile
            picture, Instagram Username, and gender during account creation.
            Authenticity enhances the experience for everyone and builds trust
            within the community.
          </p>

          <h3>Share Responsibly</h3>
          <p>
            If you're inviting your friend to participate by sharing an
            invitation link, make sure to share it only with the intended
            friend. Misuse of the invitation link or sharing it with unintended
            parties can lead to consequences, including account suspension.
          </p>

          <h3>Respect Your Friend's Rights</h3>
          <p>
            As a friend, you have the right to be fully informed about how your
            photo will be used. If, at any point, you feel uncomfortable or wish
            to withdraw your consent, you can remove the photo. It's your
            choice, and your comfort is our priority.
          </p>

          <h3>Have Fun!</h3>
          <p>
            Above all, "Checkout My Friend" is designed to be a fun and
            light-hearted space. Users are encouraged to enjoy themselves,
            explore new connections, and create lasting memories. However, this
            enjoyment should never come at the expense of another person's
            comfort or dignity. Always act with kindness, respect, and
            consideration.
          </p>
        </>
      ),
    },
    {
      id: "not-allowed",
      title: "What's Not Allowed on Checkout My Friend? ❌",
      content: (
        <>
          <h3>Sharing Content Without Consent</h3>
          <p>
            Uploading a friend's photo without their explicit consent and
            without clearly explaining the purpose and potential outcomes is
            strictly forbidden.
          </p>

          <h3>Violence, Abuse, and Harassment</h3>
          <p>
            No content that promotes or encourages violence, harassment, or
            bullying is allowed. This includes any form of child abuse, sexual
            harassment, or abusive language or symbol.
          </p>

          <h3>Harmful or Illegal Content</h3>
          <p>
            No sharing or promotion of illegal activities, including but not
            limited to, drug use, weapons, illegal substances, or any form of
            criminal behavior. Any form of spamming, trolling, or spreading
            misinformation is prohibited.
          </p>

          <h3>Personal Information and Confidentiality</h3>
          <p>
            Do not expose personal, confidential, or sensitive information about
            yourself or others. Misusing or sharing someone else's personal
            information without their consent is a serious violation.
          </p>

          <h3>No Sexual Content or Nudity</h3>
          <p>
            The sharing of explicit sexual content, including but not limited
            to, nudity or sexually suggestive material, is strictly forbidden.
          </p>

          <h3>No Spam or Unauthorized Promotion</h3>
          <p>
            Posting unsolicited promotional material, junk mail, or other forms
            of solicitation is not allowed. This includes promoting brands,
            services, or products not affiliated with "Checkout My Friend".
          </p>
        </>
      ),
    },
    {
      id: "reporting",
      title: "What Should I Do If I See a Violation? ⚠️",
      content: (
        <>
          <h3>Report the Content</h3>
          <p>
            If you see an inappropriate or offensive photo, you can report it
            directly through the web. Simply click the menu option on the top
            right of the image and select "Report." Choose the appropriate
            reason for your report, and our team will review it promptly.
          </p>

          <h3>Remove Your Content</h3>
          <p>
            As a friend, if you feel uncomfortable with your photo being shared,
            you can delete it at any time. Your consent and comfort are
            paramount, and we support your right to withdraw at any moment.
          </p>

          <h3>Contact Us</h3>
          <p>
            If someone is using your photo without your consent, or if you
            encounter a severe violation, contact us at
            help@checkoutmyfriend.link. We will take appropriate actions, which
            may include banning the user and cooperating with law enforcement if
            necessary.
          </p>
        </>
      ),
    },
    {
      id: "consequences",
      title: "Consequences of Violations",
      content: (
        <>
          <p>
            Violating these guidelines can lead to serious consequences,
            including:
          </p>
          <ul>
            <li>
              <strong>Content Removal:</strong> Any content found to be in
              violation of these guidelines will be removed promptly.
            </li>
            <li>
              <strong>Account Suspension or Ban:</strong> Depending on the
              severity of the violation, users may face account suspension or
              permanent termination. Repeat offenders or those involved in
              serious breaches will be banned from the platform.
            </li>
            <li>
              <strong>Legal Action:</strong> In cases involving criminal
              activity or severe breaches of our guidelines, "Checkout My
              Friend" reserves the right to cooperate with law enforcement and
              pursue legal action against the offending party.
            </li>
          </ul>
        </>
      ),
    },
    {
      id: "compliance",
      title: "Compliance with Local Laws",
      content: (
        <>
          <p>
            By using "Checkout My Friend", you agree to comply with all
            applicable laws and regulations governing content sharing.
            Particularly, if you are in India, you must ensure that your use of
            the app adheres to Indian law. Misuse of the app in a manner that
            violates local laws is prohibited and will be dealt with
            accordingly.
          </p>
        </>
      ),
    },
    {
      id: "revisions",
      title: "Regular Revisions",
      content: (
        <>
          <p>
            Our Community Guidelines may be updated periodically to reflect new
            policies, legal requirements, or changes to our platform. All
            updates will take effect immediately upon publication. Users will be
            notified of significant changes to these guidelines. However, it is
            the responsibility of each user to stay informed about the current
            guidelines and to review them regularly.
          </p>
          <p>
            By participating in the "Checkout My Friend" community, you agree to
            adhere to these guidelines and to contribute to a safe, and
            respectful environment for all users. Failure to comply with these
            guidelines may result in the removal of post, suspension or
            termination of your account, and in some cases, legal action. We
            appreciate your cooperation in maintaining the integrity and fun
            spirit of "Checkout My Friend".
          </p>
        </>
      ),
    },
  ];

  return (
    <div className="guidelines-container">
      {/* <header className="guidelines-header">
        <div className="logo-container">
          <div className="logo">
            <h1>Community Guidelines</h1>
          </div>
        </div>
        <p className="last-updated">Last Updated: August 28th, 2024</p>
      </header> */}

      <div className="guidelines-header">
        <h1>Community Guidelines</h1>
        <p className="header-subtitle">Last Updated: August 28th, 2024"</p>
      </div>

      <div className="guidelines-content">
        {isMobile ? (
          <div className="mobile-accordion">
            {sections.map((section) => (
              <div key={section.id} className="accordion-section">
                <button
                  className={`accordion-button ${
                    activeSection === section.id ? "active" : ""
                  }`}
                  onClick={() => toggleSection(section.id)}
                >
                  {section.title}
                  <span className="accordion-icon">
                    {activeSection === section.id ? "−" : "+"}
                  </span>
                </button>
                <div
                  className={`accordion-content ${
                    activeSection === section.id ? "active" : ""
                  }`}
                >
                  {section.content}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="desktop-layout">
            <nav className="table-of-contents">
              <h3>Contents</h3>
              <ul>
                {sections.map((section) => (
                  <li key={section.id}>
                    <a
                      href={`#${section.id}`}
                      className={activeSection === section.id ? "active" : ""}
                      onClick={(e) => {
                        e.preventDefault();
                        document
                          .getElementById(section.id)
                          .scrollIntoView({ behavior: "smooth" });
                        setActiveSection(section.id);
                      }}
                    >
                      {section.title}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
            <div className="sections-content">
              {sections.map((section) => (
                <section
                  key={section.id}
                  id={section.id}
                  className="policy-section"
                >
                  <h2>{section.title}</h2>
                  {section.content}
                </section>
              ))}
            </div>
          </div>
        )}
      </div>

      <footer className="guidelines-footer">
        <div className="footer-links">
          <a href="/terms">Terms of Service</a>
          <a href="/privacy">Privacy Policy</a>
          <a href="/support">Support</a>
        </div>
        <div className="social-icons">
          <a href="#" className="social-icon">
            <i className="instagram"></i>
          </a>
          <a href="#" className="social-icon">
            <i className="linkedin"></i>
          </a>
          <a href="#" className="social-icon">
            <i className="twitter"></i>
          </a>
        </div>
        <p>© 2024 ExtasyTechnologies. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default CommunityGuidelines;
