import React, { useState, useEffect } from 'react';

const DeleteAccountPage = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Check if screen is mobile size
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  return (
    <div style={{
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, sans-serif',
      color: '#333',
      lineHeight: '1.6'
    }}>
      {/* Logo Section */}
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '24px'
      }}>
        <img 
          src={require('../../assets/logos/cmf-logo-delete.png')}
          alt="Checkout My Friend Logo" 
          style={{
            maxWidth: '180px',
            width: '20%',
            height: 'auto',
            borderRadius: '12px',
          }}
        />
      </div>
      
      <div style={{
        background: 'linear-gradient(135deg, #ffe500 0%, #ffe500 100%)',
        borderRadius: '12px',
        padding: '22px',
        marginBottom: '24px',
        boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
      }}>
        <h1 style={{
          fontSize: '28px',
          fontWeight: '900',
          color: 'black',
          margin: '0 0 16px 0',
          textAlign: 'center'
        }}>How Do I Delete My Account?</h1>
      </div>
      
      <div style={{
        background: 'white',
        borderRadius: '12px',
        padding: '24px',
        boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
      }}>
        <p style={{
          fontSize: '16px',
          marginBottom: '24px'
        }}>To permanently delete your account, follow these steps:</p>
        
        <ol style={{
          paddingLeft: '24px',
          marginBottom: '24px'
        }}>
          <li style={{
            marginBottom: '12px'
          }}>Open the App and ensure you are signed in.</li>
          
          <li style={{
            marginBottom: '12px'
          }}>Tap the Menu Icon (☰) located at the top right of the home screen. This will take you to your Profile Page.</li>
          
          <li style={{
            marginBottom: '12px'
          }}>Scroll down and tap <strong >Manage Account</strong>.</li>
          
          <li style={{
            marginBottom: '12px'
          }}>On the Manage Account page, select <strong >Delete Account</strong>.</li>
          
          <li style={{
            marginBottom: '12px'
          }}>You will be taken to the Delete Account page, where you must check the box confirming that you understand our Privacy Policy and Deletion Policy.</li>
          
          <li style={{
            marginBottom: '12px'
          }}>Tap <strong >Confirm Deletion</strong> to permanently delete your account.</li>
        </ol>
        
        <p style={{
          marginBottom: '24px'
        }}>Once deleted, all your content—including profile details, messages, and interactions—will be deleted in accordance with our  <a  style={{ color: 'blue', fontWeight: '500', textDecoration: 'none' }}  onClick={() => {
            window.location.href = '/privacy';
          }}>Privacy Policy</a>.</p>
        
        {/* Improved responsive warning box */}
        <div style={{
          background: '#fff9e6',
          border: '1px solid #ffd166',
          borderRadius: '8px',
          padding: '16px',
          marginBottom: '24px'
        }}>
          <div style={{
            margin: '0',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center'
          }}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: isMobile ? '8px' : '0',
              marginRight: isMobile ? '0' : '8px'
            }}>
              <span style={{
                marginRight: '8px',
                fontSize: '20px'
              }}>⚠️</span>
              <strong>Important:</strong>
            </div>
            <div>
              Simply uninstalling the Checkout My Friend app from your phone will not delete your account. You must follow the above steps to ensure your account is permanently deleted.
            </div>
          </div>
        </div>
        
        <p style={{
          marginBottom: '24px'
        }}>
          If you have already uninstalled the app from your device, you can still delete your account by visiting <a  style={{ color: 'blue', fontWeight: '500', textDecoration: 'none' }}  onClick={() => {
            window.location.href = '/delete-account';
          }}>Delete Account</a>.
        </p>
        
        <div style={{
          background: '#f5f5f5',
          borderRadius: '8px',
          padding: '16px',
          marginBottom: '24px'
        }}>
          <p style={{ margin: '0' }}>
            <strong>Please note:</strong> Deleting your profile does not cancel your subscription. If you subscribed to any type of paid Checkout My Friend subscription, you'll need to take the necessary steps with the App Store or Google Play Store to ensure you aren't charged an auto-renewal subscription fee.
          </p>
        </div>
        
        <div style={{
          textAlign: 'center',
          marginTop: '32px',
          padding: '16px',
          borderTop: '1px solid #eee'
        }}>
          <p style={{ 
            fontSize: '18px',
            fontWeight: '500',
            color: '#666'
          }}>
            We'll miss you! You're welcome back anytime.<span style={{ marginLeft: '4px' }}>💛</span>
          </p>
          
         
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountPage;