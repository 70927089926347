import React, { useState, useEffect } from "react";
import "./About.css";

const About = () => {
  const [activeSection, setActiveSection] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSection = (sectionId) => {
    setActiveSection(activeSection === sectionId ? null : sectionId);
  };

  const sections = [
    {
      id: "what-is",
      title: 'What is "Checkout My Friend"?',
      content: (
        <>
          <p>
            Say hello to "Checkout My Friend", a social app that makes you the
            ultimate wingman. Share your friend’s photo on your personal social
            media accounts (like Instagram stories or Whatsapp Status) to
            receive fun proposals for them from your circle.
          </p>
          <p>
            This isn't your typical social app; it's a playful, lighthearted
            tool designed to foster laughter and connection, all while keeping
            things simple and fun.
          </p>
        </>
      ),
    },
    {
      id: "how-it-works",
      title: "How Does It Work?",
      content: (
        <>
          <p>
            Think of it like a game of wingman. Choose the hottest pic of your
            friend. Then, share the link in your personal social network (e.g.,
            Instagram Stories or WhatsApp Status), and let the magic happen.
          </p>
          <p>
            Your friends and followers can react by choosing to Date, Chill,
            Hookup, or playfully select Prank. It's all about showing interest
            and having fun—no strings attached.
          </p>
        </>
      ),
    },
    {
      id: "keeping-it-light",
      title: "Keeping It Light",
      content: (
        <>
          <p>
            Once an option is picked, they can choose from pre-written prompts;
            witty pick-up lines, flirty compliments, or fun icebreakers. These
            messages are sent to your friend, adding an element of surprise and
            amusement.
          </p>
        </>
      ),
    },
  ];

  return (
    <div className="checkout-container">
      <header className="guidelines-header">
        <div className="logo-container">
          <div className="logo">
            <h1>About CheckoutMyFriend</h1>
          </div>
        </div>
        <p className="last-updated">Last Updated: August 28th, 2024</p>
      </header>
      <div className="checkout-content">
        {isMobile ? (
          <div className="mobile-accordion">
            {sections.map((section) => (
              <div key={section.id} className="accordion-section">
                <button
                  className={`accordion-button ${
                    activeSection === section.id ? "active" : ""
                  }`}
                  onClick={() => toggleSection(section.id)}
                >
                  {section.title}
                  <span className="accordion-icon">
                    {activeSection === section.id ? "−" : "+"}
                  </span>
                </button>
                <div
                  className={`accordion-content ${
                    activeSection === section.id ? "active" : ""
                  }`}
                >
                  {section.content}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="desktop-layout">
            <nav className="table-of-contents">
              <h3>Contents</h3>
              <ul>
                {sections.map((section) => (
                  <li key={section.id}>
                    <a
                      href={`#${section.id}`}
                      className={activeSection === section.id ? "active" : ""}
                      onClick={(e) => {
                        e.preventDefault();
                        document
                          .getElementById(section.id)
                          .scrollIntoView({ behavior: "smooth" });
                        setActiveSection(section.id);
                      }}
                    >
                      {section.title}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
            <div className="sections-content">
              {sections.map((section) => (
                <section
                  key={section.id}
                  id={section.id}
                  className="policy-section"
                >
                  <h2>{section.title}</h2>
                  {section.content}
                </section>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default About;
