import React from "react";

function BackgroundImage({ imageUrl }) {
  return (
    <div
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundColor: "#000",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: -1,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      ></div>
    </div>
  );
}

export default BackgroundImage;
