import React, { useState } from "react";
import BackgroundImage from "../../components/BackgroundImage/BackgroundImage";
import CircularImage from "../../components/CircularImage/CircularImage";
import { backgrounds } from "../../data/backgrounds";
import { iconsData } from "../../data/icons";
import ConsentModal from "../../components/ConsentModal/ConsentModal";
import Modal from "../../components/Modal/Modal";

const Consent = ({ post, setPageProgress }) => {
  const [showModal, setShowModal] = useState(false);

  const backgroundImageUrl = post?.image;

  const onConsentClick = (checkoutValue) => {
    if (checkoutValue === "learn") {
      setPageProgress("learn", checkoutValue);
    } else {
      setPageProgress("exitInvite", checkoutValue);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const removePost = () => {
    onConsentClick("no");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
      }}
    >
      <BackgroundImage imageUrl={backgrounds["default"]} />

      <CircularImage backgroundImageUrl={backgroundImageUrl} />

      <div
        style={{
          alignSelf: "center",
          //   padding: "5px",
          paddingTop: "5px",
          paddingBottom: "5px",
          paddingRight: "10px",
          paddingLeft: "10px",
          cursor: "pointer",
          backgroundColor: "#FFF",
          color: "#000",
          borderRadius: "25px",
          fontWeight: 600,
          fontFamily: "'Poppins', sans-serif",
          display: "flex",
          alignItems: "center",
          fontSize: "0.8em",
          marginTop: "15px",
        }}
      >
        Just for Fun 😉
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "30px",
          marginTop: "20px",
        }}
      >
        <p
          style={{
            color: "white",
            fontSize: "1.4em",
            fontWeight: 700,
            fontFamily: "'Inter', sans-serif",
            width: "75%",
            textAlign: "center",
            marginBottom: 20,
            lineHeight: "28px",
          }}
        >
          See who secretly wants to date, marry or hookup with you. Your friend
          invites you to join now!
        </p>
        {/* 
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: -6,
            marginBottom: -20,
          }}
          onClick={() => onConsentClick("learn")}
        >
          <p
            style={{
              color: "white",
              fontWeight: 700,
              fontFamily: "'Inter', sans-serif",
              fontSize: "0.8rem",
              display: "inline-block",
              borderBottom: "2px solid white",
              marginRight: "10px",
              marginBottom: "10px",
            }}
          >
            {" "}
            Learn more{" "}
          </p>

          <p style={{ marginBottom: "10px" }}>➡️</p>
        </div> */}

        <img
          src={require("../../assets/someone-likes-you.png")}
          style={{
            width: "65%",
            maxWidth: "300px",
            marginTop: 20,
            marginBottom: 20,
          }}
        />

        <p
          style={{
            color: "white",
            fontSize: "1.3em",
            fontWeight: 700,
            fontFamily: "'Inter', sans-serif",
            width: "83%",
            textAlign: "center",
            marginBottom: 20,
            marginTop: 18,
            textWrap: "nowrap",
            textAlignLast: "center",
          }}
        >
          {post?.createdBy.firstName} is waiting for you!👀
        </p>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => onConsentClick("yes")}
        >
          <img
            src={iconsData["joinButton"]}
            style={{ width: "80%", maxWidth: "450px", cursor: "pointer" }}
          />
        </div>

        <div
          style={{
            color: "#FFFFFF",
            textDecoration: "underline", // Adds underline

            width: "70%",
            padding: "25px",
            margin: "0px 0",
            marginTop: -12,
            borderRadius: "50px",
            textAlign: "center",
            fontWeight: 600,
            fontFamily: "'Inter', sans-serif",
            fontSize: "1em",
            cursor: "pointer",
          }}
          onClick={() => setShowModal(true)}
        >
          Reject Invitation
        </div>
      </div>

      <Modal
        show={showModal}
        handleClose={handleCloseModal}
        showCloseBtn={false}
      >
        <ConsentModal
          removePost={removePost}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
    </div>
  );
};

export default Consent;
