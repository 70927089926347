import React, { useContext, useEffect, useState } from "react";
import BackgroundImage from "../../components/BackgroundImage/BackgroundImage";
import Header from "../../components/Header/Header";
import CircularImage from "../../components/CircularImage/CircularImage";
import { whatData } from "../../data/DummyValues";
import PromptList from "../../components/PromptList/PromptList";
import { backgrounds } from "../../data/backgrounds";
import { icons, iconsData } from "../../data/icons";
import axiosInstance from "../../services/axios";
import { UserContext } from "../../providers/UserProvider";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";

import { FaAngleLeft, FaArrowLeft, FaBars, FaTimes } from "react-icons/fa"; // Importing icons from react-icons
import Modal from "../../components/Modal/Modal";
import AboutModal from "../../components/AboutModal/AboutModal";
import ReportModal from "../../components/ReportModal/ReportModal";
import { BsThreeDots } from "react-icons/bs";

const What = ({ post, setPageProgress, type }) => {
  const backgroundImageUrl = post?.image;
  const { user } = useContext(UserContext);
  const [prankData, setPrankData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState("");
  const [isSingleLine, SetIsSingleLine] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Check screen size on mount and when window resizes
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768); // Common breakpoint for mobile
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);
  const onItemClick = (whatValue) => {
    setPageProgress("exit", {
      ask: false,
      value: whatValue,
      type: prankData?.type,
    });
  };

  const handleModal = (modal) => {
    if (modal !== "privacy" && modal !== "tos") {
      setShowModal(true);
      setMenuOpen(false);
      setModal(modal);
    } else {
      navigate(`/${modal}`);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModal("");
  };

  const icon = iconsData["date"];
  const prankIcon = iconsData["prankEmoji"];
  const tickIcon = iconsData["tick"];
  const crossIcon = iconsData["cross"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get("configurations/prank", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        setPrankData(response.data); // Assuming response.data contains the fetched data
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching prank data:", error);
        setLoading(false);
        navigate("/servererror");
        // Handle error state if needed
      }
    };

    if (type === "prank") {
      fetchData();
    }
  }, [type, user.token]);

  return (
    <>
      <BackgroundImage imageUrl={backgrounds[type]} />

      <div
        onClick={() => setPageProgress("checkout")}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          margin: 15,
          backgroundColor: "rgba(255, 255, 255, 0.36)", // 36% opacity of white
          borderRadius: 50,
          width: 45,
          height: 45,
          padding: 10,
          position: "absolute",
          top: 15,
          left: 10,
        }}
      >
        <FaAngleLeft size={34} style={{ color: "#ffffff" }} />
      </div>

      <div
        onClick={toggleMenu}
        style={{
          position: "absolute",
          top: 20,
          right: 10,
          padding: "10px",
          cursor: "pointer",
        }}
      >
        {menuOpen ? (
          <FaTimes size={28} color="#FFF" />
        ) : (
          <BsThreeDots size={28} color="#FFF" />
        )}
      </div>

      {menuOpen && (
        <div
          style={{
            position: "absolute",
            top: 60,
            right: 10,
            backgroundColor: "#000",
            borderRadius: "20px",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            zIndex: 120,
          }}
        >
          <div
            style={{
              width: "100%",
              borderBottom: "1px solid grey",
              paddingBottom: "10px",
              marginBottom: "5px",
              textAlign: "center",
            }}
            onClick={() => handleModal("about")}
          >
            <span
              style={{
                color: "#FFF",
                fontWeight: "bold",
                cursor: "pointer",
                fontSize: "1.5em",
              }}
            >
              About
            </span>
          </div>
          <div
            style={{
              width: "100%",
              borderBottom: "1px solid grey",
              paddingBottom: "10px",
              marginBottom: "5px",
              textAlign: "center",
            }}
            onClick={() => handleModal("privacy")}
          >
            <span
              style={{
                color: "#FFF",
                fontWeight: "bold",
                cursor: "pointer",
                fontSize: "1.5em",
              }}
            >
              Privacy
            </span>
          </div>

          <div
            style={{
              width: "100%",
              borderBottom: "1px solid grey",
              paddingBottom: "10px",
              marginBottom: "5px",
              textAlign: "center",
            }}
            onClick={() => handleModal("tos")}
          >
            <span
              style={{
                color: "#FFF",
                fontWeight: "bold",
                cursor: "pointer",
                fontSize: "1.5em",
              }}
            >
              Terms
            </span>
          </div>
          <div
            style={{ width: "100%", textAlign: "center" }}
            onClick={() => handleModal("report")}
          >
            <span
              style={{
                color: "red",
                fontWeight: "bold",
                cursor: "pointer",
                fontSize: "1.5em",
              }}
            >
              Report
            </span>
          </div>
        </div>
      )}
      <CircularImage
        backgroundImageUrl={backgroundImageUrl}
        icon={iconsData[type]}
      />
      {/* <div style={styles.backButton} onClick={handleCloseModal}>
        <FaAngleLeft size={35} style={{ color: "#ffffff" }} />
      </div> */}

      {type === "prank" ? (
        <>
          {/* <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              fontSize: "2.3em",
              margin: "15px auto", // Centers the div horizontally
              color: "#fff",
              fontWeight: 900,
              fontFamily: "Inter, sans-serif",
              width: "100%", // Ensures it doesn’t overflow
              maxWidth: "600px", // Adjusts for large screens
              lineHeight: "50px",
              marginBottom: "1.5rem",
            }}
          >
            <label>😄Prank them🤫{isSingleLine ? "🤫" : ""}</label>
            <label>with a Fake</label>
            <label>Proposal🤍</label>
          </div> */}
          {/* Back Button */}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              fontSize: "2.3em",
              margin: "15px auto",
              color: "#fff",
              fontWeight: 900,
              fontFamily: "Inter, sans-serif",
              width: "100%",
              maxWidth: "600px",
              lineHeight: "50px",
              marginBottom: "1.7rem",
              marginTop: "1.8rem",
            }}
          >
            <div style={{ width: "100%" }}>😄Prank them🤫</div>

            {isMobile ? (
              // Mobile layout - 3 lines
              <>
                <div style={{ width: "100%" }}>with a Fake</div>
                <div style={{ width: "100%" }}>Proposal🤍</div>
              </>
            ) : (
              // Desktop layout - 2 lines
              <div style={{ width: "100%" }}>with a Fake Proposal🤍</div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
              width: "100%",
              padding: "20px",
            }}
          >
            {[
              { text: "They think it's real", icon: "cross", emoji: "😍" },
              { text: "But it's a Prank", icon: "tick", emoji: "🤯" },
            ].map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  maxWidth: "700px",
                  padding: "10px 10px",
                  backgroundColor: "#fff",
                  borderRadius: "18px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                  transform: index % 2 === 0 ? "rotate(-2deg)" : "rotate(1deg)",
                }}
              >
                {/* Left Side: Emoji + Text */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "1.7em",
                      fontFamily: "Inter, sans-serif",
                      fontWeight: 600,
                    }}
                  >
                    {item.emoji}
                  </span>
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: "1.2em",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.text}
                  </span>
                </div>

                {/* Right Side: Tick or Cross */}
                <img
                  src={iconsData[item.icon]}
                  alt="Check Icon"
                  style={{ width: "24px", marginRight: "8px" }}
                />
              </div>
            ))}

            {/* Tap to Prank Button */}
            <img
              src={require("../../assets/tapprank.png")}
              onClick={() => onItemClick(prankData)}
              style={{
                width: "95%",
                maxWidth: "340px",
                marginTop: "50px",
                cursor: "pointer",
              }}
              alt="Tap Prank"
            />
          </div>
        </>
      ) : (
        <>
          <Header page={"what"} />
          <PromptList data={whatData} onItemClick={onItemClick} type={type} />
        </>
      )}

      <Modal show={showModal} handleClose={handleCloseModal}>
        {modal === "about" ? <AboutModal /> : null}
        {modal === "report" ? <ReportModal /> : null}
      </Modal>
    </>
  );
};
const styles = {
  backButton: {
    position: "absolute",
    top: "30px",
    left: "20px",
    // width: "55px",
    // height: "55px",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    zIndex: 10,
  },
};
export default What;
