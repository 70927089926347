import React, { useContext, useEffect, useState } from "react";

const ConsentModal = ({ handleCloseModal, removePost }) => {
  const [fontSize, setFontSize] = useState("1.2em");

  // Add a useEffect hook to handle responsive font size
  useEffect(() => {
    const handleResize = () => {
      // Adjust font size based on screen width
      if (window.innerWidth < 480) {
        setFontSize("1em"); // Smaller font size for phones
      } else {
        setFontSize("1.2em"); // Original size for larger screens
      }
    };

    // Initial call
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        // borderRadius: "40px",
      }}
    >
      <h2
        style={{
          color: "#FF0834",
          fontWeight: 800,
          fontFamily: "'Inter', sans-serif",
          fontSize: "2.5rem",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        Warning!
      </h2>

      <p
        style={{
          color: "#FFFFFF",
          textAlign: "center",
          lineHeight: "2",
          fontWeight: 600,
          fontFamily: "'Inter', sans-serif",
          fontSize: fontSize,
          marginBottom: "10px",
          width: "60vw",
          maxWidth: "600px",
        }}
      >
        Rejecting will completely delete this post and photo for everyone. You
        won’t get any proposals and be part of this game. Do you still want to{" "}
        <span style={{ color: "red" }}>delete</span> this?
      </p>

      <div
        onClick={handleCloseModal}
        style={{
          width: "90%",
          maxWidth: "300px",
          padding: "20px",
          backgroundColor: "#fff",
          color: "#000",
          borderRadius: "50px",
          textAlign: "center",
          fontSize: "1.2em",
          fontWeight: 600,
          fontFamily: "'Inter', sans-serif",
          marginBottom: "23px",
          marginTop: "18px",
          cursor: "pointer",
        }}
      >
        No, I want to join
      </div>

      <div
        onClick={removePost}
        style={{
          width: "90%",
          maxWidth: "300px",
          padding: "20px",
          backgroundColor: "#340000",
          color: "#FF2734",
          borderRadius: "50px",
          textAlign: "center",
          fontSize: "1.2em",
          fontWeight: 600,
          fontFamily: "'Inter', sans-serif",
          border: "1px solid #FF0000",
          marginBottom: "22px",
          cursor: "pointer",
        }}
      >
        Yes, delete it
      </div>
    </div>
  );
};

export default ConsentModal;
