// src/services/axios.js

import axios from 'axios';

// Create an Axios instance with default settings
const axiosInstance = axios.create({
    baseURL: 'https://api.checkoutmyfriend.link/v1/api/',
    // baseURL: 'http://localhost:8080/v1/api/',
    headers: {
        'Content-Type': 'application/json',
    },
});


// Add a response interceptor
// axiosInstance.interceptors.response.use(
//     (response) => response, // Return the response if it's successful
//     (error) => {
//       if (error.response && error.response.status === 500) {
//         // Redirect to the /servererror page if a 500 error is encountered
//         window.location.href = "/servererror";
//       }
//       return Promise.reject(error); // Reject other errors as usual
//     }
//   );

export default axiosInstance;
