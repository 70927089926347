import React, { useContext, useState } from "react";
import BackgroundImage from "../../components/BackgroundImage/BackgroundImage";
import Header from "../../components/Header/Header";
import ImageContainer from "../../components/ImageContainer/ImageContainer";
import { signInWithGoogle } from "../../services/Firebase";
import { useNavigate } from "react-router-dom";
import { backgrounds } from "../../data/backgrounds";
import { iconsData } from "../../data/icons";

const CheckoutDummyInvite = ({
  backgroundImageUrl,
  native = true,
  subject,
}) => {
  const navigate = useNavigate();
  const width = window.innerWidth;
  const height = window.innerHeight;

  const handleClick = () => {
    if (native) {
      signInWithGoogle();
    }
  };

  const handleTOSClick = () => {
    // tbd
    navigate("/tos");
  };

  const getPronoun = () => {
    console.log("subject", subject);
    if (subject?.gender) {
      switch (subject.gender.toLowerCase()) {
        case "male":
          return "he";
        case "female":
          return "she";
        case "non-binary":
          return "this";
        default:
          return "this"; // A neutral pronoun for unknown or unspecified gender
      }
    } else {
      return "this"; // A neutral pronoun for cases where gender is not present
    }
  };

  console.log("backgroundImageUrl", backgroundImageUrl);
  return (
    <>
      <BackgroundImage imageUrl={backgrounds["checkout2"]} />

      {!native ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "space-evenly",
            marginBottom: "20px",
          }}
        >
          <div style={{ marginBottom: 10 }}>
            <p
              style={{
                fontWeight: "bolder",
                fontSize: "3em",
                color: "#FFE500",
                marginBottom: "10px",
                fontWeight: "900",
                textAlign: "center",
              }}
            >
              Only for 18+
            </p>
            <p
              style={{
                fontWeight: "bolder",
                fontSize: "1.3em",
                color: "#FFF",
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              See before it's <span style={{ color: "#474747" }}>deleted</span>{" "}
              🔥
            </p>
          </div>

          <ImageContainer
            imageUrl={backgroundImageUrl}
            width="60%"
            height="40vh"
          >
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                padding: "10px",
                color: "#FFF",
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                borderRadius: "0 20px",
                paddingLeft: "20px",
                width: "100vw",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <img
                  src={iconsData["blurredName"]}
                  height={20}
                  width={100}
                  style={{ marginBottom: "10px" }}
                />
                <img
                  src={iconsData["blurredTime"]}
                  height={20}
                  width={150}
                  style={{ marginBottom: "10px" }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#FFE500",
                    width: "10px",
                    height: "10px",
                    marginRight: "10px",
                    borderRadius: "50%",
                  }}
                ></div>
                <h3
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.2em",
                    color: "#FFF",
                    margin: "0",
                  }}
                >{`See steps below`}</h3>
              </div>
            </div>
            <img
              src={iconsData["noEyes"]}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                width: "100px",
                height: "100px",
                transform: "translate(-50%, -50%)",
              }}
            />
          </ImageContainer>

          <div
            style={{
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontWeight: "bolder",
                  fontSize: "1.5em",
                  color: "#FFF",
                  marginBottom: "10px",
                  marginRight: "15px",
                }}
              >
                👉 Tap{" "}
              </p>
              <img
                src={iconsData["menuIcon"]}
                width={50}
                height={50}
                style={{ marginRight: "15px" }}
              />
              <p
                style={{
                  fontWeight: "bolder",
                  fontSize: "1.5em",
                  color: "#FFF",
                  marginBottom: "10px",
                }}
              >
                on top right
              </p>
            </div>

            <p
              style={{
                fontWeight: "bolder",
                fontSize: "1.5em",
                color: "#FFF",
                marginTop: "20px",
              }}
            >
              👉 Open in <span style={{ color: "#FFE500" }}>Chrome</span>
            </p>
          </div>

          {/* Animated Pointy Finger */}
          <div
            style={{
              position: "absolute",
              top: "30px",
              right: "10px",
              width: "80px",
              height: "80px",
              background: `url(${iconsData["pointyFinger"]}) no-repeat center center`,
              backgroundSize: "contain",
              animation: "bounce 1s infinite",
            }}
          />

          <style>
            {`
                    @keyframes bounce {
                        0%, 100% {
                            transform: translateY(0);
                        }
                        50% {
                            transform: translateY(-10px);
                        }
                    }
                `}
          </style>
        </div>
      ) : (
        // <div
        //   style={{
        //     display: "flex",
        //     flexDirection: "column",
        //     justifyContent: "space-evenly",
        //     height: "100vh",
        //   }}
        // >
        //   <div
        //     style={{
        //       height: height * 0.23,
        //       width: "75%",
        //       alignSelf: "center",
        //       marginBottom: height * 0.04,
        //       marginLeft: width * 0.05,
        //       marginRight: width * 0.05,
        //       marginTop: "4rem",
        //       position: "relative",
        //       alignSelf: "center",
        //     }}
        //   >
        //     <img
        //       src={subject.image}
        //       alt="Initiator"
        //       style={{
        //         width: "12rem",
        //         height: "14rem",
        //         borderRadius: width * 0.11,
        //         borderColor: "white",
        //         borderWidth: 5,
        //         borderStyle: "solid",
        //         position: "absolute",
        //         left: "3%",
        //         top: "0%",
        //         transform: "rotate(350deg)",
        //         zIndex: 1,
        //       }}
        //     />

        //     <img
        //       src={backgroundImageUrl}
        //       alt="Blurred Response"
        //       style={{
        //         width: "12rem",
        //         height: "15rem",
        //         borderRadius: width * 0.1,
        //         borderColor: "white",
        //         borderWidth: 5,
        //         borderStyle: "solid",
        //         position: "absolute",
        //         right: "3%",
        //         top: 10,
        //         zIndex: 2,
        //         transform: "rotate(7deg)",
        //       }}
        //     />

        //     <img
        //       src={require("../../assets/q-vector.png")}
        //       alt="Insta Icon"
        //       style={{ position: "absolute", top: 80, right: 50, zIndex: 3 }}
        //     />

        //     <img
        //       src={require("../../assets/insta-story-top-vector.png")}
        //       alt="Top Vector"
        //       style={{ position: "absolute", top: -30, right: -30 }}
        //     />

        //     <img
        //       src={require("../../assets/insta-story-bottom-vector.png")}
        //       alt="Bottom Vector"
        //       style={{ position: "absolute", bottom: -30, left: -30 }}
        //     />
        //   </div>

        //   <p
        //     style={{
        //       color: "white",
        //       fontSize: "3em",
        //       fontWeight: "bolder",
        //       textAlign: "center",
        //       alignSelf: "center",
        //       width: "80%",
        //     }}
        //   >
        //     {" "}
        //     <span style={{ color: "#FFE500" }}>{subject.name}</span> invites you
        //     to see who likes you 🔥
        //   </p>

        //   <div
        //     style={{
        //       display: "flex",
        //       justifyContent: "center",
        //       alignItems: "center",
        //       padding: "5px",
        //       margin: "2px 0px",
        //     }}
        //   >
        //     <button
        //       className="exit-button"
        //       onClick={handleClick}
        //       style={{
        //         backgroundColor: "#FFE500",
        //         fontSize: "1.3em",
        //         fontWeight: "700",
        //         padding: "10px 20px",
        //         borderRadius: "25px",
        //         border: "none",
        //         width: "100%",
        //         maxWidth: "280px",
        //       }}
        //     >
        //       {native ? `Continue 👻` : null}
        //     </button>
        //   </div>

        //   <img
        //     src={require("../../assets/up-arrow.png")}
        //     style={{
        //       width: "50vw",
        //       borderRadius: 20,
        //       alignSelf: "center",
        //       marginTop: -20,
        //     }}
        //   />

        //   <p
        //     style={{
        //       color: "white",
        //       textAlign: "center",
        //       width: "80vw",
        //       alignSelf: "center",
        //     }}
        //   >
        //     Note: This is only for light fun & entertainment. By Continuing, you
        //     accept our{" "}
        //     <a
        //       href="/tos"
        //       style={{ color: "#FFE500", textDecoration: "underline" }}
        //     >
        //       Terms
        //     </a>{" "}
        //     &{" "}
        //     <a
        //       href="/privacy"
        //       style={{ color: "#FFE500", textDecoration: "underline" }}
        //     >
        //       Privacy
        //     </a>
        //     .
        //   </p>
        // </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            height: "100vh",
          }}
        >
          <div
            style={{
              height: height * 0.23,
              width: "75%",
              alignSelf: "center",
              marginBottom: height * 0.07,
              marginLeft: width * 0.05,
              marginRight: width * 0.1,
              marginTop: -height * 0.01,
              position: "relative",
              alignSelf: "center",
            }}
          >
            <img
              src={subject.image}
              alt="Initiator"
              style={{
                width: "11rem",
                height: height * 0.3,
                borderRadius: width * 0.1,
                borderColor: "white",
                borderWidth: 5,
                borderStyle: "solid",
                position: "absolute",
                left: "3%",
                top: "0%",
                transform: "rotate(350deg)",
                zIndex: 1,
              }}
            />

            <img
              src={backgroundImageUrl}
              alt="Blurred Response"
              style={{
                width: "11rem",
                height: height * 0.3,
                borderRadius: width * 0.1,
                borderColor: "white",
                borderWidth: 5,
                borderStyle: "solid",
                position: "absolute",
                right: "3%",
                top: 1,
                zIndex: 2,
                transform: "rotate(7deg)",
              }}
            />

            <img
              src={require("../../assets/q-vector.png")}
              alt="Insta Icon"
              style={{ position: "absolute", top: 76, right: 60, zIndex: 3 }}
            />

            <img
              src={require("../../assets/insta-story-top-vector.png")}
              alt="Top Vector"
              style={{ position: "absolute", top: -15, right: -43 }}
            />

            <img
              src={require("../../assets/insta-story-bottom-vector.png")}
              alt="Bottom Vector"
              style={{ position: "absolute", bottom: -90, left: 0 }}
            />
          </div>

          <p
            style={{
              color: "white",
              fontSize: width < 300 ? "1.8em" : "2em",
              fontWeight: 700,
              fontFamily: "'Inter', sans-serif",
              textAlign: "center",
              alignSelf: "center",
              //   marginTop: "-1rem",
              lineHeight: height * 0.002,
              marginTop: height * 0.01,
              width: "80%",
            }}
          >
            {" "}
            <span style={{ color: "#FFE500" }}>{subject.name}</span> invites you
            to see who likes you 🔥
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              //   padding: "0px",
              //   margin: "1px 0px",
            }}
          >
            <button
              className="exit-button"
              onClick={handleClick}
              style={{
                backgroundColor: "#FFE500",
                fontSize: "1.3em",
                fontWeight: "700",
                padding: "1em 1rem",
                borderRadius: "33px",
                border: "none",
                width: "100%",
                maxWidth: "300px",
              }}
            >
              {native ? `Continue 👻` : null}
            </button>
          </div>

          <img
            src={require("../../assets/up-arrow.png")}
            style={{
              width: "50vw",
              borderRadius: 20,
              alignSelf: "center",
              marginTop: -25,
            }}
          />

          <p
            style={{
              color: "white",
              textAlign: "center",
              width: "80vw",
              alignSelf: "center",
              fontSize: width < 400 ? "0.6em" : "0.8em",
              marginTop: "-20px",
            }}
          >
            Note: This is only for light fun & entertainment. By Continuing, you
            accept our{" "}
            <a
              href="/tos"
              style={{ color: "#FFE500", textDecoration: "underline" }}
            >
              Terms
            </a>{" "}
            &{" "}
            <a
              href="/privacy"
              style={{ color: "#FFE500", textDecoration: "underline" }}
            >
              Privacy
            </a>
            .
          </p>
        </div>
      )}
    </>
  );
};

export default CheckoutDummyInvite;
