import React, { useState } from "react";
import BackgroundImage from "../../components/BackgroundImage/BackgroundImage";
import Header from "../../components/Header/Header";
import ImageContainer from "../../components/ImageContainer/ImageContainer";
import Button from "../../components/Buttons/Button"; // Import the Button component
import { backgrounds } from "../../data/backgrounds";
import Modal from "../../components/Modal/Modal";
import AboutModal from "../../components/AboutModal/AboutModal";
import ReportModal from "../../components/ReportModal/ReportModal";

const Invite = ({ post, setPageProgress }) => {
  const backgroundImageUrl = post?.image;
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);

  const onCheckoutClick = (checkoutValue) => {
    setPageProgress("consent", checkoutValue);
  };

  const handleModal = (modal) => {
    setShowModal(true);
    setMenuOpen(false);
    setModal(modal);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModal("");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        height: "100vh",
      }}
    >
      <BackgroundImage imageUrl={backgrounds["checkout"]} />

      <div
        style={{
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
          position: "relative",
          //   margin: 20,
          marginTop: -6,
          marginLeft: 20,
          marginRight: 20,
          marginBottom: -15,
          height: "70vh",
          width: "90vw",

          //   background:
          //     "linear-gradient(to bottom, rgb(255, 37, 103) 0%, rgb(252, 112, 0) 81%)",
          alignItems: "center",
          borderRadius: 20,
        }}
      >
        <ImageContainer width="95%" imageUrl={backgroundImageUrl}>
          <div
            style={{
              position: "absolute",
              top: 15,
              left: 15,
              //   padding: "8px",
              paddingRight: "15px",
              paddingLeft: "15px",
              paddingTop: "5px",
              paddingBottom: "5px",
              cursor: "pointer",
              backgroundColor: "#FFF",
              color: "#000",
              borderRadius: "20px",
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
              fontSize: "0.9em",
            }}
            onClick={() => handleModal("about")}
          >
            Just for Fun 😉
          </div>

          {/* captions */}
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: "10px",
              color: "#FFF",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              borderRadius: "10px 10px",
              padding: "20px",
              //   fontWeight: "bolder",
              width: "100%", // Ensure text takes 100% width
            }}
          >
            <h3
              style={{
                fontSize: "1.5rem",
                fontStyle: "italic",
                fontWeight: 900,
                fontFamily: "Inter, sans-serif",
              }}
            >
              Ta-da! {/* Hero emoji with sunglass */}
            </h3>
            <h3
              style={{
                fontStyle: "italic",
                fontWeight: 700,
                fontFamily: "Inter, sans-serif",
                fontSize: "1.2rem",
                marginBottom: "5px",
              }}
            >
              <span style={{ color: "#FFB300" }}>
                {post?.createdBy.firstName}
              </span>{" "}
              posted this pic to
            </h3>
            <h3
              style={{
                fontStyle: "italic",
                fontWeight: 700,
                fontFamily: "Inter, sans-serif",
                fontSize: "1.2rem",

                marginBottom: "5px",
              }}
            >
              get fun proposals for you 💌
            </h3>
          </div>
        </ImageContainer>
      </div>

      {/* Button component */}
      <div
        style={{
          backgroundColor: "white",
          color: "black",
          width: "80%",
          maxWidth: "350px",
          margin: "0px auto",
          borderRadius: "50px",
          padding: "15px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          fontWeight: 700, // Set text to be bolder
          fontFamily: "Inter, sans-serif",
          fontSize: "1.2em",
          textAlign: "center", // Center-align the text
          cursor: "pointer", // Show pointer cursor on hover
        }}
        onClick={() => onCheckoutClick("consent")}
      >
        See Next
      </div>

      <Modal show={showModal} handleClose={handleCloseModal}>
        {modal === "about" ? <AboutModal /> : null}
        {modal === "report" ? <ReportModal /> : null}
      </Modal>
    </div>
  );
};

export default Invite;
