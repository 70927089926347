import React, { useContext, useEffect, useState } from 'react'
import Gender from '../Gender/Gender';
import BackgroundImage from '../../components/BackgroundImage/BackgroundImage';
import CircularImage from '../../components/CircularImage/CircularImage';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../providers/UserProvider';
import axiosInstance from '../../services/axios';
import { backgrounds } from '../../data/backgrounds';
import { iconsData } from '../../data/icons';

const ExitInvite = ({ post, exitValue }) => {
    const [exit, setExit] = useState(false);
    const {id} = useParams();
    const {user} = useContext(UserContext);
    const navigate = useNavigate();

    const backgroundImageUrl = post?.image;





    useEffect(() => {
        if (exitValue === 'no') {
            axiosInstance.post(`/posts/invite/deny/${id}`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user?.token}`
                }
            })
            .then(response => {
                console.log('Denied invitation successfully:', response.data);
                // Additional logic after denying invitation
            })
            .catch(error => {
                console.error('Error denying invitation:', error);
                navigate('/servererror');
            });
        } else {
            setExit(true);
            axiosInstance.post(`/posts/invite/accept/${id}`, { }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user?.token}`
                }
            })
            .then(response => {
                console.log('Accepted invitation successfully:', response.data);
                // Additional logic after accepting invitation
            })
            .catch(error => {
                console.error('Error accepting invitation:', error);
                navigate('/servererror');
            });
        }
    }, [exitValue, id]);

    



    return (
        <>
            <BackgroundImage imageUrl={backgrounds['default']} />
           
            {(exitValue === 'no' || exit === true) ? (
                    <div style={{display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems:'center',  height: '100vh', textAlign: 'center'}}>

                        {exitValue === 'no' ? (
                            <>
                              
                              <p  style={{ marginTop: '15px', textAlign: 'center' , fontWeight: 'bolder', fontSize: '2.5em', color: 'white'}}>
                                    The post is deleted!
                                </p>

                                <p  style={{ marginTop: '15px', textAlign: 'center' , fontWeight: '700', fontSize: '1.3em', color: 'white'}}>
                                    But you may be interested in 👇
                                </p>


                               


                                 <img src={require('../../assets/see-who-likes-your-friend.png')} style={{width: '90%', marginTop: 40}}/>

                            </>
                        ) : null}

                        {exit ? (
                            <>
                                <CircularImage backgroundImageUrl={backgroundImageUrl} />

                              

                                <p className="exit-message" style={{marginTop: 20, marginBottom: 20, fontSize: '1.5em'}}>
                                {post?.createdBy.firstName} is waiting for you! 👀
                                </p>

                                <img src={iconsData['joinButton']} style={{width: '90%'}}/>
                            </>
                        ) : null}

                       
                         <img src={iconsData['arrowGolden']} style={{width: '50%', height: '70px', marginTop: '20px', height: 60}}/>



                         <img src={iconsData['cmfWhite']} style={{width: '70%', marginTop: '60px'}}/>

                         

                    </div>
            ) : null}

         

        </>
    )
}

export default ExitInvite