import React from "react";
import { FaEnvelope, FaLifeRing, FaGavel } from "react-icons/fa"; // Icons for better UI
import "./Contact.css";

const ContactUs = () => {
  return (
    <div className="contact-container">
      <h1 className="contact-title">Get in Touch</h1>

      <div className="contact-cards">
        <div className="contact-card">
          <div className="icon-wrapper">
            <FaEnvelope className="contact-icon" />
          </div>
          <h2 className="card-title">General Inquiry</h2>
          <p className="card-description">Have a question? Contact us at:</p>
          <a
            href="mailto:contact@checkoutmyfriend.link"
            className="contact-link"
          >
            contact@checkoutmyfriend.link
          </a>
        </div>

        <div className="contact-card">
          <div className="icon-wrapper">
            <FaLifeRing className="contact-icon" />
          </div>
          <h2 className="card-title">Help & Support</h2>
          <p className="card-description">
            Need assistance? Reach our support team:
          </p>
          <a href="mailto:help@checkoutmyfriend.link" className="contact-link">
            help@checkoutmyfriend.link
          </a>
        </div>

        <div className="contact-card">
          <div className="icon-wrapper">
            <FaGavel className="contact-icon" />
          </div>
          <h2 className="card-title">Legal Requests</h2>
          <p className="card-description">For legal matters, contact us at:</p>
          <a href="mailto:legal@checkoutmyfriend.link" className="contact-link">
            legal@checkoutmyfriend.link
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
