import React, { useState } from "react";
import BackgroundImage from "../../components/BackgroundImage/BackgroundImage";
import ImageContainer from "../../components/ImageContainer/ImageContainer";

import { FaBars, FaTimes } from "react-icons/fa"; // Importing icons from react-icons
import Modal from "../../components/Modal/Modal";
import AboutModal from "../../components/AboutModal/AboutModal";
import ReportModal from "../../components/ReportModal/ReportModal";
import { icons } from "../../data/icons";
import { backgrounds } from "../../data/backgrounds";
import { useNavigate } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";

const Checkout = ({ post, setPageProgress }) => {
  const backgroundImageUrl = post?.image;
  const [menuOpen, setMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState("");
  const navigate = useNavigate();

  const onCheckoutClick = (checkoutValue) => {
    setPageProgress("what", checkoutValue.toLowerCase());
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleModal = (modal) => {
    if (modal !== "privacy" && modal !== "tos") {
      setShowModal(true);
      setMenuOpen(false);
      setModal(modal);
    } else {
      navigate(`/${modal}`);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModal("");
  };

  const subjectPhotoBackground = backgrounds["date"];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        flex: 1,
        height: "100vh",
      }}
    >
      <BackgroundImage imageUrl={backgrounds["checkout2"]} />

      <div
        style={{
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
          position: "relative",
          margin: 20,
          height: "70vh",
          width: "90vw",
          alignItems: "center",
        }}
      >
        <ImageContainer width="95%" imageUrl={backgroundImageUrl}>
          {/* Menu Icon */}
          <div
            onClick={toggleMenu}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              padding: "10px",
              cursor: "pointer",
            }}
          >
            {menuOpen ? (
              <FaTimes size={24} color="#FFF" />
            ) : (
              <BsThreeDots size={24} color="#FFF" />
            )}
          </div>

          {/* <div
            style={{
              position: "absolute",
              top: 10,
              left: 10,
              padding: "10px",
              cursor: "pointer",
              backgroundColor: "#FFF",
              color: "#000",
              borderRadius: "20px",
              fontWeight: "700",
              display: "flex",
              alignItems: "center",
              fontSize: "0.8em",
            }}
            onClick={() => handleModal("about")}
          >
            Just for Fun 😉
          </div> */}
          {/* Dropdown Menu */}
          {menuOpen && (
            <div
              style={{
                position: "absolute",
                top: 50,
                right: 10,
                backgroundColor: "#000",
                borderRadius: "20px",
                padding: "30px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                zIndex: 10,
              }}
            >
              <div
                style={{
                  width: "100%",
                  borderBottom: "1px solid grey",
                  paddingBottom: "10px",
                  marginBottom: "5px",
                  textAlign: "center",
                }}
                onClick={() => handleModal("about")}
              >
                <span
                  style={{
                    color: "#FFF",
                    fontWeight: "bold",
                    cursor: "pointer",
                    fontSize: "1.5em",
                  }}
                >
                  About
                </span>
              </div>
              <div
                style={{
                  width: "100%",
                  borderBottom: "1px solid grey",
                  paddingBottom: "10px",
                  marginBottom: "5px",
                  textAlign: "center",
                }}
                onClick={() => handleModal("privacy")}
              >
                <span
                  style={{
                    color: "#FFF",
                    fontWeight: "bold",
                    cursor: "pointer",
                    fontSize: "1.5em",
                  }}
                >
                  Privacy
                </span>
              </div>

              <div
                style={{
                  width: "100%",
                  borderBottom: "1px solid grey",
                  paddingBottom: "10px",
                  marginBottom: "5px",
                  textAlign: "center",
                }}
                onClick={() => handleModal("tos")}
              >
                <span
                  style={{
                    color: "#FFF",
                    fontWeight: "bold",
                    cursor: "pointer",
                    fontSize: "1.5em",
                  }}
                >
                  Terms
                </span>
              </div>
              <div
                style={{ width: "100%", textAlign: "center" }}
                onClick={() => handleModal("report")}
              >
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    cursor: "pointer",
                    fontSize: "1.5em",
                  }}
                >
                  Report
                </span>
              </div>
            </div>
          )}

          <div
            style={{
              fontFamily: "Inter, sans-serif",
              fontWeight: 600,
              paddingLeft: "15px",
              paddingRight: "15px",
              paddingTop: "6px",
              paddingBottom: "6px",
              cursor: "pointer",
              backgroundColor: "#FFF",
              color: "#000",
              borderRadius: "20px",

              display: "flex",
              alignItems: "center",
              fontSize: "1rem",
              position: "absolute",
              bottom: 100,
              left: 20,
            }}
          >
            {post?.tag || "Friend"}
          </div>
          <div style={styles.infoBox}>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                color: "#FFF",
                // backgroundColor: "rgba(0, 0, 0, 0.1)",
                borderRadius: "0 20px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start", // Align to the start
              }}
            >
              <h3 style={styles.nameText}>
                {`${post?.createdBy?.firstName}'s`} Friend
                {/* Modhumita;s Friend */}
              </h3>

              <h3 style={styles.voteText}>🤫 Vote to see the result 👇</h3>
            </div>
          </div>
        </ImageContainer>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          marginTop: 5,
          marginRight: 6,
          marginLeft: 6,
        }}
      >
        {icons.map((icon, index) => (
          <div
            key={index}
            onClick={() => onCheckoutClick(icon.title)}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              margin: "8px",
              cursor: "pointer",
            }}
          >
            <img
              src={icon.image}
              alt={icon.title}
              style={{ width: "3.5em", height: "3.5em" }}
            />
            <span
              style={{
                color: "#FFF",
                marginTop: "5px",
                fontWeight: 700,
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              {icon.title == "Marry" ? "Chill" : icon.title}
            </span>
          </div>
        ))}
      </div>

      <Modal show={showModal} handleClose={handleCloseModal}>
        {modal === "about" ? <AboutModal /> : null}
        {modal === "report" ? <ReportModal /> : null}
      </Modal>
    </div>
  );
};

const styles = {
  nameText: {
    fontFamily: "Inter, sans-serif",
    fontWeight: 900,
    fontSize: "1.7rem",
    color: "#FFF",
    margin: 0,
    textShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
    fontFamily: "Inter, sans-serif",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    textAlign: "left",
    // Responsive font size
    "@media (max-width: 400px)": {
      fontSize: "calc(1.1rem + 1vw)",
    },
    "@media (max-width: 340px)": {
      fontSize: "1.1rem",
    },
  },
  voteText: {
    fontSize: "1.2em",
    color: "#FFF",
    marginTop: "5px",
    margin: 0,
    textShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
  },

  infoBox: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    color: "#FFF",
    background:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.72) 100%)",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
};

export default Checkout;
