import React, { useState, useEffect } from "react";
import "./SafetyCenter.css";

const SafetyCenter = () => {
  const [activeSection, setActiveSection] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSection = (sectionId) => {
    setActiveSection(activeSection === sectionId ? null : sectionId);
  };

  const sections = [
    {
      id: "introduction",
      title: "Our Commitment to Safety",
      content: (
        <>
          <p>
            At "Checkout My Friend", the safety and security of our users is not
            just a priority; it is the very foundation upon which our app is
            built. We understand that a fun, engaging, and lighthearted
            experience can only thrive in an environment where users feel secure
            and respected.
          </p>
          <p>
            To this end, we have invested significant resources into developing
            a comprehensive safety framework that is both proactive and
            responsive. This framework is continuously reviewed and enhanced to
            adapt to emerging challenges, ensuring that our community remains a
            safe haven for positive interactions.
          </p>
          <p>
            Our secure ecosystem is designed to operate with precision,
            constantly monitored by a dedicated team to identify and respond to
            potential risks. We employ a lot of standard practices to avoid any
            guideline violations, but we also rely on the vigilance of our
            community members to report anything that seems inappropriate or
            harmful. When you report an issue, you are not just safeguarding
            your own experience; you are contributing to the well-being of the
            entire community.
          </p>
          <p>
            If you ever encounter something that feels off or threatens your
            sense of security, we encourage you to take immediate action by
            reaching out to us at{" "}
            <a href="mailto:help@checkoutmyfriend.link">
              help@checkoutmyfriend.link
            </a>
            . We treat every report with the seriousness it deserves, and your
            concerns will be addressed promptly and with full attention.
          </p>
        </>
      ),
    },
    {
      id: "ethical-commitment",
      title: "Our Ethical Commitment",
      content: (
        <>
          <p>
            "Checkout My Friend" is deeply committed to fostering an environment
            where respect, kindness, and positivity are the norm. We have
            established a zero-tolerance policy for behaviors that undermine
            these values, such as bullying, hate speech, trolling, and human
            objectification.
          </p>
          <p>
            To prevent such negative interactions, we have carefully designed
            our app in a way that minimizes the potential for misuse. Unlike
            many other apps, "Checkout My Friend" does not allow for open-ended
            communications, which can often be a breeding ground for harmful or
            offensive material. Instead, we provide a curated set of pre-defined
            prompts that users can choose from, ensuring that interactions
            remain safe, fun, and within the bounds of our community guidelines.
          </p>
          <p>
            These prompts—ranging from playful pickup lines to heartfelt
            compliments—are crafted to encourage safe and positive engagement.
            They allow users to express themselves in a way that is both
            creative and secure, without the risk of crossing boundaries or
            causing harm. By removing the potential for harmful content to be
            created or shared, we have significantly reduced the likelihood of
            negative experiences on our app. This approach strikes a delicate
            balance: it provides users with the freedom to connect and interact,
            while also maintaining a secure environment that prioritizes safety
            and respect.
          </p>
        </>
      ),
    },
    {
      id: "reporting",
      title: "Reporting Inappropriate Content",
      content: (
        <>
          <p>
            "Checkout My Friend" provides users with an easy and effective way
            to report any photographic content they find inappropriate,
            offensive, or harmful. Your vigilance is a crucial part of our
            safety strategy, and we have made the reporting process as simple
            and effective as possible.
          </p>
          <h3>How to Report Content</h3>
          <p>
            If you come across any photographic content that violates our
            community guidelines, you can report it directly through our app. To
            do so, simply navigate to the menu option, located at the top-right
            of the image. From there, click on the "Report" option. You will be
            asked to provide specific reasons for your report, which helps our
            team understand the nature of the issue and respond accordingly.
          </p>
          <h3>Our Review Process</h3>
          <p>
            Once your report is submitted, our team will conduct a thorough
            review of the content. We take each report seriously and approach
            them with a rigorous process of evaluation, which may take anywhere
            from a few hours to a few days, depending on the volume of reports
            we receive at that time. If the photo receives multiple reports
            within a short timeframe, it will be flagged as a priority and
            temporarily removed from the app while further investigation takes
            place. This precautionary measure ensures that potentially harmful
            content is not visible while we assess the situation.
          </p>
          <p>
            Our review process is meticulous. We consider the context, intent,
            and potential impact of the reported content before making a
            decision. If the content is found to be in violation of our
            guidelines, it will be taken down, and the user responsible may face
            consequences, including a permanent ban from the app depending on
            the intensity of the violation. In cases where the content is found
            to be particularly egregious or unlawful, we will not hesitate to
            escalate the matter to law enforcement authorities for appropriate
            legal action.
          </p>
          <p>
            By reporting inappropriate photographic content, you are playing an
            active role in maintaining the safety and integrity of our
            community. Your actions help us create a space where everyone can
            enjoy themselves without fear of encountering harmful or offensive
            material. We encourage you to use this feature whenever you feel it
            is necessary—your participation is vital to the health of our app.
          </p>
        </>
      ),
    },
    {
      id: "consent",
      title: "The Importance of Consent",
      content: (
        <>
          <p>
            At the heart of "Checkout My Friend" lies a commitment to fostering
            genuine connections and friendships. Our app is designed to bring
            people closer together in a fun, lighthearted way, but this can only
            happen when trust and respect are at the forefront of every
            interaction. One of the most important aspects of this trust is the
            concept of consent, particularly when it comes to sharing photos of
            friends.
          </p>
          <h3>What is Consent?</h3>
          <p>
            Consent is not just a formality—it is a fundamental principle that
            ensures all interactions on our app are grounded in mutual respect
            and understanding. Before a photo of a friend can be shared on
            "Checkout My Friend", explicit consent must be obtained from the
            person in the photo. This is not an optional step; it is a mandatory
            requirement that upholds the integrity of our community.
          </p>
          <h3>Before Giving Consent</h3>
          <p>
            When someone asks for your photo to share on "Checkout My Friend",
            it is important that you take the time to consider the request
            carefully. Ask yourself whether you are comfortable with your photo
            being shared in this context. Reflect on the relationship you have
            with the person making the request—do you trust them? Do you believe
            they have good intentions? It is essential that you feel fully
            confident in your decision before giving your consent.
          </p>
          <p>
            In addition to considering your comfort level, it is also important
            to specify which photo you would like to be used. This allows you to
            maintain control over your photographic representation and ensures
            that you are represented in a way that you are comfortable with.
            When discussing the request with your friend, make sure they clearly
            explain how your image will be used, what the app is about, and the
            purpose behind the request. This conversation should leave you with
            a full understanding of what this is all about and how it will
            impact you.
          </p>
          <h3>Ongoing Control Over Your Content</h3>
          <p>
            Even after you have given your consent, "Checkout My Friend"
            provides you with ongoing control over your content. If at any point
            you begin to feel uncomfortable with the situation, you can opt out
            and delete your content from the app. This flexibility is available
            to you at all times, whether you are inside the app or responding to
            an invitation link. Your consent is not a one-time decision—it is a
            continuous process, and you have the right to withdraw it whenever
            you choose.
          </p>
          <h3>What to Do If Your Photo Is Used Without Consent</h3>
          <p>
            Unfortunately, there may be situations where your photo is used
            without your consent. If this happens, it is important to take
            immediate action to protect your rights. You can report the
            unauthorized use of your photo by contacting us at{" "}
            <a href="mailto:help@checkoutmyfriend.link">
              help@checkoutmyfriend.link
            </a>
            . Required information for content takedown request includes the
            following:
          </p>
          <ul>
            <li>
              <strong>Identification Details:</strong> Full Name of the
              complainant, Contact Information (phone number), Two
              government-issued IDs for identity verification (e.g., Passport,
              Aadhaar, Driver's License, PAN).
            </li>
            <li>
              <strong>Detailed Description of the Unauthorized Content:</strong>{" "}
              URL(s) or specific location(s) of the content on the app, Date and
              time the content was uploaded (if known).
            </li>
            <li>
              <strong>Proof of Ownership or Legal Rights:</strong> For personal
              images: A statement affirming that the complainant is the
              individual depicted in the content or has the legal right to
              control its distribution.
            </li>
          </ul>
          <p>
            Our team will review your request and take appropriate action to
            remove the content if it is found to be in violation of our
            policies. We are committed to ensuring that your rights and privacy
            are upheld at all times.
          </p>
          <p>
            By respecting these guidelines and actively participating in the
            consent process, you contribute to a safer and more respectful
            community on "Checkout My Friend". Your proactive approach to
            consent not only helps protect yourself but also supports the
            creation of a positive environment where everyone can feel valued
            and secure.
          </p>
          <p>
            For any additional questions or concerns, please contact us at{" "}
            <a href="mailto:help@checkoutmyfriend.link">
              help@checkoutmyfriend.link
            </a>
            .
          </p>
        </>
      ),
    },
  ];

  return (
    <div className="safety-container">
      <header className="safety-header">
        <div className="logo-container">
          <div className="logo">
            <h1>Safety Center</h1>
          </div>
        </div>
        <p className="last-updated">Last Updated: August 28th, 2024</p>
      </header>

      <div className="safety-content">
        {isMobile ? (
          <div className="mobile-accordion">
            {sections.map((section) => (
              <div key={section.id} className="accordion-section">
                <button
                  className={`accordion-button ${
                    activeSection === section.id ? "active" : ""
                  }`}
                  onClick={() => toggleSection(section.id)}
                >
                  {section.title}
                  <span className="accordion-icon">
                    {activeSection === section.id ? "−" : "+"}
                  </span>
                </button>
                <div
                  className={`accordion-content ${
                    activeSection === section.id ? "active" : ""
                  }`}
                >
                  {section.content}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="desktop-layout">
            <nav className="table-of-contents">
              <h3>Contents</h3>
              <ul>
                {sections.map((section) => (
                  <li key={section.id}>
                    <a
                      href={`#${section.id}`}
                      className={activeSection === section.id ? "active" : ""}
                      onClick={(e) => {
                        e.preventDefault();
                        document
                          .getElementById(section.id)
                          .scrollIntoView({ behavior: "smooth" });
                        setActiveSection(section.id);
                      }}
                    >
                      {section.title}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
            <div className="sections-content">
              {sections.map((section) => (
                <section
                  key={section.id}
                  id={section.id}
                  className="policy-section"
                >
                  <h2>{section.title}</h2>
                  {section.content}
                </section>
              ))}
            </div>
          </div>
        )}
      </div>

      <footer className="safety-footer">
        <div className="footer-links">
          <a href="/terms">Terms of Service</a>
          <a href="/privacy">Privacy Policy</a>
          <a href="/guidelines">Community Guidelines</a>
        </div>
        <div className="social-icons">
          <a href="#" className="social-icon">
            <i className="instagram"></i>
          </a>
          <a href="#" className="social-icon">
            <i className="linkedin"></i>
          </a>
          <a href="#" className="social-icon">
            <i className="twitter"></i>
          </a>
        </div>
        <p>© 2024 ExtasyTechnologies. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default SafetyCenter;
