import React from "react";

const Modal = ({ show, handleClose, children, showCloseBtn = true }) => {
  if (!show) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          width: "85vw",
          maxWidth: "700px",
          //   height: "70vh",
          backgroundColor: "#191919",
          padding: "20px",
          borderRadius: "45px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {children}
        {showCloseBtn && (
          <button
            onClick={handleClose}
            style={{
              marginTop: "20px",
              padding: "20px 30px",
              backgroundColor: "#FFF",
              border: "none",
              borderRadius: "50px",
              cursor: "pointer",
              fontWeight: "900",
              width: "65vw",
              fontSize: "1.2em",
            }}
          >
            Close
          </button>
        )}
      </div>
    </div>
  );
};

export default Modal;
