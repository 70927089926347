import React, { useState, useEffect } from "react";
import "./Tos.css";

const TermsOfService = () => {
  const [activeSection, setActiveSection] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSection = (sectionId) => {
    setActiveSection(activeSection === sectionId ? null : sectionId);
  };

  const sections = [
    {
      id: "introduction",
      title: "Introduction",
      content: (
        <>
          <p>
            Welcome to the Terms of Service for "Checkout My Friend"
            ("Service"), provided by ExtasyTechnologies ("we", "us", or "our").
            By accessing or using our Service, you agree to comply with and be
            bound by these Terms of Service.
          </p>
          <p>
            These Terms govern your access to and use of our mobile application,
            website, and related services. Please read them carefully before
            using the Service.
          </p>
        </>
      ),
    },
    {
      id: "definitions",
      title: "1. Definitions",
      content: (
        <>
          <p>
            For the purposes of these Terms, the following terms are defined:
          </p>
          <ul>
            <li>
              <strong>"App" or "CMF"</strong>: Refers to the “Checkout My
              Friend” mobile application and associated services.
            </li>
            <li>
              <strong>"User"</strong>: Any person who downloads, accesses, or
              uses the App.
            </li>
            <li>
              <strong>"Initiator"</strong>: The user who uploads and shares a
              friend’s photo.
            </li>
            <li>
              <strong>"Friend"</strong>: The person whose photo is uploaded by
              the Initiator.
            </li>
            <li>
              <strong>"Viewers"</strong>: Individuals who view the Friend's
              photo through a shared link.
            </li>
            <li>
              <strong>"Post"</strong>: Any photo of a Friend shared by the
              Initiator.
            </li>
            <li>
              <strong>"Likes"</strong>: Responses to a Post, including Date,
              Marry, Hookup, or Prank options.
            </li>
            <li>
              <strong>"Super Checkout"</strong>: A premium feature that unlocks
              additional options for a Post.
            </li>
            <li>
              <strong>"Subscription"</strong>: A paid service offering Super
              Checkouts per week.
            </li>
          </ul>
        </>
      ),
    },
    {
      id: "eligibility",
      title: "2. Eligibility",
      content: (
        <>
          <h3>2.1 Age Requirement</h3>
          <p>
            You must be at least 18 years old to use our Service. By using the
            Service, you represent and warrant that you meet this age
            requirement.
          </p>
        </>
      ),
    },
    {
      id: "account-security",
      title: "3. Account Registration and Security",
      content: (
        <>
          <h3>3.1 Account Creation</h3>
          <p>
            You must register for an account to use the App. You agree to
            provide accurate and complete information and to update it as
            necessary.
          </p>
          <h3>3.2 Account Responsibility</h3>
          <p>
            You are responsible for safeguarding your account credentials and
            any activities under your account.
          </p>
          <h3>3.3 Account Termination</h3>
          <p>
            We may suspend or terminate your account for violations of these
            Terms.
          </p>
        </>
      ),
    },
    {
      id: "user-responsibilities",
      title: "4. User Responsibilities",
      content: (
        <>
          <h3>4.1 Compliance with Laws</h3>
          <p>
            You agree to use the App in compliance with all applicable laws and
            regulations.
          </p>
          <h3>4.2 Consent and Post Deletion Rights</h3>
          <p>
            The Initiator must obtain explicit consent from their Friend before
            uploading a Post. The Friend has the right to request Post deletion
            at any time.
          </p>
          <h3>4.3 Post Guidelines</h3>
          <p>You agree not to upload content that:</p>
          <ul>
            <li>Contains nudity, violence, or illegal activities.</li>
            <li>Violates privacy rights.</li>
            <li>Is defamatory or discriminatory.</li>
          </ul>
        </>
      ),
    },
    {
      id: "sharing-guidelines",
      title: "5. Sharing and Inviting Guidelines",
      content: (
        <>
          <p>
            Initiators must share the link within their personal social media
            spaces. Redistributing the link to third parties is not allowed.
            Initiators must ensure they send the invitation link to the correct
            Friend.
          </p>
        </>
      ),
    },
    {
      id: "post-ownership",
      title: "6. Post Ownership and Licensing",
      content: (
        <>
          <h3>6.1 Initiator and Friend Consent</h3>
          <p>
            The Initiator must obtain explicit consent before uploading a Post.
            The Friend grants CMF a license to use and display the Post.
          </p>
          <h3>6.2 Conditional Ownership</h3>
          <p>
            The Friend retains ownership of the Post but acknowledges CMF’s
            right to use it.
          </p>
          <h3>6.3 Third-Party Post</h3>
          <p>CMF is not responsible for content provided by third parties.</p>
        </>
      ),
    },
    {
      id: "privacy",
      title: "7. Privacy and Data Protection",
      content: (
        <>
          <h3>7.1 Data Collection</h3>
          <p>
            We collect and store user data in accordance with our Privacy
            Policy.
          </p>
          <h3>7.2 Data Sharing</h3>
          <p>
            Some user information may be shared with premium subscribers as part
            of the App’s features.
          </p>
          <h3>7.3 Data Deletion</h3>
          <p>
            Users can delete their accounts, after which data is retained for 30
            days before permanent deletion.
          </p>
        </>
      ),
    },
    {
      id: "moderation",
      title: "8. Post Moderation and Reporting",
      content: (
        <>
          <h3>8.1 Post Moderation</h3>
          <p>
            We reserve the right to review and remove content that violates
            these Terms.
          </p>
          <h3>8.2 Reporting Violations</h3>
          <p>
            Users can report inappropriate content, which will be reviewed by
            our team.
          </p>
          <h3>8.3 Consequences of Violations</h3>
          <p>
            Violations may result in content removal, account suspension, or
            termination.
          </p>
        </>
      ),
    },
    {
      id: "prank-feature",
      title: "9. Prank Acknowledgement",
      content: (
        <>
          <h3>9.1 Prank Feature</h3>
          <p>
            The App includes a Prank feature for entertainment purposes. Users
            acknowledge that some responses may be intended as Pranks.
          </p>
          <h3>9.2 User Consent</h3>
          <p>
            By using CMF, users agree to participate in the Prank feature and
            accept its role in the App experience.
          </p>
          <h3>9.3 Limitations and Responsibility</h3>
          <p>
            Users must engage responsibly. Misuse leading to harassment may
            result in account suspension or termination.
          </p>
        </>
      ),
    },
    {
      id: "subscription-services",
      title: "10. Subscription Services",
      content: (
        <>
          <h3>10.1 1 Super Checkout Subscription</h3>
          <p>
            The 1 Super Checkout Subscription is a weekly paid subscription that
            provides Users with a single Super Checkout each week. Users can
            create and share Posts for free, but premium features on these Posts
            are restricted. By subscribing to the One Super Checkout
            Subscription, users receive one Super Checkout per week, which can
            be applied to a single Post to unlock its premium features.
          </p>

          <h3>10.2 Unlimited Super Checkouts Subscription</h3>
          <p>
            The Unlimited Super Checkouts Subscription is another weekly paid
            subscription that provides Users with unlimited Super Checkouts each
            week. This subscription allows users to apply Super Checkouts to as
            many Posts as they like, enabling access to premium features across
            multiple Posts without limitations on the number of Super Checkouts
            available.
          </p>

          <h3>10.3 Super Checkout</h3>
          <p>
            A Super Checkout is a consumable item that, when applied to a
            specific Post, unlocks premium features that are otherwise
            inaccessible in the free version. Each Super Checkout applies
            exclusively to the Post on which it is activated, meaning that
            premium features will not automatically be available for other
            Posts.
          </p>
          <p>
            Additionally, when a Super Checkout is activated on a Post, both
            parties associated with that Post—the Initiator and the Friend—gain
            access to the unlocked premium features. This ensures that if either
            the Initiator or the Friend activates a Super Checkout, both parties
            benefit from the enhanced features without needing separate
            subscriptions or activations.
          </p>

          <h3>10.4 Premium Features of Super Checkout</h3>
          <p>
            Once a Super Checkout is activated on a post, it provides the
            following premium features:
          </p>
          <ul>
            <li>
              <strong>Visibility of Senders:</strong> The ability to identify
              who has sent Likes, including their name, profile picture (if
              available), and the exact time the Like was sent.
            </li>
            <li>
              <strong>Visibility of Viewers:</strong> Users can view detailed
              information about individuals who have viewed the post, including
              their name, profile picture (if available), and the specific time
              of the view.
            </li>
            <li>
              <strong>Prank Detection:</strong> A feature that distinguishes
              Likes classified as pranks from genuine Likes and reactions.
            </li>
            <li>
              <strong>Extended Post Lifespan:</strong> The post’s visibility
              duration can be extended to 1 week. Additionally, the weekly
              viewer limit for the post is increased to 1,000 viewers per week.
            </li>
            <li>
              <strong>Ad-Free Experience:</strong> All interactions with posts
              become free from advertisements for both the initiator and the
              friend.
            </li>
          </ul>

          <h3>10.5 Subscription Terms</h3>
          <ul>
            <li>
              <strong>Billing and Auto-Renewal:</strong> Subscriptions renew
              automatically on a weekly basis unless canceled at least 24 hours
              before the end of the subscription period.
            </li>
            <li>
              <strong>Payment Authorization:</strong> By subscribing, users
              authorize the app to charge the applicable fees to the payment
              method provided at registration.
            </li>
            <li>
              <strong>Refund Policy:</strong> All payments for subscriptions are
              non-refundable, except where otherwise required by applicable law.
            </li>
            <li>
              <strong>Super Checkout Validity:</strong> Each Super Checkout is
              applicable to a single Post and is valid only until the next
              weekly renewal.
            </li>
          </ul>

          <h3>10.6 Compliance with App Store Policies</h3>
          <p>
            Any payment-related issues or disputes should be resolved directly
            with the respective app store (Apple App Store or Google Play Store)
            as per their terms of service and policies.
          </p>
        </>
      ),
    },
    {
      id: "prohibited-conduct",
      title: "11. Prohibited Conduct",
      content: (
        <>
          <h3>11.1 User Responsibilities and Conduct Control</h3>
          <p>
            Users who gain access to the identities of Senders through premium
            or paid features of the Platform agree to uphold the principles of
            confidentiality and respect towards all participants.
          </p>

          <h3>11.2 User Prohibition</h3>
          <p>
            Users are expressly prohibited from engaging in any conduct that may
            cause discomfort, harassment, or distress to other Users.
          </p>

          <h3>11.3 Consequences of Misconduct</h3>
          <p>
            Breach of these terms may result in the immediate suspension or
            termination of access to the Platform.
          </p>

          <h3>11.4 Reporting Mechanisms</h3>
          <p>
            Users are encouraged to report inappropriate conduct to our support
            team at help@checkoutmyfriend.like.
          </p>
        </>
      ),
    },
    {
      id: "limitation-liability",
      title: "12. Limitation of Liability",
      content: (
        <>
          <h3>12.1 Disclaimer of Warranties</h3>
          <p>
            The App and all services provided by CMF are offered "as is" and "as
            available" without warranties of any kind, either express or
            implied.
          </p>

          <h3>12.2 Limitation of Liability</h3>
          <p>
            CMF, its affiliates, partners, officers, directors, employees, and
            agents shall not be liable for any indirect, incidental, special, or
            consequential damages arising from the use of the App.
          </p>

          <h3>12.3 Exclusions and Limitations</h3>
          <p>
            Some jurisdictions do not allow the exclusion of certain warranties
            or the limitation of liability. In such cases, our liability will be
            limited to the fullest extent permitted by applicable law.
          </p>
        </>
      ),
    },

    {
      id: "dispute-resolution",
      title: "13. Dispute Resolution",
      content: (
        <>
          <h3>13.1 Governing Law</h3>
          <p>
            These Terms shall be governed by and construed in accordance with
            the laws of the Republic of India, without regard to its conflict of
            law principles.
          </p>

          <h3>13.2 Informal Resolution</h3>
          <p>
            Before initiating any legal action, You agree to first contact us at{" "}
            <a href="mailto:legal@checkoutmyfriend.link">
              legal@checkoutmyfriend.link
            </a>{" "}
            to attempt to resolve the dispute informally. We will attempt to
            resolve the dispute through communication and negotiation in good
            faith within 90 days of Your notice.
          </p>

          <h3>13.3 Arbitration</h3>
          <p>
            If the dispute cannot be resolved informally, it shall be finally
            resolved by binding arbitration. The arbitration shall be conducted
            in accordance with the Indian Arbitration and Conciliation Act,
            1996, as amended, and the venue of arbitration shall be Guwahati,
            Assam, India. The language of the arbitration shall be English. The
            arbitrator’s decision shall be final and binding, and judgment on
            the award rendered by the arbitrator may be entered in any court of
            competent jurisdiction.
          </p>

          <h3>13.4 Waiver of Class Action Rights</h3>
          <p>
            You agree that any disputes arising under these Terms or in
            connection with the App shall be resolved on an individual basis and
            not as part of any class, collective, or representative action. You
            expressly waive your right to participate in any class action or
            collective lawsuit.
          </p>

          <h3>13.5 Injunctive Relief</h3>
          <p>
            Nothing in these Terms shall prevent either party from seeking
            injunctive or other equitable relief in a court of competent
            jurisdiction to prevent the actual or threatened infringement,
            misappropriation, or violation of a party’s intellectual property
            rights or other proprietary rights.
          </p>
        </>
      ),
    },
    {
      id: "intellectual-property",
      title: "14. Intellectual Property Rights",
      content: (
        <>
          <h3>14.1 CMF Intellectual Property</h3>
          <p>
            All intellectual property rights in and to the App, including but
            not limited to the software, design, text, graphics, logos, and
            other materials, are owned by CMF or its licensors. These rights are
            protected by copyright, trademark, and other intellectual property
            laws. You acknowledge that you have no rights in or to the App
            except as expressly provided in these Terms.
          </p>

          <h3>14.2 License to Use the App</h3>
          <p>
            Subject to your compliance with these Terms, we grant you a limited,
            non-exclusive, non-transferable, non-sublicensable license to
            download, install, and use the App on your personal mobile device
            for your personal, non-commercial use. This license is revocable at
            any time without notice or liability to you.
          </p>

          <h3>14.3 Restrictions</h3>
          <p>You agree not to:</p>
          <ul>
            <li>
              Reproduce, distribute, publicly display, or create derivative
              works based on the App or any part thereof without our prior
              written consent.
            </li>
            <li>
              Reverse engineer, decompile, or disassemble any part of the App,
              except and only to the extent that such activity is expressly
              permitted by applicable law.
            </li>
            <li>
              Use the App for any commercial purposes without our prior written
              consent.
            </li>
            <li>
              Remove, alter, or obscure any copyright, trademark, or other
              proprietary rights notices on or in the App.
            </li>
          </ul>
        </>
      ),
    },
    {
      id: "termination",
      title: "15. Termination",
      content: (
        <>
          <h3>15.1 Termination by CMF</h3>
          <p>
            We may terminate or suspend your Account or access to the App at any
            time, without notice or liability, if you breach these Terms or for
            any other reason, including but not limited to prolonged inactivity,
            fraudulent activity, or illegal conduct.
          </p>

          <h3>15.2 Termination by You</h3>
          <p>
            You may terminate your Account at any time by following the
            instructions provided in the App. Upon termination, you must cease
            all use of the App and delete all copies of the App from your
            devices.
          </p>

          <h3>15.3 Effect of Termination</h3>
          <p>
            Upon termination of your Account for any reason, all licenses and
            rights granted to you under these Terms will immediately cease. Any
            provisions of these Terms that by their nature should survive
            termination shall survive, including but not limited to, ownership
            provisions, warranty disclaimers, indemnity, and limitations of
            liability.
          </p>
        </>
      ),
    },
    {
      id: "data-protection",
      title: "16. Data Protection and Privacy",
      content: (
        <>
          <h3>16.1 Data Collection</h3>
          <p>
            CMF collects and processes personal data in accordance with our
            Privacy Policy. The types of data we collect include, but are not
            limited to:
          </p>
          <ul>
            <li>
              <strong>Personal Information:</strong> Name, email address,
              gender, Instagram username, and profile photo.
            </li>
            <li>
              <strong>Usage Data:</strong> Interaction history, App usage
              patterns, and device information.
            </li>
            <li>
              <strong>Post Data:</strong> Photos, Tags, Likes, and pre-written
              messages shared within the App.
            </li>
          </ul>

          <h3>16.2 Data Retention</h3>
          <p>
            We retain personal data for as long as necessary to fulfill the
            purposes for which it was collected, or as required by law. If you
            delete your Account, we will remove your data from the App, but it
            will be retained on our servers for up to 30 days to allow for any
            necessary legal or operational reasons before it is permanently
            deleted.
          </p>

          <h3>16.3 User Rights</h3>
          <p>You have the following rights concerning your personal data:</p>
          <ul>
            <li>
              <strong>Access:</strong> You can request a copy of your data that
              we hold.
            </li>
            <li>
              <strong>Rectification:</strong> You can request the correction of
              any inaccurate or incomplete data.
            </li>
            <li>
              <strong>Deletion:</strong> You can request the deletion of your
              data, subject to legal and operational requirements.
            </li>
            <li>
              <strong>Objection:</strong> You can object to the processing of
              your data under certain circumstances.
            </li>
            <li>
              <strong>Data Portability:</strong> You can request the transfer of
              your data to another service provider.
            </li>
          </ul>

          <h3>16.4 Data Security</h3>
          <p>
            We implement appropriate technical and organizational measures to
            protect personal data from unauthorized access, disclosure,
            alteration, or destruction. However, no internet-based service can
            be completely secure, and we cannot guarantee the absolute security
            of your data.
          </p>

          <h3>16.5 Third-Party Services</h3>
          <p>
            CMF may use third-party service providers to facilitate certain
            aspects of the App, such as payment processing, hosting, showing
            ads, and analytics. These providers may have access to your personal
            data only to perform specific tasks on our behalf and are obligated
            not to disclose or use it for any other purpose.
          </p>

          <h3>16.6 Data Transfers</h3>
          <p>
            By using the App, you consent to the transfer of your personal data
            to countries outside of your residence, including countries that may
            have different data protection laws than your own. We will ensure
            that any such transfers comply with applicable data protection
            regulations.
          </p>

          <h3>16.7 Children’s Privacy</h3>
          <p>
            The App is not intended for use by individuals under the age of 18.
            We do not knowingly collect personal data from children under 18. If
            we become aware that a child under 18 has provided us with personal
            data, we will take steps to delete such data.
          </p>
        </>
      ),
    },
    {
      id: "indemnification",
      title: "17. Indemnification",
      content: (
        <>
          <h3>17.1 Your Indemnification Obligations</h3>
          <p>
            You agree to indemnify, defend, and hold harmless CMF, its
            affiliates, partners, officers, directors, employees, and agents
            from and against any and all claims, damages, losses, liabilities,
            costs, and expenses (including reasonable attorneys' fees) arising
            out of or related to:
          </p>
          <ul>
            <li>Your use of the App or any Post you share through the App.</li>
            <li>Your violation of these Terms or any applicable law.</li>
            <li>
              Any claim that your Post infringes or violates the intellectual
              property, privacy, or other rights of any third party.
            </li>
            <li>Your gross negligence or willful misconduct.</li>
          </ul>

          <h3>17.2 CMF's Right to Defense</h3>
          <p>
            CMF reserves the right, at its own expense, to assume the exclusive
            defense and control of any matter subject to indemnification by you,
            in which event you agree to cooperate with us in asserting any
            available defenses.
          </p>
        </>
      ),
    },
    {
      id: "force-majeure",
      title: "18. Force Majeure",
      content: (
        <>
          <h3>18.1 Force Majeure Events</h3>
          <p>
            CMF shall not be liable for any failure or delay in performance of
            its obligations under these Terms due to circumstances beyond its
            reasonable control, including but not limited to:
          </p>
          <ul>
            <li>
              Acts of God, natural disasters, or extreme weather conditions.
            </li>
            <li>War, invasion, terrorist attacks, or civil unrest.</li>
            <li>Governmental orders, regulations, or restrictions.</li>
            <li>Strikes, labor disputes, or industrial action.</li>
            <li>
              Internet or telecommunications failures, power outages, or system
              failures.
            </li>
          </ul>

          <h3>18.2 Effect of Force Majeure</h3>
          <p>
            If a force majeure event occurs, CMF's obligations under these Terms
            will be suspended for the duration of the event. We will use
            reasonable efforts to mitigate the impact of the force majeure event
            and resume normal operations as soon as practicable.
          </p>
        </>
      ),
    },
    {
      id: "future-updates",
      title: "19. Future Updates",
      content: (
        <>
          <p>
            You acknowledge and agree that the nature of CMF may evolve in the
            future. We reserve the right to evolve CMF into a broader service or
            social platform in the future. In the event of such changes, your
            account may be adapted or transitioned to align with the new
            framework of the service. By continuing to use the platform, you
            consent to such potential adjustments and the corresponding
            modification of your account.
          </p>
        </>
      ),
    },
    {
      id: "miscellaneous-provisions",
      title: "20. Miscellaneous Provisions",
      content: (
        <>
          <h3>20.1 Entire Agreement</h3>
          <p>
            These Terms, together with our Privacy Policy and Community
            Guidelines, constitute the entire agreement between you and CMF
            concerning your use of the App and supersede all prior agreements,
            understandings, or communications between you and CMF regarding the
            subject matter herein.
          </p>

          <h3>20.2 Amendments</h3>
          <p>
            CMF reserves the right to modify these Terms at any time. Any
            changes will be effective immediately upon posting the revised Terms
            within the App or on our website. Your continued use of the App
            following the posting of changes constitutes your acceptance of such
            changes.
          </p>

          <h3>20.3 Severability</h3>
          <p>
            If any provision of these Terms is found to be invalid or
            unenforceable by a court of competent jurisdiction, the remaining
            provisions will remain in full force and effect. The invalid or
            unenforceable provision will be deemed modified to the extent
            necessary to make it valid and enforceable.
          </p>

          <h3>20.4 Waiver</h3>
          <p>
            No waiver of any term or condition of these Terms shall be deemed a
            further or continuing waiver of such term or condition or any other
            term or condition, and any failure to assert a right or provision
            under these Terms shall not constitute a waiver of such right or
            provision.
          </p>

          <h3>20.5 Assignment</h3>
          <p>
            You may not assign or transfer these Terms, or any rights or
            obligations hereunder, without our prior written consent. CMF may
            freely assign or transfer these Terms without restriction.
          </p>

          <h3>20.6 Notices</h3>
          <p>
            All notices, requests, or other communications required or permitted
            to be given under these Terms shall be in writing and delivered to
            CMF by email at{" "}
            <a href="mailto:legal@checkoutmyfriend.link">
              legal@checkoutmyfriend.link
            </a>
            .
          </p>

          <h3>20.7 Headings</h3>
          <p>
            The section headings in these Terms are for convenience only and
            have no legal or contractual effect.
          </p>
        </>
      ),
    },
    {
      id: "contact-information",
      title: "21. Contact Information",
      content: (
        <>
          <p>
            If you have any questions about these Terms, please contact us at:
            <br />
            <a
              href="https://www.checkoutmyfriend.link/contact-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.checkoutmyfriend.link/contact-us
            </a>
          </p>
        </>
      ),
    },
  ];

  return (
    <div className="terms-of-service-container">
      {/* <header className="terms-header">
        <div className="logo-container">
          <div className="logo">
            <h1>Terms of Service</h1>
          </div>
        </div>
        <p className="last-updated">Last Updated: August 28th, 2024</p>
      </header> */}

      <div className="terms-header">
        <h1>Terms of Service</h1>
        <p className="header-subtitle">Last Updated: August 28th, 2024</p>
      </div>

      <div className="terms-content">
        {isMobile ? (
          <div className="mobile-accordion">
            {sections.map((section) => (
              <div key={section.id} className="accordion-section">
                <button
                  className={`accordion-button ${
                    activeSection === section.id ? "active" : ""
                  }`}
                  onClick={() => toggleSection(section.id)}
                >
                  {section.title}
                  <span className="accordion-icon">
                    {activeSection === section.id ? "−" : "+"}
                  </span>
                </button>
                <div
                  className={`accordion-content ${
                    activeSection === section.id ? "active" : ""
                  }`}
                >
                  {section.content}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="desktop-layout">
            <nav className="table-of-contents">
              <h3>Contents</h3>
              <ul>
                {sections.map((section) => (
                  <li key={section.id}>
                    <a
                      href={`#${section.id}`}
                      className={activeSection === section.id ? "active" : ""}
                      onClick={(e) => {
                        e.preventDefault();
                        document
                          .getElementById(section.id)
                          .scrollIntoView({ behavior: "smooth" });
                        setActiveSection(section.id);
                      }}
                    >
                      {section.title}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
            <div className="sections-content">
              {sections.map((section) => (
                <section
                  key={section.id}
                  id={section.id}
                  className="policy-section"
                >
                  <h2>{section.title}</h2>
                  {section.content}
                </section>
              ))}
            </div>
          </div>
        )}
      </div>

      <footer className="terms-footer">
        <div className="footer-links">
          <a href="/privacy">Privacy Policy</a>
          <a href="/about">About Product</a>
          <a href="/support">Support</a>
        </div>
        <div className="social-icons">
          <a href="#" className="social-icon">
            <i className="instagram"></i>
          </a>
          <a href="#" className="social-icon">
            <i className="linkedin"></i>
          </a>
          <a href="#" className="social-icon">
            <i className="twitter"></i>
          </a>
        </div>
        <p>© 2024 ExtasyTechnologies. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default TermsOfService;
